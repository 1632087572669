export enum INFO_MESSAGES {
 SUCCESS = 'Success',
 LOGOUT = 'Logged out!',
 ERR_MESSAGE = '',
 ALERT_TITLE = 'Alert',
 WARNING = 'Warning',
 ERROR = 'Error',
 INFO = 'Info',
}

export enum ALERT_MAX_DATE {
 TITLE = 'Warning',
 CONFIRMATION = 'This was the last task for this matter. Please click on the button below to create a reminder task. ',
 CONFIRM = 'Create a new task or reminder',
 CANCEL = '',
 TYPE = 'maxDate',
}

export enum ALERT_REMOVE_TASK {
 TITLE = 'Warning',
 CONFIRM = 'Ok',
 CANCEL = 'cancel',
 TYPE = 'removeTask',
}

export enum ALERT_CLAIMANT {
 ALERT_TYPE = 'claimantAlert',
 ALERT_HEADING_MESSAGE = 'By Removing associated claimant the questionnaire data will be lost!',
 ALERT_MESSAGE = 'Are you sure you want to proceed?',
}

export enum ERROR_MESSAGE {
 RESPONSE_MESSAGE = 'Something went wrong',
}

export enum STATUS {
 OPEN = 'open',
 CLOSED = 'closed',
 ACTIVE = 'active',
 INACTIVE = 'inactive',
}

export enum RESPONSE {
 ERROR = 'Something went wrong',
 NOMATTER = 'No matter found',
 NOTASK = 'No tasks found',
}

export enum TOASTR {
 SUCCESS = 'Success',
 ALERT = 'Alert',
 ERROR = 'Error',
 MESSAGE_MATTER = 'Matter added successfully!',
 MESSAGE_MATTER_UPDATE = 'Matter updated successfully',
 MESSAGE_CLIENT = 'Client details updated successfully!',
 MESSAGE_CLAIM = 'Claim step updated!',
 MESSAGE_MARK_DONE = 'Marked the done',
 MESSAGE_UPDATETASK = 'Task updated successfully!',
 MESSAGE_ADDTASK = 'Task added successfully!',
 MESSAGE_ADDEVENT = 'Event added successfully',
 MESSAGE_UPDATEEVENT = 'Event updated successfully',
 MESSAGE_EVENTDELETE = 'Event deleted successfully',
 MESSAGE_VALIDNUMBER = 'Please give valid minutes',
 MESSAGE_VALIDHOURS = 'Please give valid hours',
 MESSAGE_VALID_HRMIN = 'Hours or minutes should be greater than 0',
 MESSAGE_TASK_TIME = 'Task end time should be greater than start time',
 MESSAGE_TASKMARKDONE = 'Task marked as done',
 MESSAGE_TASKDELETE = 'Do you want to delete the task',
 MESSAGE_TASKDELETED = 'Task deleted successfully',
 MESSAGE_NOTIFICATIONDELETED = 'Notification deleted successfully',
 MESSAGE_PRECEDENTADDED = 'Precedent added successfully',
 MESSAGE_PRECEDENTDELETED = 'Precedent deleted successfully',
 MESSAGE_UPDATEPRECEDENT = 'Precedent updated successfully!',
 MESSAGE_ADDPRECEDENT = 'Precedent added successfully!',
 MESSAGE_ADDPRECEDENTCAT = 'Category added successfully!',
 MESSAGE_UPDATEPRECEDENTCAT = 'Category updated successfully!',
 MESSAGE_DOCKETDELETE = 'Do you want to delete the docket',
 MESSAGE_DOCKETDELETED = 'Docket deleted successfully',
 MESSAGE_INVITED = 'Invitation sent successfully',
 MESSAGE_EVENTSAVED = 'Event saved',
 MESSAGE_PASSUPDATE = 'Password is updated',
 MESSAGE_RESETPASSWORD = 'Your password has been updated successfully',
 MESSAGE_CLIENTEDITFORM = 'Client can edit form',
 MESSAGE_CLIENTCANNOTEDITFORM = 'Client cannot edit form',
 MESSAGE_ENTER_EMAIL = 'Please enter email address',
 CLIENT_CONFIRMATIONMESSAGE = 'Once you submit, you will no longer be able to edit this form.',
 LAWYER_CONFIRMATIONMESSAGE = 'Are you sure you want to share questionnaire with client?',
 MESSAGE_MATER_CLOSED = 'Matter has been closed',
 MESSAGE_CLAIM_ADDED = 'Added claim step successfully',
 MESSAGE_ARCHIVED = 'Matter has been archived',
 MESSAGE_OPENED = 'Matter re-opened successfully',
 MESSAGE_DOCKETUPDATED = 'Docket updated successfully!',
 MESSAGE_DOCKETADDED = 'Docket added successfully!',
 MESSAGE_CLAIMVALIDATION = 'Cannot add 0 as an order',
 MESSAGE_ORDERUPDATED = 'Order updated successfully!',
 MESSAGE_DELETEFORM = 'Form instance deleted successfully.',
 MESSAGE_TOKENRESENT = 'Token Resent successfully',
 MESSAGE_TOKENSENT = 'Token sent successfully',
 MESSAGE_TOKENSENTALREADY = 'Auth code already sent',
 MESSAGE_SAVEDFORM = 'Form saved successfully',
 MESSAGE_TOKENVERIFIED = 'Token verified successfully',
 MESSAGE_TOKENINVALID = 'Invalid Token',
 MESSAGE_CREDENTIALSENT = 'Credentials have been sent to your email',
 MESSAGE_VALIDEMAIL = 'Enter valid email',
 MESSAGE_MESSAGEDELETED = 'Message deleted successfully',
}

export enum VALIDATIONMESSAGES {
 CONFIRMPASSWORD = 'Password and confirm password should be same.',
}

export enum DATE_FORMAT {
 YMD = 'YYYY-MM-DD',
 YMDHM = 'YYYY-MM-DD HH:mm',
 LT = 'LT',
 DMY = 'dd/MM/yyyy',
 HH = 'HH:mm',
}

export enum REPLACE {
 WAS = 'was',
 IS = 'is',
}

export enum RELATION_QUESTION {
 OTHER_FIRST_NAME = 'What is your partner’s first name?',
 OTHER_LAST_NAME = 'What is your partner’s family name?',
 OTHER_RELATION_START = 'When did your relationship start?',

 WIDOWED_FIRST_NAME = 'What was your spouse / common law partner’s first name?',
 WIDOWED_LAST_NAME = 'What was your spouse / common law partner’s family name?',

 ORIGINAL_FIRST_NAME = 'What is your spouse / common law partner’s first name?',
 ORIGINAL_LAST_NAME = 'What is your spouse / common law partner’s family name?',
 ORIGINAL_RELATION_START = 'When did your relationship start (marriage/common-law union)?',
}
