import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake?.vfs;

import {
  AlignmentType,
  BorderStyle,
  Document,
  HeadingLevel,
  ImageRun,
  Packer,
  Paragraph,
  SectionType,
  Table,
  TableCell,
  TableRow,
  TextRun,
  UnderlineType,
  VerticalAlign,
  WidthType,
} from 'docx';
import { saveAs } from 'file-saver';
import * as moment from 'moment';
import * as LOCAL_STORAGE from '../enum/local-storage.enum';
@Injectable({
  providedIn: 'root',
})
export class PdfService {
  matterId: number;
  borders = {
    top: {
      style: BorderStyle.NONE,
    },
    bottom: {
      style: BorderStyle.NONE,
    },
    left: {
      style: BorderStyle.NONE,
    },
    right: {
      style: BorderStyle.NONE,
    },
    insideHorizontal: {
      style: BorderStyle.NONE,
    },
    insideVertical: {
      style: BorderStyle.NONE,
    },
  };

  matterDetailsBorders = {
    top: {
      style: BorderStyle.SINGLE,
      size: 1,
      color: 'ffffff',
    },
    bottom: {
      style: BorderStyle.SINGLE,
      size: 1,
      color: 'ffffff',
    },
    left: {
      style: BorderStyle.SINGLE,
      size: 1,
      color: 'ffffff',
    },
    right: {
      style: BorderStyle.SINGLE,
      size: 1,
      color: 'ffffff',
    },
  };

  receiptsHeading = [
    { text: 'Date', style: ['thbg', 'textFont'] },
    { text: 'Received from: (Source of funds)', style: ['thbg', 'textFont'] },
    {
      text: 'Amount',
      style: ['thbg', 'textFont'],
    },
    { text: 'Method', style: ['thbg', 'textFont'] },
  ];

  disHeading = [
    { text: 'Date', style: ['thbg', 'textFont'] },
    { text: 'Method / Number', style: ['thbg', 'textFont'] },
    {
      text: 'Paid to',
      style: ['thbg', 'textFont'],
    },
    { text: 'Particulars', style: ['thbg', 'textFont'] },
    { text: 'Amount', style: ['thbg', 'textFont'] },
    { text: 'HST Paid', style: ['thbg', 'textFont'] },
    { text: 'Total Paid', style: ['thbg', 'textFont'] },
  ];
  widths: any = {
    1: [500],
    2: [250, 250],
    3: [65, 35, 400],
    4: [65, 35, 300, 50],
    5: [65, 35, 300, 50, 50],
  };

  columnWidths: any = {
    1: [1500],
    2: [1200, 1200],
    3: [1200, 1200, 4200],
    4: [1200, 1200, 4200, 1800],
    5: [1200, 1200, 4200, 1200, 1800],
  };

  constructor(private toastr: ToastrService) {
    try {
      const matters: any = JSON.parse(localStorage.getItem(LOCAL_STORAGE.LOCAL_STORAGE.MATTER_NAV_DETAILS));
      this.matterId = matters?.matterDetails?.matterId;
    } catch (err) {}
  }

  export(selectedFields: any, dockets: any, exportType: string, matterDetails: any) {
    switch (exportType) {
      case 'PDF':
        this.generatePDF(selectedFields, dockets);
        break;
      case 'CSV':
        this.generateCSV(selectedFields, dockets);
        break;
      case 'Copy to Clipboard':
        this.copyToClipboard(selectedFields, dockets);
        break;
      case 'Word Doc':
        this.createWordDoc(selectedFields, dockets, matterDetails);
        break;
      default:
        break;
    }
  }

  async exportPrecedentDocx(precedentDocData: any) {
    const precedenList = precedentDocData?.precedentArr;
    const lawfirmData = precedentDocData?.lawfirmData;
    const clientData = precedentDocData?.clientDetails?.matterDetails;
    const lawyerLength = precedentDocData?.clientDetails?.matterDetails?.lawyers?.length;
    const recipientSelected = precedentDocData.recipientSelected;
    const subscribedUser = precedentDocData?.subscribedUser;
    let ccData = [];
    ccData = this.setCCData(precedentDocData);

    const recipientAddress = precedentDocData?.recipientAdd !== '' ? precedentDocData?.recipientAdd : '';

    let lawyerDetails;
    if (lawyerLength === 1) {
      lawyerDetails =
        precedentDocData?.clientDetails?.matterDetails?.lawyers[0] !== ''
          ? precedentDocData?.clientDetails?.matterDetails?.lawyers[0]
          : precedentDocData?.clientDetails?.matterDetails?.lawyers[0];
    } else {
      lawyerDetails = 'Please add lawyer details';
    }

    if (recipientAddress !== '') {
      this.recipientDoc(
        precedenList,
        lawfirmData,
        clientData,
        ccData,
        recipientAddress,
        lawyerDetails,
        recipientSelected,
        subscribedUser,
        precedentDocData,
      );
    } else {
      //if the Immigration and refugee board is empty and it is selected set the client empty
      const clientDetails = recipientSelected === 'Client' ? clientData : '';
      const table = new Table({
        borders: this.borders,
        columnWidths: [9010],
        rows: [
          new TableRow({
            children: [
              new TableCell({
                borders: {
                  bottom: {
                    style: BorderStyle.SINGLE,
                    size: 2,
                    color: '000000',
                  },
                },
                children: [this.summaryTitle(subscribedUser)],
              }),
            ],
          }),
        ],
      });

      const doc = new Document({
        sections: [
          {
            properties: {
              type: SectionType.NEXT_COLUMN,
            },
            children: [
              table,
              new Paragraph({
                alignment: AlignmentType.LEFT,
                children: [
                  new TextRun({
                    size: '12pt',
                    font: 'Times New Roman',
                    text: 'To,',
                    break: 1,
                  }),
                ],
              }),
              this.salutationPara(),
              this.docSubject(clientData),
              this.createClientPara(clientDetails),
              ...this.createPrecedentList(precedenList),
              lawyerDetails === 'Please add lawyer details'
                ? this.createLawyer(lawyerDetails)
                : this.createLawyerPara(lawyerDetails),
              ...this.createCC(ccData),
            ],
          },
        ],
      });
    }
  }

  docSubject(clientData) {
    let text;
    const uci = clientData?.uciNo ? clientData?.uciNo : '';
    const rpd = clientData?.prdFileNo ? clientData?.prdFileNo : '';
    const courtNo = clientData?.courtFileNo;
    if (clientData?.matterType.toLowerCase() === 'family') {
      text = `Re: ${clientData?.user?.lastName}, ${clientData?.user?.firstName} ;${
        Boolean(courtNo) ? ' ' + 'Court File No.: ' + courtNo + ' ;' : ''
      }`;
    } else if (clientData?.matterType.toLowerCase() === 'immigration') {
      text = `Re: ${clientData?.user?.lastName}, ${clientData?.user?.firstName} ;${
        Boolean(rpd) ? ' ' + 'RPD File: ' + rpd + ' ;' : ''
      }${Boolean(uci) ? ' ' + 'UCI No.: ' + uci + ' ;' : ''}`;
    }
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          size: '12pt',
          font: 'Times New Roman',
          text: text,
          bold: true,
          italics: true,
          underline: { type: UnderlineType.SINGLE },
          break: 1,
        }),
      ],
    });
  }

  matterInfo(clientData, docData) {
    let uci, rpd, name, uciTitle, rpdTitle, nameTitle, councel, councelFileNo;
    if (clientData?.matterType?.toLowerCase() === 'family') {
      rpdTitle = 'Court File No:';
      uciTitle = 'Internal File No:';
      nameTitle = 'Matter:';
      uci = clientData.matterId.toString() || '';
      rpd = clientData.courtFileNo || '';
      name = docData?.matter || '';
    } else if (clientData?.matterType?.toLowerCase() === 'immigration') {
      uciTitle = 'UCI No:';
      rpdTitle = 'RPD File No:';
      nameTitle = 'Claimant(s):';
      councelFileNo = clientData?.matterId || '';
      councel = 'Counsel File No:';
      uci = clientData?.uciNo ? clientData?.uciNo : '';
      rpd = clientData?.prdFileNo ? clientData?.prdFileNo : '';
      name = `${Boolean(clientData?.user?.firstName) ? clientData?.user?.firstName + ' ' : ''}${
        Boolean(clientData?.user?.middleName) ? clientData?.user?.middleName + ' ' : ''
      }${Boolean(clientData?.user?.lastName) ? clientData?.user?.lastName : ''}${
        docData?.hasClaimants ? ', et al.' : ''
      }`;
    }

    const rows = [
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3600,
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            children: [],
          }),
          new TableCell({
            width: {
              size: 1900,
              type: WidthType.DXA,
            },

            verticalAlign: VerticalAlign.CENTER,
            borders: this.matterDetailsBorders,
            shading: { fill: '#D3D3D3' },
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    size: '12pt',
                    font: 'Times New Roman',
                    text: nameTitle,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2610,
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            shading: { fill: '#D3D3D3' },
            borders: this.matterDetailsBorders,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    size: '12pt',
                    font: 'Times New Roman',
                    text: name,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3600,
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            children: [],
          }),
          new TableCell({
            width: {
              size: 1900,
              type: WidthType.DXA,
            },
            shading: { fill: '#D3D3D3' },

            verticalAlign: VerticalAlign.CENTER,
            borders: this.matterDetailsBorders,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    size: '12pt',
                    font: 'Times New Roman',
                    text: rpdTitle,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2610,
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            borders: this.matterDetailsBorders,
            shading: { fill: '#D3D3D3' },

            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    size: '12pt',
                    font: 'Times New Roman',
                    text: rpd,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
      new TableRow({
        children: [
          new TableCell({
            width: {
              size: 3600,
              type: WidthType.DXA,
            },
            verticalAlign: VerticalAlign.CENTER,
            children: [],
          }),
          new TableCell({
            width: {
              size: 1900,
              type: WidthType.DXA,
            },
            shading: { fill: '#D3D3D3' },

            verticalAlign: VerticalAlign.CENTER,
            borders: this.matterDetailsBorders,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    size: '12pt',
                    font: 'Times New Roman',
                    text: uciTitle,
                  }),
                ],
              }),
            ],
          }),
          new TableCell({
            width: {
              size: 2610,
              type: WidthType.DXA,
            },

            verticalAlign: VerticalAlign.CENTER,
            shading: { fill: '#D3D3D3' },
            borders: this.matterDetailsBorders,
            children: [
              new Paragraph({
                children: [
                  new TextRun({
                    size: '12pt',
                    font: 'Times New Roman',
                    text: uci,
                  }),
                ],
              }),
            ],
          }),
        ],
      }),
    ];

    if (clientData?.matterType?.toLowerCase() === 'immigration') {
      rows.push(
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 3600,
                type: WidthType.DXA,
              },
              verticalAlign: VerticalAlign.CENTER,
              children: [],
            }),
            new TableCell({
              width: {
                size: 1900,
                type: WidthType.DXA,
              },
              shading: { fill: '#D3D3D3' },

              verticalAlign: VerticalAlign.CENTER,
              borders: this.matterDetailsBorders,
              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      size: '12pt',
                      font: 'Times New Roman',
                      text: councel,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              width: {
                size: 2610,
                type: WidthType.DXA,
              },

              verticalAlign: VerticalAlign.CENTER,
              borders: this.matterDetailsBorders,
              shading: { fill: '#D3D3D3' },

              children: [
                new Paragraph({
                  children: [
                    new TextRun({
                      size: '12pt',
                      font: 'Times New Roman',
                      text: councelFileNo.toString(),
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      );
    }
    return new Table({
      borders: this.borders,
      columnWidths: [3600, 1900, 2610],
      rows: [...rows],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  }

  async recipientDoc(
    precedenList,
    lawfirmData,
    clientData,
    ccData,
    recipientAddress,
    lawyerDetails,
    recipientSelected,
    subscribedUser,
    docData,
  ) {
    const clientDetails = recipientSelected === 'Immigration and Refugee Board' ? clientData : '';

    const header = new Table({
      borders: this.borders,
      columnWidths: [9010],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 100,
                type: WidthType.PERCENTAGE,
              },
              borders: {
                bottom: {
                  style: BorderStyle.SINGLE,
                  size: 2,
                  color: '4472C4',
                },
              },
              children: [this.summaryTitle(subscribedUser)],
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });

    const table = new Table({
      borders: this.borders,
      columnWidths: [4710, 5300],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 4710,
                type: WidthType.DXA,
              },
              children: [],
            }),
            new TableCell({
              width: {
                size: 5300,
                type: WidthType.DXA,
              },
              children: [],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 4710,
                type: WidthType.DXA,
              },
              children: [],
            }),
            new TableCell({
              width: {
                size: 5300,
                type: WidthType.DXA,
              },
              children: [],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              width: {
                size: 4710,
                type: WidthType.DXA,
              },
              children: [...this.createRecipientPara(recipientAddress)],
            }),
            new TableCell({
              width: {
                size: 5300,
                type: WidthType.DXA,
              },
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      size: '12pt',
                      font: 'Times New Roman',
                      text: `${moment(new Date()).format('MMMM DD, YYYY')}`,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });

    const doc = new Document({
      sections: [
        {
          children: [
            header,
            table,
            this.matterInfo(clientData, docData),
            this.salutationPara(),
            ...this.createPrecedentList(precedenList),
            lawyerDetails === 'Please add lawyer details'
              ? this.createLawyerParagrapRecipient(lawyerDetails)
              : this.createLawyerParagraphRecipient(subscribedUser, docData),
            new Paragraph({
              alignment: AlignmentType.RIGHT,
              indent: {
                right: 720,
              },

              children: [
                new ImageRun({
                  data: docData?.lawyerSignature,
                  transformation: {
                    width: 200,
                    height: 100,
                  },
                }),
              ],
            }),
            ,
            new Paragraph({
              alignment: AlignmentType.CENTER,
              children: [
                new TextRun({
                  break: 4,
                }),
              ],
            }),
            ...this.createCC(ccData),
          ],
        },
      ],
    });

    const blob = await Packer.toBlob(doc);
    saveAs(blob, 'My Document.docx');
  }

  setCCData(ccData: any): any[] {
    const clientData = ccData?.clientDetails?.matterDetails;
    const name = `${clientData?.user?.firstName} ${
      clientData?.user?.middleName !== null ? clientData?.user?.middleName : ''
    } ${clientData?.user.lastName}`;
    const address = `${clientData.mailingAddress?.streetNumber ? clientData.mailingAddress?.streetNumber : ''} ${
      clientData.mailingAddress?.streetName ? clientData.mailingAddress?.streetName : ''
    } ${clientData.mailingAddress?.city ? clientData.mailingAddress?.city : ''} ${
      clientData.mailingAddress?.province ? clientData.mailingAddress?.province : ''
    } ${clientData.mailingAddress?.postalCode ? clientData.mailingAddress?.postalCode : ''} ${
      clientData.mailingAddress?.country ? clientData.mailingAddress?.country : ''
    }`;
    const immAddress = ccData.ccImmigrationAdd;
    let selectedCC;

    if (ccData && ccData?.selectedCC.length > 0) {
      selectedCC = ccData.selectedCC.map(el => {
        if (el.name === 'Immigration and Refugee Board') {
          el.address = immAddress;
        } else if (el.name === 'Client') {
          el.address = address ? `Client: ${name} ${address}` : '';
        } else if (el.name === 'IRCC') {
          el.address = ccData?.clientDetails?.matterDetails?.ircc
            ? `IRCC: ${ccData?.clientDetails?.matterDetails?.ircc}`
            : '';
        } else if (el.name === 'CBSA') {
          el.address = ccData?.clientDetails?.matterDetails?.cbsaDetails
            ? `CBSA: ${ccData?.clientDetails?.matterDetails?.cbsaDetails}`
            : '';
        } else if (el.name === 'Designated Representative') {
          el.address = ccData?.clientDetails?.matterDetails?.designatedRep
            ? `Designated Representative: ${ccData?.clientDetails?.matterDetails?.designatedRep}`
            : '';
        }
        el.nwAddress = el?.address?.replace(/( \n| \n| \\n|\\n)/gm, ' ');
        return el;
      });
    }
    return selectedCC;
  }

  private createRecipientPara(recipientAdd: string): Paragraph[] {
    if (recipientAdd) {
      const address = recipientAdd.split('\\n');
      return address.map(
        data =>
          new Paragraph({
            alignment: AlignmentType.LEFT,
            children: [
              new TextRun({
                size: '12pt',
                font: 'Times New Roman',
                text: `${data}`,
              }),
            ],
          }),
      );
    }
  }

  createLawyerRecipient(data: any) {
    let lawfirmAdd = `${data.streetNumber ? data.streetNumber : ''} ${data.streetName ? data.streetName : ''} ${
      data.city ? data.city : ''
    } ${data.province ? data.province : ''} ${data.postalCode ? data.postalCode : ''} ${
      data.country ? data.country : ''
    }`;
    const date = new Date();
    const formatDate = moment(date).format('DD MMMM YYYY');

    return new Paragraph({
      alignment: AlignmentType.LEFT,

      children: [
        new TextRun({
          font: 'Arial',
          text: data?.subscriberName,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          text: data?.email,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          text: lawfirmAdd,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          text: data?.phone,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          text: formatDate,
          bold: true,
          color: '#D34655',
          break: 2,
        }),
      ],
    });
  }

  private createChildren(name: string, irbFileNo: string, uciNo: string, matterFileNo: string) {
    const childrens = [];
    if (name !== '') {
      childrens.push(
        new TextRun({
          break: 4,
        }),
        new TextRun({
          font: 'Arial',
          text: name,
          bold: true,
          break: 2,
        }),
      );
    }
    if (irbFileNo !== '') {
      childrens.push(
        new TextRun({
          font: 'Arial',
          text: irbFileNo,
          bold: true,
          break: 1,
        }),
      );
    }
    if (uciNo !== '') {
      childrens.push(
        new TextRun({
          font: 'Arial',
          text: uciNo,
          bold: true,
          break: 1,
        }),
      );
    }
    if (matterFileNo !== '') {
      childrens.push(
        new TextRun({
          font: 'Arial',
          text: matterFileNo,
          bold: true,
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          bold: true,
          break: 1,
        }),
      );
    }

    return childrens;
  }
  private createClientRecipient(data: any): Paragraph {
    let name, irbfileNo, uciNo, matterFileNo;
    if (data) {
      name = `${data?.user?.firstName} ${data?.user?.middleName !== null ? data?.user?.middleName : ''} ${
        data?.user.lastName
      }`;
      irbfileNo = `${data?.prdFileNo !== null ? data?.prdFileNo : ''}`;
      uciNo = `${data?.uciNo !== null ? data?.uciNo : ''}`;
      matterFileNo = `${data?.matterId !== null ? data?.matterId : ''}`;

      return new Paragraph({
        children: this.createChildren(name, irbfileNo, uciNo, matterFileNo),
      });
    }
  }

  private salutationPara() {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          size: '12pt',
          font: 'Times New Roman',
          text: `Dear Sir/Madam,`,
          break: 2,
        }),
      ],
    });
  }

  private recipientLawyerPara() {
    return new Paragraph({
      alignment: AlignmentType.RIGHT,
      spacing: {
        before: 200,
      },

      children: [
        new TextRun({
          font: 'Arial',
          text: 'Lawyer’s Name, Barrister & Solicitor',
          break: 1,
        }),
      ],
    });
  }

  private createLawyer(data: string): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          font: 'Arial',
          text: 'Sincerely,',
          break: 2,
        }),
        new TextRun({
          font: 'Arial',
          text: data,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
      ],
    });
  }

  private createLawyerParagrapRecipient(data: string): Paragraph {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          font: 'Arial',
          text: data,
          bold: true,
          break: 2,
        }),
      ],
    });
  }

  private createPrecedentList(precedentList: any[]): Paragraph[] {
    return precedentList.map(
      data =>
        new Paragraph({
          alignment: AlignmentType.JUSTIFIED,
          children: [
            new TextRun({
              size: '12pt',
              font: 'Times New Roman',
              text: `${data.precedent}`,
              break: 1,
            }),
          ],
        }),
    );
  }

  private createLawfirmPara(data: any) {
    let lawfirmAdd = `${data.streetNumber ? data.streetNumber : ''} ${data.streetName ? data.streetName : ''} ${
      data.city ? data.city : ''
    } ${data.province ? data.province : ''} ${data.postalCode ? data.postalCode : ''} ${
      data.country ? data.country : ''
    }`;
    const date = new Date();
    const formatDate = moment(date).format('DD MMMM YYYY');

    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          font: 'Arial',
          text: data?.subscriberName,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          text: data?.email,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          text: lawfirmAdd,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          text: data?.phone,
          bold: true,
          color: '#D34655',
          break: 1,
        }),
        new TextRun({
          font: 'Arial',
          text: formatDate,
          bold: true,
          color: '#D34655',
          break: 2,
        }),
      ],
    });
  }

  private createClientPara(data: any): Paragraph {
    if (data) {
      const name = `${data?.user?.firstName} ${data?.user?.middleName !== null ? data?.user?.middleName : ''} ${
        data?.user.lastName
      }`;
      const address = `${data.mailingAddress?.streetNumber ? data.mailingAddress?.streetNumber : ''} ${
        data.mailingAddress?.streetName ? data.mailingAddress?.streetName : ''
      } ${data.mailingAddress?.city ? data.mailingAddress?.city : ''} ${
        data.mailingAddress?.province ? data.mailingAddress?.province : ''
      } ${data.mailingAddress?.postalCode ? data.mailingAddress?.postalCode : ''} ${
        data.mailingAddress?.country ? data.mailingAddress?.country : ''
      }`;
      const email = data?.user?.email;
      const phone = data?.user?.mobilePhone ? data?.user?.mobilePhone : '';
      const annotation = `Dear Mr/Mrs. ${data?.user.lastName},`;

      return new Paragraph({
        alignment: AlignmentType.LEFT,
        children: [
          new TextRun({
            font: 'Arial',
            text: name,
            break: 2,
          }),
          new TextRun({
            font: 'Arial',
            text: email,
            break: 1,
          }),
          new TextRun({
            font: 'Arial',
            text: address,
            break: 1,
          }),
          new TextRun({
            font: 'Arial',
            text: phone,
            break: 1,
          }),
          new TextRun({
            font: 'Arial',
            text: annotation,
            break: 2,
          }),
        ],
      });
    }
  }

  private createLawyerPara(data: any): Paragraph {
    let lawyerName, mailingAddress, mobilePhone, email, homePhone;

    lawyerName = `${data?.firstName !== undefined ? data?.firstName : ''} ${
      data?.middleName !== undefined ? data?.middleName : ''
    } ${data?.lastName !== undefined ? data?.lastName : ''}`;
    mailingAddress = `${data.mailingAddress?.streetNumber ? data.mailingAddress?.streetNumber : ''} ${
      data.mailingAddress?.streetName ? data.mailingAddress?.streetName : ''
    } ${data.mailingAddress?.city ? data.mailingAddress?.city : ''} ${
      data.mailingAddress?.province ? data.mailingAddress?.province : ''
    } ${data.mailingAddress?.postalCode ? data.mailingAddress?.postalCode : ''} ${
      data.mailingAddress?.country ? data.mailingAddress?.country : ''
    }`;

    mobilePhone = data?.mobilePhone !== undefined ? data?.mobilePhone : '';
    homePhone = data?.homePhone !== undefined ? data?.homePhone : data?.homePhone;
    email = data?.email !== undefined ? data?.email : '';

    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          font: 'Arial',
          text: 'Sincerely,',
          break: 2,
        }),
        new TextRun({
          font: 'Arial',
          text: lawyerName,
          color: '#D34655',
          break: 1,
        }),
      ],
    });
  }
  private createLawyerParagraphRecipient(userInfo: any, docData: any) {
    const name = `${userInfo?.firstName ? this.capitalizeFirstLetter(userInfo?.firstName) + ' ' : ''}${
      Boolean(userInfo?.middleName) ? this.capitalizeFirstLetter(userInfo?.middleName) + ' ' : ''
    } ${userInfo?.lastName ? this.capitalizeFirstLetter(userInfo?.lastName) : ''}${
      userInfo?.credentials ? ', ' + userInfo?.credentials : ''
    },`;

    const array = [
      new TextRun({
        size: '12pt',
        font: 'Times New Roman',
        text: 'Your truly,',
        break: 3,
      }),
      new TextRun({
        size: '12pt',
        font: 'Times New Roman',
        text: name,
        break: 3,
      }),
    ];

    const otherArray = [
      new TextRun({
        size: '12pt',
        font: 'Times New Roman',
        text: 'Your truly,',
        break: 3,
      }),
      new TextRun({
        size: '12pt',
        font: 'Times New Roman',
        text: name,
        break: 3,
      }),
      new TextRun({
        size: '12pt',
        font: 'Times New Roman',
        text: userInfo?.designations || '',
        break: 1,
      }),
    ];

    return new Table({
      borders: this.borders,
      columnWidths: [9500],
      rows: [
        new TableRow({
          children: [
            new TableCell({
              margins: {
                left: 4600,
              },
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: docData?.user?.credentials ? otherArray : array,
                }),
              ],
            }),
          ],
        }),
      ],
      width: {
        size: 100,
        type: WidthType.PERCENTAGE,
      },
    });
  }
  private createCC(data: any) {
    if (data && data.length > 0) {
      return data.map((item, index) => {
        const addressText = `${index === 0 ? 'CC: ' : ''}${item?.nwAddress || ''}`;
        return new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              size: '12pt',
              font: 'Times New Roman',
              text: addressText,
              break: Boolean(item?.nwAddress) ? 1 : null,
            }),
          ],
        });
      });
    } else {
      return [];
    }
  }

  private generateCSV(selectedFields: any, dockets: any) {
    const formattedDataArray = this.buildContent(dockets, selectedFields);
    let csvMeta = 'data:text/csv;charset=utf-8,';
    formattedDataArray.forEach(data => {
      const row = data
        .join('|')
        .replace(/            /g, ' ')
        .replace(/\n/g, '');
      csvMeta = csvMeta + row + '\t\n';
    });
    window.open(encodeURI(csvMeta), '_blank');
  }

  private copyToClipboard(selectedFields: any, dockets: any) {
    const formattedDataArray = this.buildContent(dockets, selectedFields);
    navigator.clipboard.writeText(formattedDataArray.join(','));
    this.toastr.success('Copied!!!', 'Success');
  }

  private generatePDF(selectedFields: any, dockets: any) {
    const docDefinition: any = {
      content: [
        { text: 'Dockets', style: 'header' },
        {
          table: {
            headerRows: 1,
            widths: this.widths[selectedFields.length],
            body: this.buildContent(dockets, selectedFields),
          },
        },
      ],
      styles: {
        header: {
          fontSize: 22,
          bold: true,
          paddingBottom: 12,
        },
      },
    };
    pdfMake.createPdf(docDefinition).download(`${this.matterId}-dockets`);
  }

  private async createWordDoc(selectedFields: any, dockets: any, matterDetails: any) {
    const headerTitle = `${matterDetails.matterId} - ${
      matterDetails.user.lastName
    }, ${matterDetails.user.firstName?.slice(0, 1)}.`;
    let title;
    if (matterDetails.matterType.toLowerCase() === 'immigration') {
      title = `${headerTitle} ${matterDetails.matterType.slice(0, 1) + 'MM'}`;
    } else {
      title = `${headerTitle} ${matterDetails.matterType.slice(0, 1) + 'M'}`;
    }

    const doc = new Document({
      title: title,
      sections: [
        {
          children: [
            new Paragraph({
              text: title,
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({ text: 'DOCKETS', heading: HeadingLevel.HEADING_5 }),
            this.createTable(selectedFields, dockets),
          ],
        },
      ],
    });
    const blob = await Packer.toBlob(doc);

    saveAs(blob, 'My Document.docx');
  }

  private setCellValue(columns: any, row: any) {
    let dataRow = [];
    columns.forEach((column, ind) => {
      switch (column) {
        case 'Date':
          row.taskDate !== '' && row.taskDate !== undefined
            ? dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph(row.taskDate)],
                }),
              )
            : dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph('N/A')],
                }),
              );
          break;
        case 'Time':
          const time = `${row.taskStart}\n${row.taskEnd}`;
          time !== '' && time !== undefined && time !== null
            ? dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph(time)],
                }),
              )
            : dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph('N/A')],
                }),
              );
          break;
        case 'Particular':
          const particular = !row.taskDescription
            ? `${row.taskTitle}`
            : `${row.taskTitle}\n${row.taskDescription.replace(/<br\s*\/?>/gi, '\n')}`;
          particular !== '' && particular !== null && particular !== undefined
            ? dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph(particular)],
                }),
              )
            : dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph('N/A')],
                }),
              );
          break;
        case 'Hour(s)':
          row.billableUnits !== '' && row.billableUnits !== undefined
            ? dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph(row.billableUnits)],
                }),
              )
            : dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph('N/A')],
                }),
              );
          break;
        case 'Counsel / Clerk':
          const initiase = this.getNameInitials(row.closedBy);
          initiase !== '' && initiase !== undefined && initiase !== null
            ? dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph(initiase)],
                }),
              )
            : dataRow.push(
                new TableCell({
                  width: {
                    size: 20,
                    type: WidthType.PERCENTAGE,
                  },
                  children: [new Paragraph('N/A')],
                }),
              );
          break;
        default:
          break;
      }
    });

    return dataRow;
  }

  private createTable(selectedFields: any, dockets: any) {
    let children = [];
    selectedFields.forEach(field => {
      children.push(
        new TableCell({
          width: {
            size: 20,
            type: WidthType.PERCENTAGE,
          },
          children: [new Paragraph(field)],
        }),
      );
    });

    let rows = [
      new TableRow({
        children: children,
      }),
    ];
    dockets.forEach(docket => {
      rows.push(
        new TableRow({
          children: this.setCellValue(selectedFields, docket),
        }),
      );
    });

    return new Table({
      columnWidths: this.columnWidths[selectedFields.length],
      rows: rows,
    });
  }

  private getNameInitials(name) {
    if (name) {
      //if middle name is absent
      return name.split(' ')[2] !== '' && name.split(' ')[2] !== undefined && name.split(' ')[2] !== null
        ? `${name.split(' ')[0].substring(0, 1)}.${name.split(' ')[2].substring(0, 1)}`
        : `${name.split(' ')[0].substring(0, 1)}.${name.split(' ')[1].substring(0, 1)}`;
    }
  }

  private buildContent(data, columns) {
    const body = [];

    body.push(columns);

    data.forEach(row => {
      const dataRow = [];

      columns.forEach(column => {
        switch (column) {
          case 'Date':
            row.taskDate !== '' && row.taskDate !== undefined ? dataRow.push(row.taskDate) : dataRow.push('N/A');
            break;
          case 'Time':
            const time = `${row.taskStart}\n${row.taskEnd}`;
            time !== '' && time !== undefined && time !== null ? dataRow.push(time) : dataRow.push('N/A');
            break;
          case 'Particular':
            const particular = !row.taskDescription
              ? `${row.taskTitle}`
              : `${row.taskTitle}\n${row.taskDescription.replace(/<br\s*\/?>/gi, '\n')}`;
            particular !== '' && particular !== null && particular !== undefined
              ? dataRow.push(particular)
              : dataRow.push('N/A');
            break;
          case 'Hour(s)':
            row.billableUnits !== '' && row.billableUnits !== undefined
              ? dataRow.push(row.billableUnits)
              : dataRow.push('N/A');
            break;
          case 'Counsel / Clerk':
            const initiase = this.getNameInitials(row.closedBy);
            initiase !== '' && initiase !== undefined && initiase !== null
              ? dataRow.push(initiase)
              : dataRow.push('N/A');
            break;
          default:
            break;
        }
      });

      body.push(dataRow);
    });

    return body;
  }

  async accountSummary(invoices: any, matterDetails: any) {
    pdfMake.fonts = {
      Times: {
        normal: `${window.location.origin}/assets/fonts/Times/TIMES.ttf`,
        bold: `${window.location.origin}/assets/fonts/Times/TIMESBD.ttf`,
        italics: `${window.location.origin}/assets/fonts/Times/TIMESI.ttf`,
        bolditalics: `${window.location.origin}/assets/fonts/Times/TIMESBI.ttf`,
      },
    };

    const address = `${invoices?.lawfirmInfo?.streetNumber ? invoices?.lawfirmInfo?.streetNumber + ' ' : ''}${
      invoices?.lawfirmInfo?.streetName ? invoices?.lawfirmInfo?.streetName + '. ' : ''
    }${invoices?.lawfirmInfo?.appartmentNumber ? 'Suite ' + invoices?.lawfirmInfo?.appartmentNumber + ' ' : ''}${
      invoices?.lawfirmInfo?.city ? invoices?.lawfirmInfo?.city + ' ' : ''
    }${invoices?.lawfirmInfo?.province ? invoices?.lawfirmInfo?.province + ' ' : ''}${
      invoices?.lawfirmInfo?.postalCode ? invoices?.lawfirmInfo?.postalCode : ''
    }\n${invoices?.lawfirmInfo?.homePhone ? 'Tel/Fax ' + invoices?.lawfirmInfo?.homePhone + ' ' : ''}${
      invoices?.lawfirmInfo?.email ? 'Email: ' + invoices?.lawfirmInfo?.email : ''
    }${invoices?.lawfirmInfo?.website ? '\n' + invoices?.lawfirmInfo?.website : ''} \n \n \n`;

    const docDefinition: any = {
      footer: (currentPage, pageCount) => {
        const footer = {
          layout: 'noBorders',
          fontSize: 8,
          margin: [25, 0, 25, 0],
          table: {
            widths: ['*', '*'],
            body: [[{ text: 'Page  ' + currentPage.toString() + ' of ' + pageCount }]],
          },
        };
        return footer;
      },

      content: [
        {
          text: `${invoices?.user?.firstName} ${invoices?.user.lastName}${
            invoices?.user?.credentials ? ', ' + invoices?.user?.credentials : ''
          }${invoices?.user?.designations ? '\n' + invoices?.user?.designations : ''}\n`,
          style: 'header',
        },
        {
          text: address,
          style: 'alignment',
        },
        {
          canvas: [{ type: 'line', x1: 10, y1: -15, x2: 510, y2: -15, lineWidth: 1 }],
        },
        { text: 'STATEMENT OF ACCOUNT \n', style: ['boldText', 'alignment', 'fontItalic'], paddingBottom: 10 },
        { text: '\n' },
        {
          table: {
            headerRows: 1,
            widths: ['*', '*', '*', '*', '*', '*'],
            body: [
              [
                { text: 'Bill To:', style: ['newBg'] },
                {
                  text: `${matterDetails.user.lastName} ${
                    matterDetails.user?.middleName !== null ? matterDetails.user.middleName : ''
                  } ${matterDetails.user.firstName}`,
                  bold: true,
                },
                { text: 'Our File No:', style: ['newBg'] },
                { text: matterDetails.matterId, bold: true },
                { text: 'Statement Date', style: ['newBg'] },
                { text: `${moment().format('MMMM DD, YYYY')}`, bold: true },
              ],
              [
                { text: 'Address:', style: ['newBg'] },
                { text: '' },
                { text: 'Statement No:', style: ['newBg'] },
                { text: '1-' + matterDetails.matterId, bold: true },
                { text: '', bold: true, style: ['newBg'] },
                { text: '', bold: true },
              ],
            ],
          },
        },
        {
          text: [
            `\n \n As it is our firm's practice to render accounts on the completion of the matter, enclosed herewith is our Statement of Account No: `,
            { text: `${matterDetails.matterId}, `, bold: true },
            {
              text: `${moment().format('MMMM DD, YYYY')}`,
            },
            `, rendered in connection with above-noted file.`,
          ],
        },
        {
          table: {
            headerRows: 1,
            widths: ['*'],

            body: [
              [
                {
                  text: 'Details of Account',
                  style: ['alignment', 'headerbg', 'fontItalic', 'boldText'],
                  borderColor: ['#337AB7', '#337AB7', '#337AB7', '#337AB7'],
                },
              ],
            ],
          },
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 100],
            body: [
              [
                {
                  text: 'Incurred Fees.:',
                  style: ['fontItalic'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
                {
                  text: invoices.incurredFees ? '$' + invoices.incurredFees.toFixed(2) : '$0.00',
                  style: ['boldText'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
              [
                { text: 'HST(13%)', borderColor: ['#808080', '#fff', '#808080', '#808080'] },
                {
                  text: invoices.hst ? '$' + invoices.hst.toFixed(2) : '$0.00',
                  style: ['boldText'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
              [
                { text: 'Disbursement', borderColor: ['#808080', '#fff', '#808080', '#808080'] },
                {
                  text: invoices.disbursements ? '$' + invoices.disbursements.toFixed(2) : '$0.00',
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
              [
                {
                  text: 'TOTAL',
                  style: ['background', 'boldText'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
                {
                  text: invoices.grandTotal ? '$' + invoices.grandTotal.toFixed(2) : '$0.00',
                  style: ['background', 'boldText'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
              [
                {
                  text: 'Received Total (see page 4 for details)',
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
                {
                  text: invoices.receivedPayments ? '$' + invoices.receivedPayments.toFixed(2) : '$0.00',
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
              [
                { text: 'Outstanding Balance:', borderColor: ['#808080', '#fff', '#808080', '#337AB7'] },
                {
                  text: invoices.outstandingBalance ? '$' + invoices.outstandingBalance.toFixed(2) : '$0.00',
                  borderColor: ['#808080', '#fff', '#808080', '#337AB7'],
                },
              ],
            ],
          },
        },

        {
          text: `\n I trust the above summary clarifies the details of the enclosed invoice. \n

          In the event that you have any questions with respect to the enclosed account please contact me.\n
          Any questions, clarifications or explanations required should be dealt with by date of this account. Payments due upon receipt. Pursuant to the provision provided by the Solicitor Act, interest at the rate provided there-under will be charged on all invoices.\n \n \n \n `,
        },
        { text: 'Yours very truly.', style: 'cstmRight' },
        { text: 'Cemal Acikgoz, B.A., M.A.,J.D.', style: 'rightAlign' },
        '\n \n',
        {
          text: `Business Number: ${invoices?.lawfirmInfo?.hstNo ? invoices?.lawfirmInfo?.hstNo : ''}`,
          pageBreak: 'after',
        },

        { text: 'TIME DOCKETS', style: ['alignment', 'boldText'] },
        '\n',
        {
          table: {
            headerRows: 1,
            widths: ['auto', '*', 'auto', 22],
            body: this.getBody(matterDetails, invoices),
          },
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 72],
            body: [
              [
                {
                  text: 'TOTAL HOURS',
                  style: ['boldText', 'alignment'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
                {
                  text: `${this.totalHours}`,
                  style: ['boldText', 'leftAlign'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
              [
                {
                  text: 'LEGAL FEES',
                  style: ['boldText', 'alignment', 'background'],
                  borderColor: ['#808080', '#808080', '#808080', '#808080'],
                },
                {
                  text: `$${this.billedFees.toFixed(2)}`,
                  style: ['boldText', 'background'],
                  borderColor: ['#808080', '#808080', '#808080', '#808080'],
                },
              ],
            ],
          },
        },

        { text: 'DISBURSEMENTS:', fontSize: 14, bold: true, margin: [0, 15, 0, 10] },

        {
          table: {
            headerRows: 1,
            widths: ['auto', '*', 100],
            body: this.getDisbursementBody(invoices.invoices),
          },
          unbreakable: true,
        },

        {
          table: {
            border: [],
            headerRows: 1,
            widths: ['*', 100],
            body: [
              [
                {
                  text: 'TOTAL',
                  style: ['boldText', 'alignment'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
                {
                  text: `$${this.totalCost.toFixed(2)}`,
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
            ],
          },
          unbreakable: true,
        },

        {
          table: {
            headerRows: 1,
            widths: ['*', 100],
            body: [
              [
                {
                  text: 'GRAND TOTAL:',
                  style: ['boldText', 'alignment', 'background'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
                {
                  text: `$${this.totalCost.toFixed(2)}`,
                  style: ['boldText', 'background'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
            ],
          },
          unbreakable: true,
        },

        { text: 'RECEIVED PAYMENT RECORD:', fontSize: 14, bold: true, margin: [0, 15, 0, 10] },

        {
          table: {
            headerRows: 1,

            widths: ['auto', 'auto', '*', 100],
            body: this.recievedPaymentDetails(invoices.paymentInfo, matterDetails),
          },
          unbreakable: true,
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 100],
            body: [
              [
                {
                  text: 'TOTAL',
                  style: ['boldText', 'alignment', 'background'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
                {
                  text: `$${this.totalPaymentRecieved.toFixed(2)}`,
                  style: ['boldText', 'background'],
                  borderColor: ['#808080', '#fff', '#808080', '#808080'],
                },
              ],
            ],
          },
          unbreakable: true,
        },
      ],

      defaultStyle: {
        fontSize: 12,
        font: 'Times',
      },

      styles: {
        header: {
          bold: true,
          color: '#006FBE',
          alignment: 'center',
        },
        fontColor: {
          color: '#006FBE',
          alignment: 'center',
        },
        boldText: {
          bold: true,
        },
        alignment: {
          alignment: 'center',
        },
        cstmRight: {
          margin: [390, 0, 0, 0],
        },
        rightAlign: {
          alignment: 'right',
        },
        leftAlign: {
          alignment: 'left',
        },
        background: {
          color: 'black',
          fillColor: '#FCF0E6',
        },
        newBg: {
          color: 'black',
          fillColor: '#E7EFF6',
        },
        headerbg: {
          color: 'white',
          margin: [2, 5, 0, 5],
          fillColor: '#337AB7',
        },
        bgColor: {
          fillColor: '#D2DFEE',
        },
        fontWeight: {
          fontWeight: 'bold',
        },
        timeDocketBg: {
          color: 'black',
          fillColor: '#f1f2f2',
        },
        fontItalic: {
          italics: true,
        },
      },
    };

    pdfMake.createPdf(docDefinition).download('Statement of Account.pdf');
  }

  totalHours = 0;

  billedFees = 0;
  getBody(matterDetails, invoice) {
    let body = [];
    body.push([
      {
        text: 'Date/Time',
        style: ['boldText', 'headerbg'],
        borderColor: ['#337AB7', '#337AB7', '#808080', '#337AB7'],
      },
      {
        text: 'Particular',
        style: ['boldText', 'alignment', 'headerbg'],
        borderColor: ['#337AB7', '#337AB7', '#808080', '#337AB7'],
      },
      {
        text: 'Hour(s)',
        style: ['boldText', 'headerbg'],
        borderColor: ['#337AB7', '#337AB7', '#808080', '#337AB7'],
      },
      {
        text: '',
        style: ['boldText', 'headerbg'],
        borderColor: ['#337AB7', '#337AB7', '#337AB7', '#337AB7'],
      },
    ]);

    let docketsData = matterDetails?.dockets?.map((docket, index) => {
      if (+docket.billableUnits !== 0 && docket.billableUnits !== '0:0') {
        return [
          {
            text: docket.taskDate,
            borderColor: ['#808080', '#808080', '#808080', '#808080'],
          },
          {
            text: docket.taskTitle || docket.taskDescription,
            borderColor: ['#808080', '#808080', '#808080', '#808080'],
          },
          {
            text: docket?.billableUnits?.replace(':0', '') || '',
            style: ['boldText'],
            borderColor: ['#808080', '#808080', '#808080', '#808080'],
          },

          {
            text: this.getNameInitials(docket.closedBy),
            style: ['boldText'],
            borderColor: ['#808080', '#808080', '#808080', '#808080'],
          },
        ];
      }
    });

    const dockets = docketsData?.filter(docket => Boolean(docket));

    let legalFees = 0;
    invoice?.invoices.forEach(memberArr => {
      memberArr?.members?.forEach(member => {
        member?.dockets.forEach(docket => {
          legalFees += +docket?.hours.toFixed(2) * +member?.hourlyRate.$numberDecimal;
        });
      });
    });

    this.billedFees = +legalFees.toFixed(2);

    const sumWithInitial = matterDetails?.dockets?.reduce(
      (previousValue, currentValue) => previousValue + parseFloat(currentValue?.billableUnits),
      0,
    );
    this.totalHours = sumWithInitial.toFixed(2);
    body = body.concat(dockets);
    return body;
  }

  totalCost = 0;
  getDisbursementBody(disbursement) {
    let body = [];

    if (disbursement) {
      body.push([
        {
          text: 'Date/Time',
          style: ['boldText', 'headerbg'],
          borderColor: ['#337AB7', '#337AB7', '#808080', '#337AB7'],
        },
        {
          text: 'Explanation',
          style: ['boldText', 'alignment', 'headerbg'],
          borderColor: ['#337AB7', '#337AB7', '#808080', '#337AB7'],
        },
        {
          text: 'Cost($)',
          style: ['boldText', 'headerbg'],
          borderColor: ['#337AB7', '#337AB7', '#337AB7', '#337AB7'],
        },
      ]);

      const disbursementData = disbursement
        .map((disburse, index) => {
          return disburse?.disbursement?.items?.map(item => {
            return item;
          });
        })
        .flat();

      const dis = disbursementData.filter(disbursement => Boolean(disbursement));

      if (dis.length === 0) {
        return body;
      }

      const dockets = dis.map((disbursement, index) => {
        const date = moment(disbursement?.created).format('DD-MM-YYYY') as string;
        return [
          {
            text: date,
            borderColor: ['#808080', '#808080', '#808080', '#808080'],
          },
          {
            text: disbursement?.itemTitle,
            borderColor: ['#808080', '#808080', '#808080', '#808080'],
          },
          {
            text: `$${disbursement?.amount.$numberDecimal}`,
            borderColor: ['#808080', '#808080', '#808080', '#808080'],
          },
        ];
      });

      this.totalCost = dis.reduce((prev, disburse) => prev + +disburse?.amount.$numberDecimal, 0);

      body = body.concat(dockets);

      return body;
    }
  }

  totalPaymentRecieved = 0;
  recievedPaymentDetails(paymentDetails, matterData) {
    let body = [];
    if (paymentDetails) {
      body.push([
        {
          text: 'Date',
          style: ['boldText', 'headerbg', 'alignment'],
          borderColor: ['#337AB7', '#337AB7', '#808080', '#337AB7'],
        },
        {
          text: 'Method',
          style: ['boldText', 'alignment', 'headerbg'],
          borderColor: ['#337AB7', '#337AB7', '#808080', '#337AB7'],
        },
        {
          text: 'Client & Purpose of Payments',
          style: ['boldText', 'headerbg', 'alignment'],
          borderColor: ['#337AB7', '#337AB7', '#808080', '#337AB7'],
        },
        {
          text: 'Amount',
          style: ['boldText', 'alignment', 'headerbg'],
          borderColor: ['#337AB7', '#337AB7', '#337AB7', '#337AB7'],
        },
      ]);

      const dockets = paymentDetails?.map((payment, index) => {
        if (payment.payments && payment.payments.length > 0) {
          const date = moment(payment.payments[0]?.date).format('DD-MM-YYYY') as string;
          return [
            {
              text: date,
              style: ['boldText', 'leftAlign'],
              borderColor: ['#808080', '#808080', '#808080', '#808080'],
            },
            {
              text: payment?.payments[0].paymentMethod,
              style: ['leftAlign'],
              borderColor: ['#808080', '#808080', '#808080', '#808080'],
            },
            {
              text: `${matterData.user.firstName} ${
                matterData.user?.middleName !== null ? matterData.user?.middleName : ''
              } ${matterData.user.lastName}  ${
                payment.payments[0].comments ? '- ' + payment.payments[0].comments : ''
              }`,
              style: ['leftAlign'],
              borderColor: ['#808080', '#808080', '#808080', '#808080'],
            },
            {
              text: `$${payment?.payments[0].amount.$numberDecimal}`,
              style: ['leftAlign'],
              borderColor: ['#808080', '#808080', '#808080', '#808080'],
            },
          ];
        }
      });

      this.totalPaymentRecieved = paymentDetails.reduce(
        (prev, payment) =>
          prev + (payment?.payments && payment.payments.length > 0 ? +payment?.payments[0].amount.$numberDecimal : 0),
        0,
      );

      body = body.concat(dockets);

      return body;
    }
  }

  capitalizeFirstLetter(str: string): string {
    return str ? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase() : '';
  }

  private summaryTitle(userInfo) {
    const name = `${userInfo?.firstName ? this.capitalizeFirstLetter(userInfo.firstName) + ' ' : ''}${
      userInfo?.middleName ? this.capitalizeFirstLetter(userInfo.middleName) + ' ' : ''
    }${userInfo?.lastName ? this.capitalizeFirstLetter(userInfo.lastName) : ''}, Barrister & Solicitor`;

    const streetInfo = `${userInfo.streetNumber ? userInfo.streetNumber + ' ' : ''}${
      userInfo.streetName ? userInfo.streetName + ', ' : ', '
    }${userInfo.appartmentNumber ? 'Suite : ' + userInfo.appartmentNumber + ', ' : ''}${
      userInfo.city ? userInfo.city + ' ' : ''
    }${userInfo.province ? userInfo.province + ', ' : ''}${userInfo.postalCode ? userInfo.postalCode + ', ' : ''}`;
    const contact = `${userInfo.homePhone ? 'Tel : ' + userInfo.homePhone + ' ' : ''}${
      userInfo.fax ? 'Fax : ' + userInfo.fax + ' ' : ''
    }${userInfo.email ? 'Email : ' + userInfo.email + ' ' : ''}`;
    return new Paragraph({
      alignment: AlignmentType.CENTER,
      children: [
        new TextRun({
          size: '12pt',
          font: 'Times New Roman',
          text: name,
          bold: true,
          color: '#0F77C1',
        }),
        new TextRun({
          size: '12pt',
          font: 'Times New Roman',
          text: streetInfo,
          break: 1,
          color: '#0F77C1',
        }),
        new TextRun({
          size: '12pt',
          font: 'Times New Roman',
          text: contact,
          break: 1,
          color: '#0F77C1',
        }),
        new TextRun({
          size: '12pt',
          font: 'Times New Roman',
          text: 'www.acikgozlaw.ca',
          break: 1,
          color: '#0F77C1',
        }),
      ],
    });
  }

  private break() {
    return new Paragraph({
      children: [
        new TextRun({
          break: 1,
        }),
      ],
    });
  }

  private statementDetails(matterDetails) {
    return new Table({
      borders: this.borders,
      columnWidths: [6010, 3000],

      rows: [
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.CENTER,
                  children: [
                    new TextRun({
                      text: 'STATEMENT OF ACCOUNT',
                      italics: true,
                      bold: true,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Bill To:',
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${matterDetails.user.lastName} ${
                        matterDetails.user.middleName ? matterDetails.user.middleName : ''
                      } ${matterDetails.user.firstName}`,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Our File No:',
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: matterDetails.matterId,
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Statement Date:',
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: `${moment().format('MMMM DD, YYYY')}`,
                    }),
                  ],
                }),
              ],
            }),
          ],
        }),
        new TableRow({
          children: [
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Address:',
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [new TextRun({})],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: 'Statement No:',
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [new TextRun({})],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [
                    new TextRun({
                      text: '',
                    }),
                  ],
                }),
              ],
            }),
            new TableCell({
              children: [
                new Paragraph({
                  alignment: AlignmentType.LEFT,
                  children: [new TextRun({})],
                }),
              ],
            }),
          ],
        }),
      ],
    });
  }

  private statement(matterDetails) {
    return new Paragraph({
      children: [
        new TextRun({
          break: 1,
          text: `As it is our firm's practice to render accounts on the completion of the matter, enclosed herewith is our Statement of Account No: ${
            matterDetails.matterId
          }, ${moment().format('MMMM DD, YYYY')}, rendered in connection with above-noted file.`,
        }),
      ],
    });
  }

  private notice() {
    return new Paragraph({
      children: [
        new TextRun({
          break: 1,
          text: 'I trust the above summary clarifies the details of the enclosed invoice.',
          italics: true,
        }),
        new TextRun({
          break: 2,
          text: 'In the event that you have any questions with respect to the enclosed account please contact me.',
        }),
        new TextRun({
          break: 2,
          text: 'Any questions, clarifications or explanations required should be dealt with by date of this account. Payments due upon receipt. Pursuant to the provision provided by the Solicitor Act, interest at the rate provided there-under will be charged on all invoices.',
        }),

        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [
            new TextRun({
              break: 1,
              text: 'Yours very truly.',
            }),
            new TextRun({
              break: 1,
              text: 'Cemal Acikgoz, B.A., M.A.,J.D.',
            }),
          ],
        }),
        new Paragraph({
          alignment: AlignmentType.LEFT,
          children: [
            new TextRun({
              break: 1,
              text: 'Business Number: 80159 5315 RT0001 ',
            }),
          ],
        }),
      ],
    });
  }

  private signature() {
    return new Paragraph({
      alignment: AlignmentType.RIGHT,
      children: [
        new TextRun({
          break: 2,
          text: 'Yours very truly.',
        }),
        new TextRun({
          break: 2,
          text: 'Cemal Acikgoz, B.A., M.A.,J.D.',
        }),
      ],
    });
  }

  private businessNumber() {
    return new Paragraph({
      alignment: AlignmentType.LEFT,
      children: [
        new TextRun({
          break: 2,
          text: 'Business Number: 80159 5315 RT0001 ',
        }),
      ],
    });
  }

  private pageBreak() {
    return new Paragraph({
      pageBreakBefore: true,
    });
  }

  private timeDocketsData(matterDetails) {
    let children = [];
    matterDetails.dockets.forEach((docket, index) => {
      if (index != 0) {
        children.push(
          new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: docket.taskDate,
                        bold: true,
                      }),
                      new TextRun({
                        text: docket.taskStart,
                        bold: true,
                        break: 1,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: docket.taskDescription,
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: docket.taskHours,
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    alignment: AlignmentType.LEFT,
                    children: [
                      new TextRun({
                        text: this.getNameInitials(docket.closedBy),
                        bold: true,
                      }),
                    ],
                  }),
                ],
              }),
            ],
          }),
        );
      }
    });

    return new Table({
      rows: children,
      columnWidths: [1300, 6200, 900, 600],
    });
  }

  private timeDockets(matterDetails) {
    let data = [];

    const dockets = matterDetails.dockets.map((docket, index) => {
      let accountDetails = [];
      if (index != 0) {
        if (docket.taskDate && docket.taskStart) {
          accountDetails.push(`${docket.taskDate}\n${docket.taskStart}`);
        }

        if (docket.taskDescription) {
          accountDetails.push(docket.taskDescription);
        }

        if (docket.taskHours) {
          accountDetails.push(docket.taskHours);
        }

        if (docket.closedBy) {
          accountDetails.push(this.getNameInitials(docket.closedBy));
        }
        return accountDetails;
      }
    });
    dockets.shift();

    data.push(dockets);
  }

  private getTotalHours(matterDetails) {
    const hours = 0;

    const sumWithInitial = matterDetails.dockets.reduce(
      (previousValue, currentValue) => +previousValue + +currentValue.billableUnits,
      hours,
    );
  }

  trustDisbursements(disbursements: any, grandTotal: any, whichType: string) {
    const wid = whichType === 'receipts' ? ['*', 100, 100, '*'] : ['*', 80, '*', 80, 80, 80, '*'];
    const name = whichType === 'receipts' ? 'General Receipts' : 'General Disbursement';
    const docDefinition: any = {
      content: [
        { text: 'Cemal ACIKGOZ, Barrister & Solicitor\n', style: 'header' },
        {
          text: '795 Wilson Ave. Suite 301 Torronto ON. M3K 1E4 \n Tel/Fax+1 416 371 5000 Email: cemal@ackgozlaw.ca \n www.acikgozlaw.ca, \n \n \n',
          style: 'fontColor',
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 10,
              y1: -15,
              x2: 510,
              y2: -15,
              lineWidth: 1,
            },
          ],
        },
        {
          text: name,
          style: ['boldText', 'alignment', 'fontItalic'],
          margin: [0, 0, 0, 5],
        },
        {
          table: {
            headerRows: 1,
            widths: wid,
            body: this.buildTrustDisbursements(disbursements, whichType),
          },
        },
        {
          table: {
            headerRows: 0,
            widths: ['*', 80],

            body: [
              // Total
              [
                {
                  text: 'Total',
                  style: 'itemsFooterSubTitle',
                },
                {
                  text: `$${grandTotal.toFixed(2)}`,
                  style: 'itemsFooterSubValue',
                },
              ],
            ],
          }, // table
          layout: 'lightHorizontalLines',
        },
      ],
      styles: {
        header: {
          bold: true,
          color: '#006FBE',
          alignment: 'center',
        },
        fontColor: {
          color: '#006FBE',
          alignment: 'center',
        },
        textFont: {
          color: '#000000',
        },
        boldText: {
          bold: true,
        },
        alignment: {
          alignment: 'center',
        },
        fontItalic: {
          italics: true,
        },
        thbg: {
          bold: true,
          fillColor: '#c8ced3',
          alignment: 'center',
        },
        smallText: {
          fontSize: 10,
        },
        itemsFooterSubTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterSubValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
      },
    };
    const fileName = whichType === 'receipts' ? `general-receipts-${Date.now()}` : `general-disbursement-${Date.now()}`;
    pdfMake.createPdf(docDefinition).download(fileName);
  }

  private buildTrustDisbursements(data, whichType) {
    const body = [];
    let headings = whichType === 'receipts' ? this.receiptsHeading : this.disHeading;
    body.push(headings);
    data.forEach(row => {
      const dataRow = [];
      if (whichType === 'receipts') {
        const amount = parseFloat(row?.amount?.$numberDecimal).toFixed(2);
        dataRow.push(
          { text: moment(row.date).format('DD-MM-YYYY'), style: ['smallText'] },
          { text: row?.clientName, style: ['smallText'] },
          { text: amount, style: ['smallText'] },
          { text: row?.paymentMethod, style: ['smallText'] },
        );
      } else {
        const amount = parseFloat(row?.amount?.$numberDecimal).toFixed(2);
        const tax = parseFloat(row?.tax?.$numberDecimal).toFixed(2);
        const total = parseFloat(row?.total?.$numberDecimal).toFixed(2);
        dataRow.push(
          { text: moment(row.timestamps).format('DD-MM-YYYY'), style: ['smallText'] },
          { text: row?.paymentMethod, style: ['smallText'] },
          { text: row?.paidTo, style: ['smallText'] },
          { text: row?.particulars, style: ['smallText'] },
          { text: amount, style: ['smallText'] },
          { text: tax, style: ['smallText'] },
          { text: total, style: ['smallText'] },
        );
      }
      body.push(dataRow);
    });
    return body;
  }

  async clientTrustLedger(ledger: any, total: any) {
    let docDefinition = {
      content: [
        { text: 'Cemal ACIKGOZ, Barrister & Solicitor\n', style: 'header' },
        {
          text: '795 Wilson Ave. Suite 301 Torronto ON. M3K 1E4 \n Tel/Fax+1 416 371 5000 Email: cemal@ackgozlaw.ca \n www.acikgozlaw.ca, \n \n \n',
          style: 'fontColor',
        },
        {
          canvas: [
            {
              type: 'line',
              x1: 10,
              y1: -15,
              x2: 510,
              y2: -15,
              lineWidth: 1,
            },
          ],
        },
        {
          text: "Clients' Trust Ledger \n",
          style: ['boldText', 'alignment', 'fontItalic'],
          margin: [0, 0, 0, 5],
        },
        {
          table: {
            headerRows: 1,
            widths: ['*', 100, 100, 100, '*'],
            body: this.getLedgerData(ledger),
          },
        },
        {
          table: {
            headerRows: 0,
            widths: ['*', 80],

            body: [
              // Total
              [
                {
                  text: 'Total',
                  style: 'itemsFooterSubTitle',
                },
                {
                  text: `$${total.toFixed(2)}`,
                  style: 'itemsFooterSubValue',
                },
              ],
            ],
          }, // table
          layout: 'lightHorizontalLines',
        },
      ],
      styles: {
        header: {
          bold: true,
          color: '#006FBE',
          alignment: 'center',
        },
        fontColor: {
          color: '#006FBE',
          alignment: 'center',
        },
        textFont: {
          color: '#000000',
        },
        boldText: {
          bold: true,
        },
        alignment: {
          alignment: 'center',
        },
        fontItalic: {
          italics: true,
        },
        thbg: {
          bold: true,
          fillColor: '#c8ced3',
          alignment: 'center',
        },
        smallText: {
          fontSize: 10,
        },
        itemsFooterSubTitle: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'right',
        },
        itemsFooterSubValue: {
          margin: [0, 5, 0, 5],
          bold: true,
          alignment: 'center',
        },
      },
    };
    pdfMake.createPdf(docDefinition).download('Trust Ledger.pdf');
  }

  getLedgerData(ledger) {
    let body = [];
    body.push([
      { text: 'Date', style: ['thbg', 'textFont'] },
      { text: 'File Number', style: ['thbg', 'textFont'] },
      { text: 'Client Name', style: ['thbg', 'textFont'] },
      { text: 'Matter Type', style: ['thbg', 'textFont'] },
      { text: 'Balance', style: ['thbg', 'textFont'] },
    ]);

    const data = ledger.map((ledger, index) => {
      return [
        { text: moment(ledger?.created).format('DD-MM-YYYY'), style: ['smallText'] },
        { text: ledger.matterId, style: ['smallText'] },
        { text: `${ledger?.user?.firstName} ${ledger?.user?.lastName}`, style: ['smallText'] },
        { text: ledger.matterType, style: ['smallText'] },
        { text: `$${ledger.balance.toFixed(2)}`, style: ['smallText'] },
      ];
    });

    body = body.concat(data);
    return body;
  }
}
