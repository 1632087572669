import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { environment as env } from '../../environments/environment';

@Injectable({
 providedIn: 'root',
})
export class ApiService {
 base_url = env.base_url;

 constructor(private http: HttpClient) {}
 post(endpoint, req: any) {
  let param = new HttpParams();
  param = req;
  return this.http.post(this.base_url + endpoint, param).pipe(catchError(this.errorHandl));
 }

 generatePdf(endpoint, req: any) {
  const uniqueId = uuidv4();

  let param = new HttpParams();
  const headers = {
   'Content-Type': 'application/json',
   'x-access-key': uniqueId,
  };
  param = req;
  return this.http
   .post(env.pdfGeneratorUrl + endpoint, param, { headers, responseType: 'blob' })
   .pipe(catchError(this.errorHandl));
 }

 // fetch login user token for authenticate api
 getToken() {
  const token = localStorage.getItem('authtoken');
  const h = localStorage.getItem('h');
  const s = localStorage.getItem('s');
  if (token) {
   return `${h}.${token}.${s}`;
  }
  return null;
 }

 get(endpoint) {
  return this.http.get(this.base_url + endpoint).pipe(catchError(this.errorHandl));
 }

 delete(endpoint) {
  return this.http.delete(this.base_url + endpoint).pipe(catchError(this.errorHandl));
 }

 deleteWithParams(endpoint, params: any) {
  return this.http.delete(this.base_url + endpoint, { params }).pipe(catchError(this.errorHandl));
 }

 put(endpoint, req: any) {
  let param = new HttpParams();
  param = req;
  return this.http.put(this.base_url + endpoint, param).pipe(catchError(this.errorHandl));
 }

 fetch(endpoint, req: any) {
  let param = new HttpParams();
  param = req;
  return this.http.put(this.base_url + endpoint, param).pipe(catchError(this.errorHandl));
 }

 // Error handling
 errorHandl(error) {
  let errorMessage = '';
  if (error.error instanceof ErrorEvent) {
   // Get client-side error
   errorMessage = error.error.message;
  } else {
   // Get server-side error
   errorMessage = error;
   if (error.status === 401) {
    // localStorage.clear();
   }
  }
  return throwError(errorMessage);
 }

 getParams(endpoint, params: any) {
  return this.http.get(this.base_url + endpoint, { params }).pipe(catchError(this.errorHandl));
 }
}
