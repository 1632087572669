export const MATTER_TYPE = [{ name: 'Criminal' }, { name: 'Family' }, { name: 'Immigration' }, { name: 'Real Estate' }];

export const IMMTYPE = [{ name: 'Sponsorship' }, { name: 'Permanent Resident Application' }, { name: 'Refugee Claim' }];

export const JURISDICTION = [
  {
    name: 'Montreal',
    address: `Refugee Protection Division\\nGuy- Favreau​ Complex\\n200 René - Lévesque Blvd.West,\\nEast Tower, Room 102\\nMontréal, Quebec H2Z 1X4`,
  },
  {
    name: 'Toronto',
    address: `Refugee Protection Division\\n25 St. Clair Ave E, Suite 200\\nToronto, ON\\nM4T 0A8`,
  },
  {
    name: 'Vancouver',
    address: `Refugee Protection Division\\n300 West Georgia Street, Suite 1600\\nLibrary Square\\nVancouver, B.C. V6B 6C9`,
  },
];

export const FAMTYPE = [
  {
    partyType: [{ name: 'Applicant' }, { name: 'Respondent' }],
  },
  {
    courtLevel: [{ name: 'Ontario Court of Justice' }, { name: 'Superior Court of Justice' }],
  },
];
