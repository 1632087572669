import { CurrencyPipe } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import * as METHODS from '../../../enum/accounting';
import { ApiService } from '../../../services';
import { PaymentMethods } from '../../config/payment-methods';

@Component({
 selector: 'app-add-payment',
 templateUrl: './add-payment.component.html',
 styleUrls: ['./add-payment.component.scss'],
})
export class AddPaymentComponent implements OnInit, OnChanges, OnDestroy {
 @ViewChild('paymentModal') public paymentModal: ModalDirective;
 @ViewChild('transferModal') public transferModal: ModalDirective;

 @Output() confirm = new EventEmitter();
 @Output() hidePaymentModal = new EventEmitter();
 @Input() selectedInvoice: any;
 @Input() matterDetails: any;
 @Input() type: string;
 @Input() totalBalance: number;
 @Input() addDisbursement = false;
 @Input() matterSpecific;

 addPaymentFormTrust: FormGroup;
 addPaymentForm: FormGroup;
 receipts: any;
 pageNo = 1;
 pageSize = 10;
 toData: any;
 fromData: any;
 maxDate = new Date();

 caseList: any;

 isPaymentSubmitted = false;
 methods = [];
 labels = {
  Cheque: 'Cheque No',
  Cash: 'Cash Memo No',
  'Bank Transfer': 'Transaction ID',
  'Electronic Money Transfer': 'Transaction ID',
  'Credit card': 'Transaction ID',
  'Deduct from Trust': 'Transaction ID',
 };
 trustInfo: any;
 errMessage: string;
 hasTrustBalance: boolean;
 selectedPaymentMethod: string;
 selectedClient: string;
 userName: string;
 matterId: string;
 destroy$ = new Subject<boolean>();

 constructor(
  private apiService: ApiService,
  private toastr: ToastrService,
  private fb: FormBuilder,
  private cp: CurrencyPipe,
 ) {}

 ngOnInit(): void {
  this.initializeForm();
  this.getCaseList();
  if (this.type === 'invoice') {
   this.methods = PaymentMethods;

   this.getTrustBalance();
  } else if (this.type === 'trust') {
   this.methods = PaymentMethods.filter(me => me?.toLowerCase() !== METHODS.PaymentMethods.DEDUCT_FROM_TRUST);
   this.methods.push('Inter Trust Transfer');
   this.initializeTrustForm();
  } else if (this.type === 'refund') {
   this.methods = PaymentMethods.filter(me => me?.toLowerCase() !== METHODS.PaymentMethods.DEDUCT_FROM_TRUST);
   this.methods.push('Add to Trust');

   this.getTrustBalance();
  } else {
   this.methods = PaymentMethods.filter(me => me?.toLowerCase() !== METHODS.PaymentMethods.DEDUCT_FROM_TRUST);
   this.methods.push('Add to Trust');
   this.initializeForm();
  }
  this.matterId = this.matterDetails._id;
  this.addPaymentFormTrust.get('clientEmail').setValue(this.matterDetails?.user?.email);
  this.addPaymentFormTrust.get('clientEmail').disable();
  this.userName = `${this.matterDetails?.user?.firstName} ${this.matterDetails?.user?.lastName}`;
  this.addPaymentFormTrust.get('clientName').setValue(this.userName);
  this.addPaymentFormTrust.get('clientName').disable();
 }

 getTrustBalance() {
  this.apiService
   .get(`${Endpoints.getTrustBalance}${this.matterDetails._id}`)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    (trust: any) => {
     this.trustInfo = trust;
     const trustBal = +this.trustInfo?.trustBalance?.$numberDecimal;
     this.addPaymentFormTrust.get('trustBalance').setValue(trustBal?.toFixed(2));
     this.addPaymentFormTrust.get('trustBalance').disable();
     if (this.addDisbursement) {
      this.addPaymentFormTrust.get('amount').setValidators(Validators.max(+trustBal?.toFixed(2)));
      this.addPaymentFormTrust.get('amount').setValue(trustBal?.toFixed(2));
      this.hasTrustBalance = false;
      this.addPaymentFormTrust.updateValueAndValidity();
     }
    },
    err => {
     this.hasTrustBalance = true;
     this.addPaymentFormTrust.get('trustBalance').setValue(0);
     this.addPaymentFormTrust.get('trustBalance').disable();
    },
   );
 }

 initializeForm() {
  this.addPaymentFormTrust = this.fb.group({
   paymentMethod: ['', Validators.required],
   refId: ['', Validators.required],
   date: [new Date()],
   lastFourDigits: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{4}$')],
   amount: [null, [Validators.required, Validators.min(1)]],
   comment: [''],
   trustBalance: [0],
   clientEmail: ['', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]],
   clientName: ['', [Validators.required, this.trimValidator(), Validators.pattern('[a-zA-Z ]*')]],
   clientType: ['existClient', Validators.required],
  });

  this.addPaymentForm = this.fb.group({
   paymentMethod: [''],
   refId: [''],
   lastFourDigits: [''],
   date: [new Date()],
   amount: ['', Validators.required],
   purposeOfFunds: [''],
   creditType: [''],
   from: ['', Validators.required],
   trustBalance: [''],
   to: [this.matterSpecific ? this.matterDetails?._id : '', Validators.required],
  });

  this.getList();

  if (this.selectedInvoice?.balance?.$numberDecimal !== '') {
   this.addPaymentFormTrust.get('amount').setValue(this.selectedInvoice?.balance?.$numberDecimal);
  }

  if (this.type === 'refund') {
   let remainingAmount;
   const invoiceAmount = +this.selectedInvoice?.total?.$numberDecimal;
   if (this.selectedInvoice?.refundAmount && this.selectedInvoice?.refundAmount !== '') {
    if (invoiceAmount > this.selectedInvoice?.refundAmount) {
     remainingAmount = +invoiceAmount - +this.selectedInvoice?.refundAmount;
    } else {
     remainingAmount = +this.selectedInvoice?.refundAmount - +invoiceAmount;
    }
   } else {
    remainingAmount = invoiceAmount;
   }

   this.addPaymentFormTrust.get('refId').clearValidators();
   this.addPaymentFormTrust.get('amount').setValidators(Validators.max(remainingAmount));
   this.addPaymentFormTrust.get('amount').setValue(remainingAmount);
   this.addPaymentFormTrust.updateValueAndValidity();
  }

  if (this.type === 'addRefund') {
   this.addPaymentFormTrust.get('refId').clearValidators();
  }

  if (this.addDisbursement) {
   this.addPaymentFormTrust.get('paymentMethod').setValue('Deduct from Trust');
   this.addPaymentFormTrust.get('paymentMethod').disable();
  }
 }

 trimValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
   const value = control.value;

   if (!value) {
    return null;
   }

   if (value.startsWith(' ')) {
    return {
     trimError: { value: 'Name must not contain leading whitespaces' },
    };
   }

   if (value.endsWith(' ')) {
    return {
     trimError: { value: 'Name must not contain traling whitespaces' },
    };
   }
   return null;
  };
 }

 /**
  * @param client
  * @description Function to get selected client
  * @returns {void}
  */
 selectedClientField(client: string): void {
  if (client === 'thirdPartyClient') {
   this.selectedClient = client;
   this.addPaymentFormTrust.get('clientEmail').enable();
   this.addPaymentFormTrust.get('clientName').enable();
   this.addPaymentFormTrust.get('clientEmail').setValue('');
   this.addPaymentFormTrust.get('clientName').setValue('');
  } else {
   this.selectedClient = client;
   this.addPaymentFormTrust.get('clientEmail').disable();
   this.addPaymentFormTrust.get('clientEmail').setValue(this.matterDetails.user.email);
   this.addPaymentFormTrust.get('clientName').disable();
   this.addPaymentFormTrust.get('clientName').setValue(this.userName);
  }
 }

 initializeTrustForm() {
  this.addPaymentFormTrust = this.fb.group({
   paymentMethod: ['', Validators.required],
   refId: ['', Validators.required],
   lastFourDigits: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{4}$')],
   amount: ['', [Validators.required, Validators.min(1)]],
   purposeOfFunds: [''],
   date: [new Date()],
   creditType: ['trust receipts', Validators.required],
  });
 }

 get getPaymentControlsTrust() {
  return this.addPaymentFormTrust.controls;
 }

 setToId(ev: any) {
  this.toData = this.caseList.find(c => c._id === ev.target.value);
 }

 cancelDocketModal() {
  this.paymentModal.hide();
  this.confirm.emit({ isDone: false });
 }

 validateNumberTrust(ev: any, controlName: string) {
  if (ev.target.value < 0) {
   this.addPaymentFormTrust.get(controlName).setValue(0);
  }
  if (this.type === 'refund') {
   this.errMessage = `Refund amount should be less or equal to invoice amount`;
  } else if (this.addDisbursement) {
   this.errMessage = `Disbursement amount should be less or equal to trust balance`;
  }
 }

 enableControls() {
  this.addPaymentFormTrust?.get('trustBalance')?.enable();
  this.addPaymentFormTrust?.get('amount')?.enable();
 }

 savePaymentInfo() {
  this.addPaymentFormTrust?.get('paymentMethod')?.enable();

  const params: any = {
   amount: +this.addPaymentFormTrust.value.amount,
   lastFourDigits: this.addPaymentFormTrust.value.lastFourDigits,
   paymentMethod: this.addPaymentFormTrust.value.paymentMethod,
   date: this.addPaymentFormTrust.value.date,
   refId: this.addPaymentFormTrust.value.refId,
   invoiceId: this.selectedInvoice?.invoiceId,
   balance: this.selectedInvoice?.balance,
   comments: this.addPaymentFormTrust?.value?.comment,
   matterId: this.matterDetails._id,
   clientId: this.matterDetails.clientId,
   transferredBy: this.matterDetails.clientId,
  };

  if (this.type === 'trust') {
   if (this.addPaymentFormTrust.value?.purposeOfFunds.length === 0) {
    if (this.matterDetails?.matterType.toLowerCase() === 'immigration') {
     params.purposeOfFunds = `${this.matterDetails?.matterType} - ${this.matterDetails.matterSubType}`;
    } else {
     params.purposeOfFunds = `${this.matterDetails.matterType}`;
    }
   } else {
    params.purposeOfFunds = this.addPaymentFormTrust.value?.purposeOfFunds;
   }
   params.trustBalance = +this.addPaymentFormTrust.value.amount + this.totalBalance;
   params.creditType = this.addPaymentFormTrust.value.creditType;
  } else {
   params.trustBalance = +this.addPaymentFormTrust.value.trustBalance;
  }

  const api: any =
   this.type === 'invoice' && !this.addDisbursement
    ? this.apiService.put(Endpoints.addPayment, params)
    : this.apiService.put(Endpoints.trust, params);

  api.pipe(takeUntil(this.destroy$)).subscribe(
   (payment: any) => {
    this.toastr.success(payment.message);
    this.confirm.emit({ isDone: true });
   },
   err => {
    this.confirm.emit({ isDone: false });
   },
  );
 }

 addRefund() {
  const params: any = {
   amount: +this.addPaymentFormTrust.value.amount,
   lastFourDigits: this.addPaymentFormTrust.value.lastFourDigits,
   paymentMethod: this.addPaymentFormTrust.value.paymentMethod,
   invoiceId: this.selectedInvoice?._id,
   comments: this.addPaymentFormTrust?.value?.comment,
   matterId: this.matterDetails._id,
   clientId: this.matterDetails.clientId,
   refId: this.addPaymentFormTrust.value.refId,
   transferredBy: this.matterDetails.clientId,
   invoiceAmount: this.selectedInvoice?.total?.$numberDecimal,
  };

  const apiEndPoints: any =
   this.type === 'refund'
    ? this.apiService.put(Endpoints.refundAmount, params)
    : this.apiService.post(Endpoints.refundAmount, params);

  apiEndPoints.pipe(takeUntil(this.destroy$)).subscribe(
   (res: any) => {
    this.toastr.success(res.message);
    this.confirm.emit({ isDone: true });
   },
   err => {
    this.confirm.emit({ isDone: false });
   },
  );
 }

 async addPayment() {
  this.enableControls();
  if (
   this.addPaymentFormTrust?.get('trustBalance')?.value === 0 &&
   this.addPaymentFormTrust.value.paymentMethod.toLowerCase() === 'deduct from trust'
  ) {
   this.toastr.warning('You cannot use this payment method as your trust balance is insufficient', 'Warning');

   return;
  }

  if (this.type !== 'refund' && this.type !== 'addRefund') {
   if (this.selectedPaymentMethod === 'Cash') {
    await this.submitCashPaymentForm();
   }
   this.savePaymentInfo();
  } else {
   this.addRefund();
  }
 }

 /**
  * @description Function submits the cash payment details
  * @returns {void}
  */
 submitCashPaymentForm(): Promise<void> {
  return new Promise((resolve, reject) => {
   const amount = this.cp.transform(this.addPaymentFormTrust.get('amount').value, 'USD', 'symbol', '1.2-2');
   const cashPaymentReceiptData = {
    clientAmount: amount,
    clientEmail: this.addPaymentFormTrust.get('clientEmail').value,
    clientName: this.addPaymentFormTrust.get('clientName').value,
    clientType: this.addPaymentFormTrust.get('clientType').value,
   };

   const data = {
    paymentReceiptData: cashPaymentReceiptData,
    redirectUrl: `${window.location.origin}/#/cash-payment-receipt`,
    matterId: this.matterDetails._id,
    lawyerId: this.matterDetails.lawyers[0]._id,
    courtFileNo: this.matterDetails.matterId,
    client: `${this.matterDetails.user.firstName.toUpperCase()} ${this.matterDetails.user.lastName.toUpperCase()} `,
    existClientEmail: this.matterDetails.user.email,
   };

   if (data) {
    this.apiService
     .put(Endpoints.paymentReceipt, data)
     .pipe(takeUntil(this.destroy$))
     .subscribe(
      (response: any) => {
       if (response) {
        this.toastr.success(response.message, 'Success');
       }
       resolve();
      },
      error => {
       reject();
       console.log('TCL ->  ~ PaymentsComponent ~ this.apiService.post ~ error:', error);
      },
     );
   }
  });
 }

 updatePaymentMethod(ev: any) {
  this.selectedPaymentMethod = ev.target.value;
  const amountControl = this.addPaymentFormTrust.get('amount');

  if (ev.target.value.toLowerCase() === 'inter trust transfer') {
   this.paymentModal.hide();
   this.transferModal.show();
   if (this.matterSpecific) {
    this.addPaymentForm.get('to').disable();
   }
  }

  if (this.type !== 'refund') {
   if (ev.target.value.toLowerCase() === METHODS.PaymentMethods.DEDUCT_FROM_TRUST) {
    if (
     this.selectedInvoice?.balance?.$numberDecimal > +this.addPaymentFormTrust.get('trustBalance').value &&
     +this.addPaymentFormTrust.get('trustBalance').value > 0
    ) {
     this.errMessage = 'Amount should be less than or equal to trust balance';
     const trustBal = +this.addPaymentFormTrust.get('trustBalance').value;
     amountControl.setValidators([Validators.max(trustBal), Validators.required]);
     amountControl.setValue(this.addPaymentFormTrust.get('trustBalance').value);
    } else {
     this.errMessage = `Amount should be less than or equal to remaining balance (${'$'}${
      this.selectedInvoice?.balance?.$numberDecimal
     })`;
     this.addPaymentFormTrust
      .get('amount')
      .setValidators([Validators.max(this.selectedInvoice?.balance?.$numberDecimal), Validators.required]);
     amountControl.setValue(this.selectedInvoice?.balance?.$numberDecimal);
     if (this.addPaymentFormTrust.get('trustBalance').value === 0) {
      amountControl.disable();
      this.addPaymentFormTrust.get('refId').disable();
      this.addPaymentFormTrust.get('comment').disable();
     }
    }
   } else {
    this.addPaymentFormTrust.clearValidators();
    amountControl.setValidators(Validators.required);
    this.addPaymentFormTrust.updateValueAndValidity();
    this.addPaymentFormTrust?.get('amount')?.setValue(this.selectedInvoice?.balance?.$numberDecimal);
    this.addPaymentFormTrust?.get('amount')?.enable();
    this.addPaymentFormTrust?.get('refId')?.enable();
    this.addPaymentFormTrust?.get('comment')?.enable();
    if (this.selectedPaymentMethod === 'Cash') {
     amountControl.setValidators([Validators.required, Validators.min(0), Validators.max(7500)]);
     amountControl.updateValueAndValidity();
    }
   }
  }
 }

 getBalance() {
  const balance = +this.trustInfo?.trustBalance?.$numberDecimal || 0.0;
  return balance.toFixed(2);
 }

 getList() {
  this.apiService
   .get(Endpoints.interTrustTransfer)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    (data: any) => {
     this.receipts = data;
    },
    err => {},
   );
 }

 validateNumber(ev: any, controlName: string) {
  if (ev.target.value < 0) {
   this.addPaymentForm.get(controlName).setValue(0);
  }

  this.errMessage =
   this.selectedPaymentMethod === 'Cash' && this.type === 'invoice'
    ? 'Amount must be less than or equal to $7500'
    : `Transfer amount should be less or equal to trust balance`;
 }

 getTrustBalanceTrust(matterId: string) {
  this.apiService
   .get(`${Endpoints.getTrustBalance}${matterId}`)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    (trust: any) => {
     this.trustInfo = trust;
     const trustBal = +this.trustInfo?.trustBalance?.$numberDecimal;
     this.addPaymentForm.get('trustBalance').setValue(trustBal?.toFixed(2));
     this.addPaymentForm.get('trustBalance').disable();
     this.addPaymentForm.get('amount').setValidators(Validators.max(+trustBal?.toFixed(2)));
     this.addPaymentForm.get('amount').enable();
     this.addPaymentForm.get('purposeOfFunds').enable();
     this.addPaymentForm.get('amount').setValue(trustBal?.toFixed(2));
     this.addPaymentForm.updateValueAndValidity();
     this.hasTrustBalance = false;
    },
    err => {
     this.addPaymentForm.get('trustBalance').setValue(0);
     this.addPaymentForm.get('trustBalance').disable();
     this.addPaymentForm.get('amount').disable();
     this.addPaymentForm.get('purposeOfFunds').disable();
     this.hasTrustBalance = true;
     this.toastr.warning('Insufficient trust balance for the selected case.', 'Cannot proceed');
    },
   );
 }

 addPaymentTrust() {
  this.addPaymentForm.get('trustBalance').enable();
  const balance = +this.addPaymentForm.get('trustBalance').value;
  if (balance === 0) {
   this.toastr.warning('Insufficient trust balance for the selected case.', 'Cannot proceed');

   return;
  }

  const params: any = {
   amount: +this.addPaymentForm.value.amount,
   creditType: 'inter trust transfer',
   from: this.addPaymentForm.value.from,
   fromId: this.fromData.clientId,
   date: this.addPaymentForm.value.date,
   lastFourDigits: this.addPaymentForm.value.lastFourDigits,
   paymentMethod: this.addPaymentForm.value.paymentMethod,
   purposeOfFunds: this.addPaymentForm.value.purposeOfFunds,
   refId: this.addPaymentForm.value.refId,
   to: this.matterSpecific ? this.matterDetails?._id : this.addPaymentForm.value.to,
   toId: this.matterSpecific ? this.matterDetails?.clientId : this.toData.clientId,
   trustBalance: this.addPaymentForm.value.trustBalance,
   trustId: this.trustInfo._id,
   toMatterId: this.matterSpecific ? this.matterDetails?._id : this.addPaymentForm.value.to,
  };

  this.apiService
   .put(Endpoints.interTrustTransfer, params)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    (data: any) => {
     this.transferModal.hide();

     this.toastr.success(data.message);
     this.hidePaymentModal.emit({ modal: false });
     this.getList();
    },
    err => {
     console.log(
      'TCL ->  ~ file: inter-trust-transfer-record.component.ts ~ line 138 ~ InterTrustTransferRecordComponent ~ this.apiService.put ~ err',
      err,
     );
     this.toastr.error(err.error);
    },
   );
 }

 get getPaymentControls() {
  return this.addPaymentForm.controls;
 }

 cancel() {
  this.addPaymentForm.reset();
  this.transferModal.hide();
  this.hidePaymentModal.emit({ modal: false });
 }

 checkTrustBalance(ev: any) {
  this.fromData = this.caseList.find(c => c._id === ev.target.value);
  if (this.matterDetails?._id === ev.target.value) {
   this.toastr.error('The Sender cannot be the Receiver');
   this.addPaymentForm.get('from').setValue('');
  } else {
   this.getTrustBalanceTrust(ev.target.value);
  }
 }

 getCaseList() {
  this.apiService
   .get(Endpoints.getCaseFiles)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    (cases: any) => {
     this.caseList = cases;
    },
    err => {},
   );
  this.initializeForm();
 }

 ngOnChanges() {
  setTimeout(() => {
   this.paymentModal.show();
  }, 100);
 }

 ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.complete();
 }
}
