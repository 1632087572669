import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Capacitor } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { Endpoints } from 'app/config';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from './api.service';
import { FormsService } from './forms.service';

@Injectable({
  providedIn: 'root',
})
export class FamilyFormControlService {
  constructor(
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private api: ApiService,
    private formService: FormsService,
  ) {}

  /**
   *
   * @param field form field
   * @param formGroup Form group
   * @description Method is used to set the form control value
   */
  setControlValue(field: any, formGroup: FormGroup) {
    if (field?.controlName) {
      formGroup.get(field.controlName).setValue(field.answer);
    }
  }

  /**
   *
   * @param control
   * @param mainGroup
   * @description Method is used to set the form array value.
   */

  setFormArrayValue(control: any, mainGroup: FormGroup) {
    this.getControls(control.formArrayName, mainGroup)['controls'].forEach((formControl: any) => {
      control.fields.forEach(cntrl => {
        cntrl.controls.forEach(subChild => {
          subChild.child.forEach(ans => {
            if (ans?.controlName) {
              formControl.controls[ans.controlName].setValue(ans.answer);
            }
          });
        });
      });
    });
  }

  /**
   *
   * @param controls
   * @param formGroup
   * @returns Form Array
   * @description Method is used to get the form array
   */
  getControls(controls: string, formGroup: FormGroup): FormArray {
    return formGroup.get(controls) as FormArray;
  }

  /**
   *
   * @param form
   * @param mainFormGroup
   */
  setGeneralInfo(form: any, mainFormGroup: FormGroup) {
    form?.generalInfo.forEach(data => {
      if (data.childControls) {
        data.childControls.forEach(control => {
          mainFormGroup.addControl(`${control.controlName}`, new FormControl(''));
          mainFormGroup.get(control.controlName).disable();
        });
      } else {
        mainFormGroup.addControl(`${data.controlName}`, new FormControl(''));
        if (data && data.answer !== '') {
          if (data?.fieldType === 'date') {
            data.answer = moment(data.answer).format('YYYY-MM-DD');
          }
          this.setControlValue(data, mainFormGroup);
        }
      }
    });
  }

  setChildControlInfo(form: any, mainFormGroup: FormGroup) {
    form?.forEach(data => {
      if (data?.childControls) {
        data.childControls.forEach(control => {
          mainFormGroup.addControl(`${control.controlName}`, new FormControl(''));
          mainFormGroup.get(control.controlName).disable();
        });
      }
      mainFormGroup.addControl(`${data.controlName}`, new FormControl(''));
      if (data && data.answer !== '') {
        this.setControlValue(data, mainFormGroup);
      }
    });
  }

  addFormArrayControl(form: any, mainFormGroup: FormGroup, controlArr: any) {
    const result = controlArr?.reduce((obj, cur) => ({ ...obj, [cur]: [null] }), {});

    mainFormGroup.addControl(`${form?.formArrayName}`, this.formBuilder.array([this.formBuilder.group(result)]));

    this.setFormArrayValue(form, mainFormGroup);
  }

  addControl(form: any, mainFormGroup: FormGroup): any {
    let arrControls = [];
    if (form?.fields) {
      form?.fields.forEach(data => {
        data.controls.forEach(child => {
          const controls = child.child.map(arr => arr.controlName);
          arrControls.push(...controls);
        });
      });

      if (arrControls.length > 0) {
        return arrControls;
      }
    }

    // this.addFormArrayControl(form, mainFormGroup, arrControls);
  }

  addFormArrayValue(field: any, mainForm: FormGroup) {
    this.getControls(field.formGroup, mainForm).push(this.addChildrens(field));
  }

  addChildrens(field: any) {
    let arrControls;
    if (field?.controls?.length > 0 && field?.controls[0]?.child) {
      arrControls = field?.controls[0]?.child.map(arr => arr.controlName);
    } else {
      arrControls = field.controls.map(arr => arr.controlName);
    }
    const result = arrControls.reduce((obj, cur) => ({ ...obj, [cur]: [null] }), {});
    return this.formBuilder.group(result);
  }

  checkAnswer(form: FormGroup) {
    if (form) {
      let isAnsExist;
      for (const [key, value] of Object.entries(form?.value)) {
        if (Array.isArray(value)) {
          const data = value.filter(contrls => {
            let result = !Object.values(contrls).every(o => o === '' || o === null || o === undefined);
            return result;
          });
          data.length > 0 ? (isAnsExist = true) : (isAnsExist = false);
          if (data.length > 0) break;
        } else {
          isAnsExist = value !== undefined && value !== '' && value !== null ? true : false;
          if (isAnsExist) break;
        }
      }
      return isAnsExist;
    }
  }

  async downloadPDFForNative(res, matterDetail, matterPartyType, previousInstanceData, fileName, formName) {
    try {
      const permission = await Filesystem.checkPermissions();
      if (permission.publicStorage !== 'granted') {
        return this.toastr.error('Please allow storage permission to download the file');
      }

      if (matterDetail?._id) {
        let params;

        params = {
          formId: res.data?._id,
          site: `${window.location.origin}/#/family-forms/${res.data?.matterId}/${fileName}`,
        };

        this.api.generatePdf(Endpoints.generateForm, params).subscribe(
          async (res: any) => {
            const personName = `${matterDetail?.user?.firstName ? matterDetail?.user?.firstName.trim() + ' ' : ''}${
              matterDetail?.user?.lastName ? matterDetail?.user?.lastName.trim() : ''
            }`;
            const fileName = this.formService.getFileName(
              formName,
              matterPartyType || previousInstanceData?.matterPartyType,
              personName,
            );
            const data = await this.getBase64Data(res);
            const response = await Filesystem.writeFile({
              path: fileName,
              data: data,
              directory: Directory.Documents,
              recursive: true,
            });
            const path = response.uri.split('/').splice(-2);
            if (Capacitor.getPlatform() === 'ios') {
              this.toastr.success(`File downloaded successfully at "Alexur/${fileName.trim()}"`);
            } else {
              this.toastr.success(`File downloaded successfully at "${decodeURIComponent(path.join('/'))}"`);
            }
            this.api.delete(`${Endpoints.deleteFormInstance}/${params.formId}`).toPromise();
          },
          err => {
            console.log('** ->  ~ file: factum.component.ts:714 ~ pageNumber ~ error:', err);
          },
        );
      }
    } catch (error) {
      this.toastr.error('Something went wrong');
      console.log('** ->  ~ file: forms.component.ts:884 ~ downloadPDF ~ error:', error);
    }
  }

  async getBase64Data(file: File): Promise<string> {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = error => reject(error);
    });
  }
}
