export enum LOCAL_STORAGE {
  MATTER_NAV_DETAILS = 'matterNavDetails',
  MATTER_ID = 'matterId',
  MATTER_TYPE = 'matterType',
  MATTER_DATA = 'matterData',
  MATTER_SUB_TYPE = 'matterSubType',
  MATTER_DETAILS = 'matterDetails',
  USER_DETAILS = 'userDetails',
  UNIT_TIME = 'unitTime',
  LAW_FIRM_NAME = 'lawFirm',
  TAX_RATE = 'taxRate',
  PLAYER_ID = 'playerId',
}
