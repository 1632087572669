import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import * as MESSAGE from '../../../enum/info-messages.enum';
import { LOCAL_STORAGE } from '../../../enum/local-storage.enum';
import { ApiService } from '../../../services';

@Component({
  selector: 'app-alert',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css'],
})
export class AlertsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() cancelButton: string;
  @Input() confirmButton: string;
  @Input() title: string;
  @Input() message: string;
  @Input() alertMessage: string;
  @Input() toggleState: boolean;
  @Input() type: string;
  @Input() claimDate: any;
  @Input() formData: any;
  @Input() docketInfo: any;
  @Input() format = false;

  @Output() confirm = new EventEmitter();
  @ViewChild('smallModal', { static: true }) public smallModal: ModalDirective;
  @ViewChild('reminderModal', { static: true }) public reminderModal: ModalDirective;

  DeleteOption: any = false;
  matterDetails: any;
  subscription: any = [];
  endpoints = Endpoints;
  globalTask: FormGroup;
  isFormValid = false;
  addInvitees = [];
  pattern = '^[0-9]*$';
  preDefinedItems = [
    { id: 1, name: 'Tomorrow' },
    { id: 7, name: 'Next week' },
    { id: 30, name: 'Next month' },
  ];
  destroy$ = new Subject<boolean>();

  constructor(private fb: FormBuilder, private toastr: ToastrService, private api: ApiService) {}

  ngOnInit(): void {
    if (this.type === 'maxDate') {
      this.confirm.emit({ state: true, type: this.type });
      setTimeout(() => {
        this.smallModal.hide();
      }, 200);
    } else {
      this.getUserMatterDetails();
      this.docketInfo?.taskLawyers.forEach(lawyer => {
        this.addInvitees.push(lawyer.email);
      });
    }
  }

  initializeForm() {
    const title = `Reminder for - ${this.matterDetails?.user.lastName.toUpperCase()}, ${
      this.matterDetails?.user?.firstName?.charAt(0)?.toUpperCase() + this.matterDetails.user?.firstName?.slice(1)
    } - ${this.matterDetails?.matterId}`;

    this.globalTask = this.fb.group({
      title: [title, [Validators.required]],
      time: [''],
      email: [this.addInvitees],
      eventDate: [this.claimDate, [Validators.required]],
      eventStart: ['', [Validators.required]],
      eventEnd: ['', [Validators.required]],
      eventDescription: [''],
    });
  }

  ngOnChanges(): void {
    setTimeout(() => {
      this.smallModal.show();
    }, 100);
  }

  getUserMatterDetails() {
    try {
      this.matterDetails = JSON.parse(localStorage.getItem('matterDetails'));
      if (this.matterDetails) {
        this.initializeForm();
      }
    } catch (err) {
      console.error(err);
    }
  }

  onSelectConfirm(state: boolean, isUpdate?: boolean) {
    this.smallModal.hide();
    this.confirm.emit({ state: state, type: this.type, isUpdate });
  }

  /**
   *
   * @param item
   * @returns Added tags
   */
  addItems = item => ({ id: item, name: item });

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  closeModal() {
    this.smallModal.hide();
    this.confirm.emit({ state: false, type: this.type, isUpdate: false });
  }

  get getControls() {
    return this.globalTask.controls;
  }

  onRemove(ev: any) {
    if (ev && ev.value && ev.value !== '') {
      this.addInvitees = this.addInvitees.filter(email => email !== ev.value);
    } else {
      this.addInvitees = this.addInvitees.filter(email => email !== ev);
    }
  }

  submitEvent() {
    this.isFormValid = true;

    if (this.globalTask.invalid) {
      return;
    }
    const newDate = this.globalTask.value.eventDate;

    const eventDate = moment(newDate).format('YYYY-MM-DD');
    const eventStart = this.globalTask.value.eventStart;
    const eventStartDateTime = new Date(`${eventDate}T${eventStart}`);
    const eventEnd = this.globalTask.value.eventEnd;
    const eventEndDateTime = new Date(`${eventDate}T${eventEnd}`);

    const lawfirmName = localStorage.getItem(LOCAL_STORAGE.LAW_FIRM_NAME);

    const params = {
      eventId: '',
      eventTitle: this.globalTask.value.title,
      eventDescription: this.globalTask.value.eventDescription,
      eventDate: eventDate,
      eventStart: this.globalTask.value.eventStart,
      eventEnd: this.globalTask.value.eventEnd,
      eventColor: this.globalTask.value.eventColor,
      invitees: this.addInvitees.length > 0 ? this.addInvitees : [],
      timezoneOffset: new Date().getTimezoneOffset(),
      lawFirm: lawfirmName,
      removedInvitees: [],
      eventStartDateTime: eventStartDateTime.toISOString(),
      eventEndDateTime: eventEndDateTime.toISOString(),
    };

    const apiEndPoint = this.api.post(`${Endpoints.addEvent}`, params);
    apiEndPoint.pipe(takeUntil(this.destroy$)).subscribe(
      data => {
        this.toastr.success(MESSAGE.TOASTR.MESSAGE_UPDATEEVENT);
        this.confirm.emit({ isAdd: true });
        this.isFormValid = false;
      },
      err => {
        let errMessage = err.error || MESSAGE.RESPONSE.ERROR;
        this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        this.isFormValid = false;
      },
    );
  }

  onEmailInput(ev: any) {
    if (ev && ev.value !== '') {
      this.addInvitees.push(ev.value);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
