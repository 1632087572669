export const validationMessage = {
  addressApt: [{ type: 'required', message: 'Field required' }],
  addressCity: [{ type: 'required', message: 'Field required' }],
  addressInLastTwoYear: [{ type: 'required', message: 'Field required' }],
  addressNo: [{ type: 'required', message: 'Field required' }],
  addressState: [{ type: 'required', message: 'Field required' }],
  addressStreet: [{ type: 'required', message: 'Field required' }],
  addressZip: [{ type: 'required', message: 'Field required' }],
  allSurname: [{ type: 'required', message: 'Field required' }],
  anotherCountryTravelDateLeft: [{ type: 'required', message: 'Field required' }],
  anotherCountryTravelDateLeftTwo: [{ type: 'required', message: 'Field required' }],
  anotherCountryTravelDateReturn: [{ type: 'required', message: 'Field required' }],
  anotherCountryTravelDateReturnTwo: [{ type: 'required', message: 'Field required' }],
  birthCity: [{ type: 'required', message: 'Field required' }],
  birthCountry: [{ type: 'required', message: 'Field required' }],
  birthDate: [{ type: 'required', message: 'Field required' }],
  birthState: [{ type: 'required', message: 'Field required' }],
  businessAddrApt: [{ type: 'required', message: 'Field required' }],
  businessAddrCity: [{ type: 'required', message: 'Field required' }],
  businessAddrNo: [{ type: 'required', message: 'Field required' }],
  businessAddrState: [{ type: 'required', message: 'Field required' }],
  businessAddrStreet: [{ type: 'required', message: 'Field required' }],
  businessAddrZip: [{ type: 'required', message: 'Field required' }],
  cardNumber: [{ type: 'required', message: 'Field required' }],
  cardSignDate: [{ type: 'required', message: 'Field required' }],
  cardType: [{ type: 'required', message: 'Field required' }],
  dateOfExp: [{ type: 'required', message: 'Field required' }],
  dateOfIssue: [{ type: 'required', message: 'Field required' }],
  declareGivenName: [{ type: 'required', message: 'Field required' }],
  declareSurname: [{ type: 'required', message: 'Field required' }],
  docNumber: [{ type: 'required', message: 'Field required' }],
  documentNo: [{ type: 'required', message: 'Field required' }],
  documentNoTwo: [{ type: 'required', message: 'Field required' }],
  documentType: [{ type: 'required', message: 'Field required' }],
  documentTypeTwo: [{ type: 'required', message: 'Field required' }],
  email: [
    { type: 'required', message: 'Field required' },
    { type: 'pattern', message: 'Enter valid email.' },
  ],
  emegencyContactRelationShipToApplicant: [{ type: 'required', message: 'Field required' }],
  emergencyApt: [{ type: 'required', message: 'Field required' }],
  emergencyCity: [{ type: 'required', message: 'Field required' }],
  emergencyContactGivenName: [{ type: 'required', message: 'Field required' }],
  emergencyContactSurname: [{ type: 'required', message: 'Field required' }],
  emergencyEmail: [
    { type: 'required', message: 'Field required' },
    { type: 'pattern', message: 'Enter valid email.' },
  ],
  emergencyNo: [{ type: 'required', message: 'Field required' }],
  emergencySignDate: [{ type: 'required', message: 'Field required' }],
  emergencyState: [{ type: 'required', message: 'Field required' }],
  emergencyStreet: [{ type: 'required', message: 'Field required' }],
  emergencyTelePhone: [{ type: 'required', message: 'Field required' }],
  emergencyTelePhoneOther: [{ type: 'required', message: 'Field required' }],
  emergencyZip: [{ type: 'required', message: 'Field required' }],
  eyeColour: [{ type: 'required', message: 'Field required' }],
  fromDate: [{ type: 'required', message: 'Field required' }],
  fromDateTwo: [{ type: 'required', message: 'Field required' }],
  givenName: [{ type: 'required', message: 'Field required' }],
  hasCertificate: [{ type: 'required', message: 'Field required' }],
  height: [{ type: 'required', message: 'Field required' }],
  idProof: [{ type: 'required', message: 'Field required' }],
  identySignDate: [{ type: 'required', message: 'Field required' }],
  indicateCountry: [{ type: 'required', message: 'Field required' }],
  isCitizenship: [{ type: 'required', message: 'Field required' }],
  knownApplicant: [{ type: 'required', message: 'Field required' }],
  lastAddressApt: [{ type: 'required', message: 'Field required' }],
  lastAddressAptTwo: [{ type: 'required', message: 'Field required' }],
  lastAddressCity: [{ type: 'required', message: 'Field required' }],
  lastAddressCityTwo: [{ type: 'required', message: 'Field required' }],
  lastAddressNumber: [{ type: 'required', message: 'Field required' }],
  lastAddressNumberTwo: [{ type: 'required', message: 'Field required' }],
  lastAddressState: [{ type: 'required', message: 'Field required' }],
  lastAddressStateTwo: [{ type: 'required', message: 'Field required' }],
  lastAddressStreet: [{ type: 'required', message: 'Field required' }],
  lastAddressStreetTwo: [{ type: 'required', message: 'Field required' }],
  lastAddressZip: [{ type: 'required', message: 'Field required' }],
  lastAddressZipTwo: [{ type: 'required', message: 'Field required' }],
  mailAddressApt: [{ type: 'required', message: 'Field required' }],
  mailAddressCity: [{ type: 'required', message: 'Field required' }],
  mailAddressNo: [{ type: 'required', message: 'Field required' }],
  mailAddressState: [{ type: 'required', message: 'Field required' }],
  mailAddressStreet: [{ type: 'required', message: 'Field required' }],
  mailAddressZip: [{ type: 'required', message: 'Field required' }],
  mothersSirName: [{ type: 'required', message: 'Field required' }],
  nameAppearOnCard: [{ type: 'required', message: 'Field required' }],
  nameOfApplicantCard: [{ type: 'required', message: 'Field required' }],
  nameOnDoc: [{ type: 'required', message: 'Field required' }],
  nameOnDocTwo: [{ type: 'required', message: 'Field required' }],
  occupation: [{ type: 'required', message: 'Field required' }],
  officialUse: [{ type: 'required', message: 'Field required' }],
  orgName: [{ type: 'required', message: 'Field required' }],
  orgPhoneOther: [{ type: 'required', message: 'Field required' }],
  orgTelePhone: [{ type: 'required', message: 'Field required' }],
  originalCitizenship: [{ type: 'required', message: 'Field required' }],
  otherCitizenship: [{ type: 'required', message: 'Field required' }],
  otherTravelDoc: [{ type: 'required', message: 'Field required' }],
  refAddApt: [{ type: 'required', message: 'Field required' }],
  refAddAptTwo: [{ type: 'required', message: 'Field required' }],
  refAddCity: [{ type: 'required', message: 'Field required' }],
  refAddCityTwo: [{ type: 'required', message: 'Field required' }],
  refAddNo: [{ type: 'required', message: 'Field required' }],
  refAddNoTwo: [{ type: 'required', message: 'Field required' }],
  refAddState: [{ type: 'required', message: 'Field required' }],
  refAddStateTwo: [{ type: 'required', message: 'Field required' }],
  refAddStreet: [{ type: 'required', message: 'Field required' }],
  refAddStreetTwo: [{ type: 'required', message: 'Field required' }],
  refAddZip: [{ type: 'required', message: 'Field required' }],
  refAddZipTwo: [{ type: 'required', message: 'Field required' }],
  refEmail: [
    { type: 'required', message: 'Field required' },
    { type: 'pattern', message: 'Enter valid email.' },
  ],
  refEmailTwo: [
    { type: 'required', message: 'Field required' },
    { type: 'pattern', message: 'Enter valid email.' },
  ],
  refGivenname: [{ type: 'required', message: 'Field required' }],
  refGivennameTwo: [{ type: 'required', message: 'Field required' }],
  refSurname: [{ type: 'required', message: 'Field required' }],
  refSurnameTwo: [{ type: 'required', message: 'Field required' }],
  refTelePhone: [{ type: 'required', message: 'Field required' }],
  refTelePhoneOther: [{ type: 'required', message: 'Field required' }],
  refTelePhoneOtherTwo: [{ type: 'required', message: 'Field required' }],
  refTelePhoneTwo: [{ type: 'required', message: 'Field required' }],
  refrelationShipToApplicant: [{ type: 'required', message: 'Field required' }],
  refrelationShipToApplicantTwo: [{ type: 'required', message: 'Field required' }],
  rerHasknownMe: [{ type: 'required', message: 'Field required' }],
  rerHasknownMeTwo: [{ type: 'required', message: 'Field required' }],
  sex: [{ type: 'required', message: 'Field required' }],
  signDate: [{ type: 'required', message: 'Field required' }],
  signedAtCity: [{ type: 'required', message: 'Field required' }],
  signedAtTer: [{ type: 'required', message: 'Field required' }],
  supportDocExpDate: [{ type: 'required', message: 'Field required' }],
  supportDocExpDateTWo: [{ type: 'required', message: 'Field required' }],
  surname: [{ type: 'required', message: 'Field required' }],
  telePhone: [{ type: 'required', message: 'Field required' }],
  telePhoneOther: [{ type: 'required', message: 'Field required' }],
  toDate: [{ type: 'required', message: 'Field required' }],
  toDateTwo: [{ type: 'required', message: 'Field required' }],
  travelDocDateIssue: [{ type: 'required', message: 'Field required' }],
  travelReason: [{ type: 'required', message: 'Field required' }],
  travelReasonTwo: [{ type: 'required', message: 'Field required' }],
  uci: [{ type: 'required', message: 'Field required' }],
  undefined: [{ type: 'required', message: 'Field required' }],
  visitedCountry: [{ type: 'required', message: 'Field required' }],
  visitedCountryTwo: [{ type: 'required', message: 'Field required' }],
  visitedOtherCountry: [{ type: 'required', message: 'Field required' }],
  cardExpiryDate: [{ type: 'required', message: 'Field required' }],
};

export const validationMsgForDeclartionForm = {
  fullName: [{ type: 'required', message: 'Field required' }],
  givenName: [{ type: 'required', message: 'Field required' }],
  familyNames: [{ type: 'required', message: 'Field required' }],
  familyGivenNames: [{ type: 'required', message: 'Field required' }],
  uci: [{ type: 'required', message: 'Field required' }],
  sex: [{ type: 'required', message: 'Field required' }],
  height: [{ type: 'required', message: 'Field required' }],
  eyeColour: [{ type: 'required', message: 'Field required' }],
  dob: [{ type: 'required', message: 'Field required' }],
  placeOfBirth: [{ type: 'required', message: 'Field required' }],
  birthCountry: [{ type: 'required', message: 'Field required' }],
  relationshipWithApplicant: [{ type: 'required', message: 'Field required' }],
  other: [{ type: 'required', message: 'Field required' }],
  accompanyApplicantToCanda: [{ type: 'required', message: 'Field required' }],
  reasonForNonAccompany: [{ type: 'required', message: 'Field required' }],
  dependentType: [{ type: 'required', message: 'Field required' }],
  addressCountry: [{ type: 'required', message: 'Field required' }],
  status: [{ type: 'required', message: 'Field required' }],
  addressOther: [{ type: 'required', message: 'Field required' }],
  residenceFrom: [{ type: 'required', message: 'Field required' }],
  residenceTo: [{ type: 'required', message: 'Field required' }],
  lastEntryDate: [{ type: 'required', message: 'Field required' }],
  lastEntryPlace: [{ type: 'required', message: 'Field required' }],
  isLivedInOtherCont: [{ type: 'required', message: 'Field required' }],
  maritialStatus: [{ type: 'required', message: 'Field required' }],
  marriageDate: [{ type: 'required', message: 'Field required' }],
  spouseName: [{ type: 'required', message: 'Field required' }],
  spouseFamily: [{ type: 'required', message: 'Field required' }],
  spouseGivenName: [{ type: 'required', message: 'Field required' }],
  previouslyMarried: [{ type: 'required', message: 'Field required' }],
  validTravelDocument: [{ type: 'required', message: 'Field required' }],
  nationalIdentyDoc: [{ type: 'required', message: 'Field required' }],
  travelOrPassportNo: [{ type: 'required', message: 'Field required' }],
  countryOfIssue: [{ type: 'required', message: 'Field required' }],
  issueDate: [{ type: 'required', message: 'Field required' }],
  expiryDate: [{ type: 'required', message: 'Field required' }],
  highestLevlEducation: [{ type: 'required', message: 'Field required' }],
  noOfYearsOfTotalEdu: [{ type: 'required', message: 'Field required' }],
  currentOccupation: [{ type: 'required', message: 'Field required' }],
  intendedOccupation: [{ type: 'required', message: 'Field required' }],
  nativeLang: [{ type: 'required', message: 'Field required' }],
  frequentlyUseLang: [{ type: 'required', message: 'Field required' }],
  ableToCommunicatEngOrFrn: [{ type: 'required', message: 'Field required' }],
  docNumber: [{ type: 'required', message: 'Field required' }],
  programApplyingFor: [{ type: 'required', message: 'Field required' }],
  categoryApplyingFor: [{ type: 'required', message: 'Field required' }],
  familyMembersCount: [{ type: 'required', message: 'Field required' }],
  correspondence: [{ type: 'required', message: 'Field required' }],
  interview: [{ type: 'required', message: 'Field required' }],
  interpreterReq: [{ type: 'required', message: 'Field required' }],
  provience: [{ type: 'required', message: 'Field required' }],
};

export const genericValidationMsg = {
  programApplyingFor: [{ type: 'required', message: 'Field required' }],
  categoryApplyingFor: [{ type: 'required', message: 'Field required' }],
  familyMembersCount: [{ type: 'required', message: 'Field required' }],
  correspondence: [{ type: 'required', message: 'Field required' }],
  interview: [{ type: 'required', message: 'Field required' }],
  interpreterRqu: [{ type: 'required', message: 'Field required' }],
  provience: [{ type: 'required', message: 'Field required' }],
  countryOfIssue: [{ type: 'required', message: 'Field required' }],
  travelOrPassportNo: [{ type: 'required', message: 'Field required' }],
  issueDate: [{ type: 'required', message: 'Field required' }],
  expiryDate: [{ type: 'required', message: 'Field required' }],
  validTravelDocument: [{ type: 'required', message: 'Field required' }],
};

export const scheduleFormValidationMsg = {
  fullName: [{ type: 'required', message: 'Field required' }],
  givenName: [{ type: 'required', message: 'Field required' }],
  dateOfBirth: [{ type: 'required', message: 'Field required' }],
  clientId: [{ type: 'required', message: 'Field required' }],
  grantedPermanentResiInCanada: [{ type: 'required', message: 'Field required' }],
  refugeeForOtherCountry: [{ type: 'required', message: 'Field required' }],
  returnToCountry: [{ type: 'required', message: 'Field required' }],
  citizenMoreThanOne: [{ type: 'required', message: 'Field required' }],
};

export const financialValidationMessage = {
  courtName: [{ type: 'required', message: 'Field required' }],
  courtFileNo: [{ type: 'required', message: 'Field required' }],
  courtOfficeAddress: [{ type: 'required', message: 'Field required' }],
  replyBy: [{ type: 'required', message: 'Field required' }],
};

export const affidavitValidationMessage = {
  courtName: [{ type: 'required', message: 'Field required' }],
  courtFileNo: [{ type: 'required', message: 'Field required' }],
  courtOfficeAddress: [{ type: 'required', message: 'Field required' }],
  replyBy: [{ type: 'required', message: 'Field required' }],
  affidavitDate: [{ type: 'required', message: 'Field required' }],
  legalName: [{ type: 'required', message: 'Field required' }],
  lawyerName: [{ type: 'required', message: 'Field required' }],
  fullName: [{ type: 'required', message: 'Field required' }],
  fullAddress: [{ type: 'required', message: 'Field required' }],

  municipalityName: [{ type: 'required', message: 'Field required' }],
  municipalityAddress: [{ type: 'required', message: 'Field required' }],
  commissionerName: [{ type: 'required', message: 'Field required' }],
  lawyerSignature: [{ type: 'required', message: 'Field required' }],
  statement: [{ type: 'required', message: 'Field required' }],
};

export const motionValidationMessage = {
  courtName: [{ type: 'required', message: 'Field required' }],
  courtFileNo: [{ type: 'required', message: 'Field required' }],
  courtOfficeAddress: [{ type: 'required', message: 'Field required' }],
  partyNames: [{ type: 'required', message: 'Field required' }],
  applicantName: [{ type: 'required', message: 'Field required' }],
  respondentName: [{ type: 'required', message: 'Field required' }],
  hearingDate: [{ type: 'required', message: 'Field required' }],
  judgeName: [{ type: 'required', message: 'Field required' }],
  filedBy: [{ type: 'required', message: 'Field required' }],
  motionMadeWith: [{ type: 'required', message: 'Field required' }],
  otherSpecify: [{ type: 'required', message: 'Field required' }],
  orderCourtToMake: [{ type: 'required', message: 'Field required' }],
  lawsAndRules: [{ type: 'required', message: 'Field required' }],
  partysLawyer: [{ type: 'required', message: 'Field required' }],
  otherPartysLawyer: [{ type: 'required', message: 'Field required' }],
  courtDealWithMotion: [{ type: 'required', message: 'Field required' }],
  motionMaterial: [{ type: 'required', message: 'Field required' }],
  continuingRecord: [{ type: 'required', message: 'Field required' }],
  transcriptEvidence: [{ type: 'required', message: 'Field required' }],
  evidenceDate: [{ type: 'required', message: 'Field required' }],
  pages: [{ type: 'required', message: 'Field required' }],
};

export const familyStatementValidationMessage = {
  courtName: [{ type: 'required', message: 'Field required' }],
  courtFileNo: [{ type: 'required', message: 'Field required' }],
  courtOfficeAddress: [{ type: 'required', message: 'Field required' }],
  fullName: [{ type: 'required', message: 'Field required' }],
};

export const orderOnMotionFormPageValidationMessage = {
  courtName: [{ type: 'required', message: 'Field required' }],
  courtFileNo: [{ type: 'required', message: 'Field required' }],
  courtOfficeAddress: [{ type: 'required', message: 'Field required' }],
  motioMadeBy: [{ type: 'required', message: 'Field required' }],
  withoutNoticeToName: [{ type: 'required', message: 'Field required' }],
  personInCourt: [{ type: 'required', message: 'Field required' }],
  motionDocumentList: [{ type: 'required', message: 'Field required' }],
  recievedBy: [{ type: 'required', message: 'Field required' }],
  courtOrder: [{ type: 'required', message: 'Field required' }],
  noticeTo: [{ type: 'required', message: 'Field required' }],
};

export const withdrawalInstructionValidationMessage = {
  courtFileNo: [{ type: 'required', message: 'Field required' }],
  courtOfficeAddress: [{ type: 'required', message: 'Field required' }],
  motioMadeBy: [{ type: 'required', message: 'Field required' }],
  withoutNoticeToName: [{ type: 'required', message: 'Field required' }],
  personInCourt: [{ type: 'required', message: 'Field required' }],
  motionDocumentList: [{ type: 'required', message: 'Field required' }],
  recievedBy: [{ type: 'required', message: 'Field required' }],
  courtOrder: [{ type: 'required', message: 'Field required' }],
};

export const divorceOrderValidationMessage = {
  courtName: [{ type: 'required', message: 'Field required' }],
  courtOfficeAddress: [{ type: 'required', message: 'Field required' }],
  courtFileNo: [{ type: 'required', message: 'Field required' }],
  receivedEvidence: [{ type: 'required', message: 'Field required' }],
  applicantName: [{ type: 'required', message: 'Field required' }],
  applicationDate: [{ type: 'required', message: 'Field required' }],
  spouseName: [{ type: 'required', message: 'Field required' }],
  marriedPlace: [{ type: 'required', message: 'Field required' }],
  marriedDate: [{ type: 'required', message: 'Field required' }],
  motionMadeBy: [{ type: 'required', message: 'Field required' }],
};

export const motionToChangeValidationMessage = {
  courtName: [{ type: 'required', message: 'Field required' }],
  courtOfficeAddress: [{ type: 'required', message: 'Field required' }],
  courtFileNo: [{ type: 'required', message: 'Field required' }],
};
