import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Countries } from '../../../../assets/data/country';

@Component({
  selector: 'app-select',
  template: `
    <ng-container [formGroup]="formGroup">
      <select
        [name]="question.controlName"
        [id]="question.controlName"
        [formControlName]="question.controlName"
        class="form-control"
        (change)="setValue($event, question, formField.fields)">
        <ng-container *ngIf="question?.answerOptions && question?.answerOptions?.length === 0">
          <option *ngFor="let option of countries" [value]="option">{{ option }}</option>
        </ng-container>
        <ng-container *ngIf="question?.answerOptions?.length > 0">
          <ng-container *ngIf="!question?.answerOptions[0].name">
            <option *ngFor="let option of question.answerOptions" [value]="option">{{ option }}</option>
          </ng-container>

          <ng-container *ngIf="question?.answerOptions[0].name">
            <option *ngFor="let option of question?.answerOptions" [value]="option.value">
              {{ option.name }}
            </option>
          </ng-container>
        </ng-container>
      </select>
    </ng-container>
  `,
  styles: [],
})
export class SelectComponent implements OnInit {
  @Input() formGroup: any;
  @Input() question: any;
  @Input() formField: any;
  @Output() setVal: EventEmitter<any> = new EventEmitter<any>();
  options = [];
  countries = Countries;

  constructor() {}

  ngOnInit(): void {
    const que = this.question.question.toLowerCase();

    if (que.includes('country') && !que.includes('(by birth, naturalization)')) {
      this.options = Countries.sort((a, b) => {
        if (a < b) {
          return -1;
        }
        if (a > b) {
          return 1;
        }
        return 0;
      });
    } else {
      this.options = this.question.answerOptions;
    }
  }

  setValue(ev: any, question: any, fields: any) {
    const params = {
      ev,
      question,
      fields,
    };

    this.setVal.emit(params);
  }
}
