import { AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment as env } from '../../../../environments/environment';
import { Endpoints } from '../../../config';
import * as MESSAGE from '../../../enum/info-messages.enum';
import { User } from '../../../interfaces/user.interface';
import { ApiService, AuthService, CommonService, DataService } from '../../../services';
import { userRole } from '../../../shared/config/userRole';
@Component({
  selector: 'app-law-firm-members',
  templateUrl: './law-firm-members.component.html',
  styleUrls: ['./law-firm-members.component.scss'],
})
export class LawFirmMembersComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('clientDisableModal') public clientDisableModal: ModalDirective;
  @ViewChild('editAccessLevel') public editAccessLevel: ModalDirective;
  @ViewChild('myModal') addMemberModal: ModalDirective;
  @ViewChild('selectAction') selectAction: ModalDirective;
  @ViewChild('interpreterModal') interpreterModal: ModalDirective;
  @ViewChild('showSignature') showSignature: ModalDirective;
  @ViewChild('editInterpreter') editInterpreterModal: ModalDirective;

  @Input() status: string;
  query: string;
  messageType = '';
  currentUser;
  public searchFilter: any = '';
  userId: string;
  actionType: boolean;
  assignedRole: number;
  userRole = userRole;
  user;
  groupedUser;
  memberRole;
  accessLevel: number;
  hourlyRate: number;
  memberGroup: FormGroup;
  interpreterGroup: FormGroup;
  submitForm = false;
  destroy$: Subject<any> = new Subject();
  signatureData;
  interpretersData;
  signaturePreview;
  base_url = env.base_url;
  modalTitle = 'Alert';
  modalMessage = 'Are you sure you want to delete this interpreter?';
  alertMessage = '';
  confirm = 'Confirm';
  cancel = 'Cancel';
  type = 'Delete Interpreter';
  showAlert = false;
  interpreterData: any;
  interpreterId = '';
  default = 'default';
  selectControl = new FormControl('');

  constructor(
    private dataService: DataService,
    private api: ApiService,
    private toastr: ToastrService,
    private authService: AuthService,
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
  ) {}

  endpoints = Endpoints;
  allUsers = [];

  ngOnInit(): void {
    this.dataService.setUserDetailActive(true);
    this.currentUser = JSON.parse(localStorage.getItem('userDetails'));
    this.accessLevel = +this.authService.getUserAccessLevel();

    this.getUserList();
    this.initializeForm();
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.addMember) {
        this.addMemberModal.show();
      }
    });
  }

  initializeForm() {
    this.memberGroup = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      middleName: [''],
      lastName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      email: ['', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$')]],
      mailingAddress: ['', [Validators.required]],
      accessLevel: ['', [Validators.required]],
      rate: ['', [Validators.required]],
    });

    this.interpreterGroup = this.fb.group({
      firstName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      middleName: [''],
      lastName: ['', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]],
      default: [false],
    });
  }

  getUserList() {
    this.api
      .getParams(this.endpoints.getAllUsers, { isActive: this.status === 'active' ? true : false, isClient: false })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          if (res) {
            this.allUsers = res;

            const sorted = [...this.allUsers].sort((a, b) => (a.lastName > b.lastName ? 1 : -1));

            this.allUsers = sorted.sort((a, b) => b.accessLevel - a.accessLevel);
            if (this.allUsers && this.allUsers.length > 0) {
              const groupUser = [...this.allUsers].reduce((groups, user) => {
                if (user) {
                  let accessLevel = user?.accessLevel;
                  let userRole;
                  if (user?.accessLevel === 1) userRole = 'Client';
                  if (user?.accessLevel === 2) userRole = 'Clerk';
                  if (user?.accessLevel === 3) userRole = 'Lawyer';
                  if (user?.accessLevel === 4) userRole = 'Admin';
                  if (user?.accessLevel === 5) userRole = 'Principal Admin';

                  groups[userRole] = groups[userRole] || [];
                  groups[userRole].push(user);

                  return groups;
                }
              }, {});

              const result: any = Object.keys(groupUser).map(key => ({ key, users: groupUser[key] }));
              if (result && result.length > 0) {
                this.groupedUser = result;
              }
            }
            this.getInterpreters();
          }
        },
        error => {},
      );
  }

  showActions(user: any, actionType: boolean) {
    document.getElementById('demo-click').click();
    this.actionType = actionType;
    this.userId = user._id;
    this.clientDisableModal.show();
  }

  changeAccessLevel(user: any, pop?: any) {
    document.getElementById('demo-click').click();
    this.assignedRole = user.accessLevel;
    this.hourlyRate = user.hourlyRate;
    this.userId = user._id;
    this.user = user;
    this.editAccessLevel.show();
  }

  disableUser(updateAccessLevel: boolean) {
    this.api
      .put(`${this.endpoints.disableUser}/${this.userId}`, { updateAccessLevel, actionType: this.actionType })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        () => {
          this.getUserList();
          this.clientDisableModal.hide();
        },
        error => {},
      );
  }

  onDisableUser(ev: any) {
    this.messageType = ev.target.value;
  }

  disableAction(action: string) {
    if (action === 'cancel') {
      this.messageType = '';
      this.clientDisableModal.hide();
    } else {
      if (this.messageType === 'updateAccessLevel') {
        this.disableUser(true);
      } else {
        this.disableUser(false);
      }
    }
  }

  updateUserAccessLevel() {
    this.api
      .put(`${this.endpoints.updateUser}/${this.userId}`, {
        accessLevel: this.assignedRole,
        hourlyRate: this.hourlyRate,
      })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          if (res) {
            this.getUserList();
            try {
              this.editAccessLevel.hide();
              this.toastr.success('Updated Successfully', 'Success');
            } catch (err) {
              this.toastr.error('Something went wrong', 'Alert');
            }
          }
        },
        error => {},
      );
  }

  hideModal() {
    this.editAccessLevel.hide();
  }

  get getControls() {
    return this.memberGroup.controls;
  }

  get getInterpreterControls() {
    return this.interpreterGroup.controls;
  }

  addMember() {
    this.submitForm = true;
    if (!this.memberGroup.valid) {
      return false;
    }
    const params = {
      users: {
        email: this.memberGroup.value.email.trim(),
        firstName: this.memberGroup.value.firstName.trim(),
        middleName: this.memberGroup.value.middleName,
        lastName: this.memberGroup.value.lastName.trim(),
        gender: '',
        streetNumber: '',
        streetName: '',
        city: '',
        province: '',
        postalCode: '',
        homePhoneNumber: '',
        mobileNumber: '',
        appartmentNumber: '',
        mailingAddress: this.memberGroup.value.mailingAddress,
      },
      subscriberUser: {
        accessLevel: this.memberGroup.value.accessLevel,
        hourlyRate: this.memberGroup.value.rate,
      },
    };

    this.api
      .post(this.endpoints.addLawfirmMember, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          if (res) {
            this.memberGroup.reset();
            this.submitForm = false;
            this.addMemberModal.hide();
            this.toastr.success('Added law firm member', MESSAGE.INFO_MESSAGES.SUCCESS);
            this.getUserList();
          }
        },
        error => {
          console.log(
            'TCL ->  ~ file: law-firm-members.component.ts:257 ~ LawFirmMembersComponent ~ addMember ~ error',
            error,
          );

          let errMessage = error.error || MESSAGE.RESPONSE.ERROR;
          this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        },
      );
  }

  closeMemberModal() {
    this.submitForm = false;
    this.addMemberModal.hide();
    this.memberGroup.reset();
  }

  closeInterpreterModal() {
    this.interpreterModal.hide();
    this.interpreterGroup.reset();
  }

  closeEditInterpreterModal() {
    this.editInterpreterModal.hide();
    this.interpreterGroup.reset();
    this.signatureData = '';
  }

  authenticateUser(users) {
    this.api
      .post(this.endpoints.authenticatedUser, { users })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        data => {
          if (data) {
            this.toastr.success('Invitation sent successfully', MESSAGE.INFO_MESSAGES.SUCCESS);
          }
        },
        error => {
          let errMessage = error.error || MESSAGE.RESPONSE.ERROR;
          this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        },
      );
  }

  /**
   * Method to resent the password using email
   * @param user
   */
  resendPassword(user: User) {
    this.api
      .put(this.endpoints.resetMemberPassword, { user })
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          this.toastr.success(data?.message, MESSAGE.INFO_MESSAGES.SUCCESS);
        },
        error => {
          let errMessage = error.error || MESSAGE.RESPONSE.ERROR;
          this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        },
      );
  }

  async addInterpreter() {
    if (!this.interpreterGroup.valid || !this.signatureData) {
      return false;
    }

    const id = Date.now();
    const params = {
      firstName: this.interpreterGroup.value.firstName.trim(),
      middleName: this.interpreterGroup.value.middleName,
      lastName: this.interpreterGroup.value.lastName.trim(),
      default: this.interpreterGroup.value.default,
      signatureId: id.toString(),
    };

    const res = await this.uploadSignature(id);
    if (res) {
      this.api
        .post(this.endpoints.addInterpreter, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            if (res) {
              this.interpreterGroup.reset();
              this.signatureData = '';
              this.interpreterModal.hide();
              this.toastr.success('Interpreter added successfully', MESSAGE.INFO_MESSAGES.SUCCESS);
              this.getInterpreters();
            }
          },
          err => {
            console.log('** ->  ~ file: law-firm-members.component.ts:361 ~ addInterpreter ~ err', err);
          },
        );
    }
  }

  getInterpreters() {
    this.api.get(this.endpoints.getInterpreters).subscribe(res => {
      if (res) {
        this.interpretersData = res;
      }
    });
  }

  getSignature(id, updateSignatureData = false) {
    const params = { userId: id };
    this.commonService
      .getFile(params, `${this.base_url}${this.endpoints.getSignature}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        async (res: any) => {
          if (res) {
            this.signaturePreview = await this.blobToBase64(res);
            if (updateSignatureData) {
              this.signatureData = this.signaturePreview;
            } else {
              this.showSignature.show();
            }
          }
        },
        err => {
          console.log('** ->  ~ file: law-firm-members.component.ts:361 ~ addInterpreter ~ err', err);
        },
      );
  }

  uploadSignature(id) {
    return new Promise((resolve, reject) => {
      const params = {
        userId: id.toString(),
        base64: this.signatureData,
        type: this.signatureData.split(';')[0].split('/')[1],
      };

      this.api
        .post(this.endpoints.uploadSignature, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            if (res) {
              resolve(true);
            } else {
              reject(false);
            }
          },
          err => {
            reject(false);
            this.toastr.error('Something went wrong while uploading signature', MESSAGE.TOASTR.ERROR);
          },
        );
    });
  }

  selectActionModal(ev) {
    if (ev.target.value === 'Add member') {
      this.selectAction.hide();
      this.addMemberModal.show();
    } else if (ev.target.value === 'Add interpreter') {
      this.selectAction.hide();
      this.interpreterModal.show();
    } else {
      return;
    }
    this.selectControl.setValue('');
  }

  browseFiles() {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/jpeg, image/png, image/jpg, image/webp';
    input.onchange = async () => {
      const files = Array.from(input.files);
      this.signatureData = await this.toBase64(files[0]);
    };
    input.click();
  }

  toBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
    });
  }

  blobToBase64(blob: Blob) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  deleteInterpreter(interpreterData) {
    const params = { interpreterId: interpreterData._id, signatureId: interpreterData.signatureId };
    this.api
      .deleteWithParams(this.endpoints.deleteInterpreter, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        res => {
          if (res) {
            this.getInterpreters();
            this.showAlert = false;
          }
        },
        error => {
          console.log('** ->  ~ file: law-firm-members.component.ts:483 ~ this.api.deleteWithParams ~ error', error);
        },
      );
  }

  openDeleteModal(interpreterData) {
    document.getElementById('demo-click').click();
    this.interpreterData = interpreterData;
    this.showAlert = true;
  }

  onConfirm(ev: any) {
    if (ev.state) {
      this.deleteInterpreter(this.interpreterData);
    } else {
      this.showAlert = false;
    }
  }

  openInterpreterModal(interpreterData) {
    document.getElementById('demo-click').click();
    this.interpreterGroup.patchValue(interpreterData);
    this.interpreterId = interpreterData?._id;

    this.editInterpreterModal.show();
    this.getSignature(interpreterData.signatureId, true);
  }

  async updateInterpreter() {
    if (!this.interpreterGroup.valid || !this.signatureData) {
      return false;
    }

    const id = Date.now();
    const params = {
      firstName: this.interpreterGroup.value.firstName.trim(),
      middleName: this.interpreterGroup.value.middleName,
      lastName: this.interpreterGroup.value.lastName.trim(),
      signatureId: id.toString(),
      _id: this.interpreterId,
    };

    const res = await this.uploadSignature(id);
    if (res) {
      this.api
        .put(this.endpoints.addInterpreter, params)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          res => {
            if (res) {
              this.interpreterGroup.reset();
              this.signatureData = '';
              this.editInterpreterModal.hide();
              this.toastr.success('Interpreter updated successfully', MESSAGE.INFO_MESSAGES.SUCCESS);
              this.getInterpreters();
            }
          },
          err => {
            console.log('** ->  ~ file: law-firm-members.component.ts:361 ~ addInterpreter ~ err', err);
          },
        );
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
