import { Component, OnInit } from '@angular/core';

@Component({
 selector: 'app-transfer-from-trust',
 templateUrl: './transfer-from-trust.component.html',
 styleUrls: ['./transfer-from-trust.component.scss'],
})
export class TransferFromTrustComponent implements OnInit {
 ngOnInit(): void {}
}
