import {
 HttpErrorResponse,
 HttpEvent,
 HttpHandler,
 HttpInterceptor,
 HttpRequest,
 HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-bootstrap-spinner';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, debounceTime, finalize, tap } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { AuthService, DataService } from '../services';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
 token: string;
 private apiCount = 0;
 private spinnerCountSubject = new BehaviorSubject<number>(0);

 constructor(
  private router: Router,
  private auth: AuthService,
  private data: DataService,
  private spinner: NgxSpinnerService,
 ) {
  this.spinnerCountSubject.pipe(debounceTime(100)).subscribe(count => {
   if (count > 0) {
    this.spinner.show();
   } else {
    this.spinner.hide();
   }
  });
 }

 intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
  this.incrementApiCount();
  this.auth.setHeader();

  if (!request.url.includes(env.pdfGeneratorUrl)) {
   request = request.clone({ headers: this.auth.headers });
  }

  return next.handle(request).pipe(
   tap(event => {
    if (event instanceof HttpResponse) {
     this.apiCount--;

     if (event.body) {
      const token = event.headers.get('X-auth-token');

      if (token) {
       this.auth.setToken(token);
       this.data.setUserDetail(event.body);
      }
     }
    }
   }),
   catchError(error => {
    if (error instanceof HttpErrorResponse) {
     this.apiCount--;

     if (error?.status === 401) {
      const url = window.location.pathname;
      if (url !== '/auth/login') {
       this.data.setRedirectUrl(url);
      }

      this.router.navigateByUrl('/auth/login');
     }
     return throwError(error);
    }
   }),
   finalize(() => {
    this.decrementApiCount();
   }),
  );
 }

 private incrementApiCount() {
  this.apiCount++;
  this.spinnerCountSubject.next(this.apiCount);
 }

 private decrementApiCount() {
  this.apiCount--;
  if (this.apiCount < 0) this.apiCount = 0; // Prevent negative count
  this.spinnerCountSubject.next(this.apiCount);
 }
}
