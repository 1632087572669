import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-number',
  template: `<div id="pgNo" class="hide-on-screen"></div>`,
  styles: [
    `
      @media screen {
        .hide-on-screen {
          display: none;
        }
      }
    `,
  ],
})
export class PageNumberComponent implements OnInit {
  ngOnInit(): void {
    document.getElementById('pgNo').innerHTML = '';
    let top = 0;
    let totalPages = Math.ceil(document.body.scrollHeight / 1160); //842px A4 pageheight for 72dpi, 1123px A4 pageheight for 96dpi,
    if ((document.body.scrollHeight / 1160) % 1 > 0.5) {
      totalPages += 1;
    }
    for (let i = 1; i <= totalPages; i++) {
      const pageNumberDiv = document.getElementById('pgNo');
      const div = document.createElement('div');
      div.innerText = 'Page ' + i + ' of ' + totalPages;
      div.style.position = 'absolute';
      top = i === 1 ? 1350 : top + 1360 + 45;
      div.style.top = top.toString() + 'px';
      div.style.right = '30px';
      div.style.zIndex = '999';
      div.style.height = '16px';
      pageNumberDiv.appendChild(div);
    }
  }
}
