import { Injectable, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../config';
import * as MESSAGE from '../enum/info-messages.enum';
import { LOCAL_STORAGE } from '../enum/local-storage.enum';
import { ApiService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class DataService implements OnDestroy {
  public isLoaded: Subject<boolean> = new Subject<boolean>();
  public userDetail = new BehaviorSubject(false);
  public redirectUrl = new BehaviorSubject('');
  public matterDetailActive = new BehaviorSubject(false);
  public userDetailActive = new BehaviorSubject(false);
  public matterType: Subject<string> = new Subject<string>();
  public matterData = new BehaviorSubject(null);
  public matterDetails: Subject<any> = new Subject<any>();
  public showAddMatterButton = new BehaviorSubject(false);
  public getSelectedUserData = new BehaviorSubject(null);
  public updateLawyers: Subject<any> = new Subject<any>();
  public updateClerks: Subject<any> = new Subject<any>();
  public updateDockets: Subject<boolean> = new Subject<boolean>();
  public updateTasks: Subject<boolean> = new Subject<boolean>();
  public taskList: Subject<any> = new Subject<any>();
  public updatePrecedent: Subject<any> = new Subject<any>();
  public refreshClaimSteps: Subject<any> = new Subject<any>();
  public questionId: Subject<any> = new Subject<any>();
  public recipient: Subject<any> = new Subject<any>();
  public refreshReceipts: Subject<any> = new Subject<any>();
  public messageCount: Subject<any> = new Subject<any>();
  public currentUser: any;
  destroy$ = new Subject<boolean>();

  constructor(private api: ApiService, private toastr: ToastrService) {
    this.currentUser = JSON.parse(localStorage.getItem('userDetails'));
  }

  getUserDetail() {
    return this.userDetail.asObservable();
  }

  setUserDetail(data: any) {
    this.userDetail.next(data);
  }

  getRedirectUrl() {
    return this.redirectUrl.asObservable();
  }

  setRedirectUrl(url: string) {
    this.redirectUrl.next(url);
  }

  getMatterDetailActive() {
    return this.matterDetailActive.asObservable();
  }

  setMatterDetailActive(data: boolean) {
    this.matterDetailActive.next(data);
  }

  setMatterDetails(data: any) {
    this.matterDetails.next(data);
  }

  setLoader(data: boolean) {
    this.isLoaded.next(data);
  }

  getLoader() {
    return this.isLoaded.asObservable();
  }

  getMatterDetails() {
    return this.matterDetails.asObservable();
  }

  getRecipient() {
    return this.recipient.asObservable();
  }

  setRecipient(recipient: string) {
    this.recipient.next(recipient);
  }

  setRefreshClaimstep() {
    this.refreshClaimSteps.next(true);
  }

  refreshClaimStepsRecords() {
    return this.refreshClaimSteps.asObservable();
  }

  getUserDetailActive() {
    return this.userDetailActive.asObservable();
  }

  setUserDetailActive(data: boolean) {
    this.userDetailActive.next(data);
  }

  setMatterType(data: string) {
    this.matterType.next(data);
  }

  setAddMatterButtonActive(data: boolean) {
    this.showAddMatterButton.next(data);
  }

  getAddMatterButtonActive() {
    return this.showAddMatterButton.asObservable();
  }

  setLawyersDetails(data: any) {
    this.updateLawyers.next(data);
  }

  getLawyersDetails() {
    return this.updateLawyers.asObservable();
  }

  setClerksDetails(data: any) {
    this.updateClerks.next(data);
  }

  getClerksDetails() {
    return this.updateClerks.asObservable();
  }

  setDockets(data: boolean) {
    this.updateDockets.next(data);
  }

  setTasks(data: boolean) {
    this.updateTasks.next(data);
  }

  setPrecedent(data: boolean) {
    this.updatePrecedent.next(data);
  }

  refreshPrecedent() {
    return this.updatePrecedent.asObservable();
  }

  getTasks() {
    return this.updateTasks.asObservable();
  }

  getDockets() {
    return this.updateDockets.asObservable();
  }

  setTaskList(data: any) {
    this.taskList.next(data);
  }

  getTaskList() {
    return this.taskList.asObservable();
  }

  pad(time) {
    return time < 10 ? '0' + time : time;
  }

  addMatterTask(data: any) {
    let formdata: any;
    const todaysDate = new Date();
    const taskDate = moment(todaysDate).format(MESSAGE.DATE_FORMAT.YMD);
    const taskStart = moment(todaysDate).format(MESSAGE.DATE_FORMAT.HH);
    const eventStartDateTime = new Date(`${taskDate}T${taskStart}`);
    const taskEnd = moment(todaysDate).add(1, 'hour').format(MESSAGE.DATE_FORMAT.HH);
    const eventEndDateTime = new Date(`${taskDate}T${taskEnd}`);

    try {
      formdata = {
        matterId: data?._id,
        clientId: data?.clientId,
        subscriberId: data?.subscriberId,
        taskTitle: 'Initial task',
        taskDate: taskDate,
        taskStart: taskStart,
        taskEnd: taskEnd,
        taskDescription: 'Task description',
        taskLocation: '',
        assignedTo: data?.lawyers[0],
        mapsUrl: '',
        taskColor: null,
        invitees: [],
        lawFirm: localStorage.getItem(LOCAL_STORAGE.LAW_FIRM_NAME),
        completed: false,
        caseProgress: false,
        timezoneOffset: new Date().getTimezoneOffset(),
        timeZone: new Date().toString().match(/([-\+][0-9]+)\s/)[1],
        eventStartDateTime: eventStartDateTime.toISOString(),
        eventEndDateTime: eventEndDateTime.toISOString(),
        removedInvitees: [],
      };
      this.insertTask(formdata);
    } catch (error) {}
  }

  insertTask(formData: any) {
    this.api
      .post(Endpoints.matterTask, formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          if (res) {
            this.toastr.success('Task added successfully!', 'Success');

            this.setTasks(true);
          }
        },
        error => {
          let errMessage = error.error || 'Something went wrong';

          this.toastr.error(errMessage, 'Alert');
        },
      );
  }

  addMatterDocket(data: any) {
    this.currentUser = JSON.parse(localStorage.getItem('userDetails'));

    const date = new Date();
    const taskStart = this.pad(date.getHours()) + ':' + this.pad(date.getMinutes());

    const formdata = {
      matterId: data._id,
      clientId: data.clientId,
      subscriberId: data.subscriberId,
      taskTitle: 'File opened',
      taskDescription: null,
      taskDate: moment(date).format('YYYY-MM-DD'),
      taskStart: taskStart,
      taskLocation: null,
      mapsUrl: null,
      taskHours: 0,
      taskMinutes: 0,
      billableUnits: '0:0',
      taskCloseDate: moment(date).format('YYYY-MM-DD'),
      assignedTo: this.currentUser?._id,
      completed: true,
      completedBy: this.currentUser?._id,
    };

    this.insertDockets(formdata);
  }

  insertDockets(formdata: any) {
    this.api
      .post(Endpoints.matterTask, formdata)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          if (res) {
            this.toastr.success('Docket added successfully!', 'Success');
            this.setDockets(true);
          }
        },
        error => {
          let errMessage = error.error || 'Something went wrong';

          this.toastr.error(errMessage, 'Alert');
        },
      );
  }

  setNetValue(key: string, netOnValue: any) {
    localStorage.setItem(key, netOnValue);
  }

  getNetValue(key: string) {
    return localStorage.getItem(key);
  }

  clearStorage(clearAll: boolean) {
    this.getSelectedUserData.next(null);
    this.matterDetailActive.next(false);
    if (clearAll) {
      localStorage.clear();
    }
  }

  setTimeStamp(timeStamp: any) {
    localStorage.setItem('activityTimeStamp', timeStamp);
  }

  getTimeStamp() {
    return +localStorage.getItem('activityTimeStamp');
  }

  clearTimeStamp() {
    localStorage.removeItem('activityTimeStamp');
  }

  setPreviousUrl(url: string) {
    localStorage.setItem('previousUrl', url);
  }

  getLastRoute() {
    return localStorage.getItem('previousUrl');
  }

  clearLastRoute() {
    localStorage.removeItem('previousUrl');
  }

  removeItem(key: string) {
    localStorage.removeItem(key);
  }

  setPrecedentOptions(data: any) {
    localStorage.setItem('precedentOptions', JSON.stringify(data));
  }

  getPrecedentOptions() {
    try {
      return JSON.parse(localStorage.getItem('precedentOptions'));
    } catch (err) {}
  }

  addItems(items: any) {
    localStorage.setItem('items', JSON.stringify(items));
  }

  getItems() {
    try {
      return JSON.parse(localStorage.getItem('items'));
    } catch (err) {}
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
