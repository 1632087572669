import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { PdfService } from '../../../services/pdf.service';

@Component({
  selector: 'app-field-selection',
  templateUrl: './field-selection.component.html',
  styleUrls: ['./field-selection.component.scss'],
})
export class FieldSelectionComponent implements OnChanges {
  @ViewChild('fieldSelection') public fieldSelection: ModalDirective;

  @Input() dockets: any;
  @Input() exportType: string;
  @Input() matterDetails: any;
  @Output() confirm = new EventEmitter();
  fieldSelectionForm: FormGroup;
  selectedFields: any = ['Date', 'Time', 'Particular', 'Hour(s)', 'Counsel / Clerk'];

  constructor(private pdfService: PdfService) {}

  ngOnChanges() {
    setTimeout(() => {
      this.fieldSelection.show();
    }, 100);
  }

  isChecked(ev: any, whichType: string) {
    if (ev.target.checked) {
      this.selectedFields.push(whichType);
    } else {
      const ind = this.selectedFields.indexOf(whichType);
      this.selectedFields.splice(ind, 1);
    }
  }

  hideModal() {
    this.fieldSelection.hide();
    this.confirm.emit({ isAdd: false });
  }

  exportPDF() {
    const isDone = this.pdfService.export(this.selectedFields, this.dockets, this.exportType, this.matterDetails);
    this.fieldSelection.hide();
    this.hideModal();
  }
}
