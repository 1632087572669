import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import * as MESSAGE from '../../../enum/info-messages.enum';
import { LOCAL_STORAGE } from '../../../enum/local-storage.enum';
import { ApiService, AuthService } from '../../../services';

@Component({
  selector: 'app-add-event',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
})
export class AddEventComponent implements OnInit, OnChanges {
  @ViewChild('eventModal') public eventModal: ModalDirective;

  @Output() confirm = new EventEmitter();
  @Input() clientId: string;
  @Input() eventData;
  eventForm: FormGroup;
  endpoints = Endpoints;
  userDetails;
  accessLevel: number;
  userId: string;
  isSubmittedEventForm = false;
  canEditDelete = false;
  editLabel = false;
  removedInvitees = [];
  addInvitees = [];
  destroy$ = new Subject<boolean>();

  constructor(
    private api: ApiService,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private auth: AuthService,
  ) {}

  ngOnInit(): void {
    this.initializeForm();
    setTimeout(() => {
      this.eventModal.show();
    }, 100);

    if (this.eventData && this.eventData !== undefined) {
      this.patchEventData();
      this.editLabel = true;
    } else {
      this.editLabel = false;
    }

    this.userDetails = this.auth.getUserDetails();
    this.accessLevel = this.userDetails.accessLevel;
    this.userId = this.userDetails._id;

    if (this.editLabel) {
      if (this.eventData && (this.eventData?.creatorId === this.userId || this.accessLevel > 3)) {
        this.canEditDelete = true;
      } else {
        this.eventForm.disable();
      }
    }
  }

  ngOnChanges() {
    setTimeout(() => {
      this.eventModal.show();
    }, 100);
  }

  patchEventData() {
    this.eventForm.patchValue({
      eventTitle: this.eventData.eventTitle,
      eventDescription: this.eventData.eventDescription,
      eventDate: this.eventData.eventDate,
      eventStart: this.eventData.eventStart,
      eventEnd: this.eventData.eventEnd,
      eventColor: this.eventData.eventColor,
      email: this.eventData.invitees,
    });
    this.addInvitees = this.eventData?.invitees.length > 0 ? this.eventData?.invitees : [];
  }

  initializeForm() {
    this.eventForm = this.fb.group({
      eventTitle: ['', [Validators.required]],
      eventDescription: [''],
      eventDate: ['', [Validators.required]],
      eventStart: ['', [Validators.required]],
      eventEnd: ['', [Validators.required]],
      eventColor: [null],
      email: [''],
    });
  }

  get getControls() {
    return this.eventForm.controls;
  }

  cancel() {
    this.eventForm.reset();
    this.confirm.emit({ isAdd: false });
  }

  addEvent() {
    this.isSubmittedEventForm = true;
    if (this.eventForm.invalid) {
      return;
    }

    if (this.editLabel && !this.canEditDelete) {
      return;
    }

    const eventDate = moment(this.eventForm.value.eventDate).format('YYYY-MM-DD');
    const eventStart = this.eventForm.value.eventStart;
    const eventStartDateTime = new Date(`${eventDate}T${eventStart}`);
    const eventEnd = this.eventForm.value.eventEnd;
    const eventEndDateTime = new Date(`${eventDate}T${eventEnd}`);

    const lawfirmName = localStorage.getItem(LOCAL_STORAGE.LAW_FIRM_NAME);

    const params = {
      eventId: this.eventData?._id,
      eventTitle: this.eventForm.value.eventTitle,
      eventDescription: this.eventForm.value.eventDescription,
      eventDate: this.eventForm.value.eventDate,
      eventStart: this.eventForm.value.eventStart,
      eventEnd: this.eventForm.value.eventEnd,
      eventColor: this.eventForm.value.eventColor,
      invitees: this.addInvitees.length > 0 ? this.addInvitees : [],
      timezoneOffset: new Date().getTimezoneOffset(),
      lawFirm: lawfirmName,
      removedInvitees: this.editLabel ? this.removedInvitees : [],
      eventStartDateTime: eventStartDateTime.toISOString(),
      eventEndDateTime: eventEndDateTime.toISOString(),
    };

    const apiEndPoint = this.editLabel
      ? this.api.put(`${Endpoints.addEvent}`, params)
      : this.api.post(`${Endpoints.addEvent}`, params);
    apiEndPoint.pipe(takeUntil(this.destroy$)).subscribe(
      () => {
        this.toastr.success(MESSAGE.TOASTR.MESSAGE_UPDATEEVENT);
        this.confirm.emit({ isAdd: true });
        this.isSubmittedEventForm = false;
      },
      err => {
        let errMessage = err.error || MESSAGE.RESPONSE.ERROR;
        this.toastr.error(errMessage, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
        this.isSubmittedEventForm = false;
      },
    );
  }

  deleteEvent() {
    this.confirm.emit({ canDelete: this.canEditDelete });
  }

  onRemove(ev: any) {
    if (ev && ev.value && ev.value !== '') {
      this.removedInvitees.push(ev.value);
      this.addInvitees = this.addInvitees.filter(email => email !== ev.value);
    } else {
      this.addInvitees = this.addInvitees.filter(email => email !== ev);
      this.removedInvitees.push(ev);
    }
  }

  onEmailInput(ev: any) {
    if (ev && ev.value !== '') {
      this.addInvitees.push(ev.value);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
