import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment as env } from '../../environments/environment';
import { Endpoints } from '../config';
import { LOCAL_STORAGE } from '../enum/local-storage.enum';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root',
})
export class CommonService implements OnDestroy {
  public loggedUserName = new BehaviorSubject<any>(false);
  public userName = this.loggedUserName.asObservable();
  public showCalendar: Subject<boolean> = new Subject<boolean>();
  public isEventClicked: Subject<any> = new Subject<any>();
  public lawFirmDetails: Subject<any> = new Subject<any>();
  public unselectedTab: Subject<string> = new Subject<string>();
  public hideSideMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public InvoiceInfo: any;
  public precedentTabChange: Subject<string> = new Subject<string>();
  public allowUpdate: Subject<boolean> = new Subject<boolean>();
  public updateSideMenu: Subject<boolean> = new Subject<boolean>();
  public updateNotificationBadge: Subject<boolean> = new Subject<boolean>();
  public disableSettings: Subject<boolean> = new Subject<boolean>();
  public precedentArray: any = [];
  destroy$ = new Subject<boolean>();

  get showSideMenu(): boolean {
    return this.hideSideMenu.getValue();
  }

  private units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  endPoints = Endpoints;
  base_url = env.base_url;

  constructor(
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private authService: AuthService,
    private toastr: ToastrService,
  ) {}

  calculateDuration(task: any) {
    if (task.taskEnd && task.taskEnd !== undefined) {
      const endTime = task.taskEnd.split(':');
      const startTime = task.taskStart.split(':');

      let start;
      if (startTime[0] == 0) {
        start = moment(task.taskDate).add(startTime[1], 'minute');
      } else {
        start = moment(task.taskDate).add(startTime[0], 'hour').add(startTime[1], 'minute');
      }
      let end;
      if (endTime[0] == 0) {
        end = moment(task.taskDate).add(endTime[1], 'minute');
      } else {
        end = moment(task.taskDate).add(endTime[0], 'hour').add(endTime[1], 'minute');
      }
      const diff = end.diff(start);
      const duration = moment.utc(diff).format('h [hrs], m [min]');
      return duration;
    }
  }

  updateUserName(data: string) {
    this.loggedUserName.next(data);
  }

  addAppicants() {
    return this.formBuilder.group({
      applicantFullLegalName: [''],
      applicantStreetNo: [''],
      applicantPhone: [''],
      applicantEmail: [''],
      applicantProvince: [''],
      applicantCity: [''],
      applicantPostalCode: [''],
      applicantStreetName: [''],
      fullAddress: [''],
      applicantCountry: [''],
      applicantFax: [''],
      contactInfo: [''],
    });
  }

  addRespondent() {
    return this.formBuilder.group({
      respondentFullLegalName: [''],
      respondentStreetNo: [''],
      respondentPhone: [''],
      respondentEmail: [''],
      respondentCity: [''],
      respondentProvince: [''],
      respondentPostalCode: [''],
      respondentStreetName: [''],
      fullAddress: [''],
      respondentCountry: [''],
      respondentFax: [''],
      contactInfo: [''],
    });
  }

  addRespondentLawyerNames() {
    return this.formBuilder.group({
      respondentLawyerFullLegalName: [''],
      respondentLawyerStreetNo: [''],
      respondentLawyerPhone: [''],
      respondentLawyerEmail: [''],
      respondentLawyerProvince: [''],
      respondentLawyerCity: [''],
      respondentLawyerCountry: [''],
      respondentLawyerPostalCode: [''],
      respondentLawyerStreetName: [''],
      fullAddress: [''],
      respondentLawyerFax: [''],
      contactInfo: [''],
    });
  }

  addApplicantsLawyerNames() {
    return this.formBuilder.group({
      applicantLawyerFullLegalName: [''],
      applicantLawyerStreetNo: [''],
      applicantLawyerPhone: [''],
      applicantLawyerEmail: [''],
      applicantLawyerProvince: [''],
      applicantLawyerCity: [''],
      applicantLawyerCountry: [''],
      applicantLawyerPostalCode: [''],
      applicantLawyerStreetName: [''],
      fullAddress: [''],
      applicantLawyerFax: [''],
      contactInfo: [''],
    });
  }

  applicantFormGroup() {
    return this.formBuilder.group({
      applicants: this.formBuilder.array([]),
      applicantsLawyerNames: this.formBuilder.array([]),
      judgeName: [''],
    });
  }

  respondentFormGroup() {
    return this.formBuilder.group({
      respondent: this.formBuilder.array([]),
      respondentLawyerNames: this.formBuilder.array([]),
      orderDate: [''],
    });
  }

  showSpinner(isShow: boolean) {
    if (isShow) {
    } else {
    }
  }

  showSidebarCalendar(data: boolean) {
    this.showCalendar.next(data);
  }

  filterList(filterObj: any, preFilter: any) {
    if (preFilter.preType && preFilter.preStatus) {
      if (
        preFilter.preType === filterObj.matter?.matterType.toLowerCase() &&
        preFilter.preStatus === filterObj.completed?.toString()
      ) {
        return true;
      }

      if (
        preFilter.preType === filterObj.matterType?.toLowerCase() &&
        preFilter.preStatus === filterObj.status?.toString()
      ) {
        return true;
      }
    }
    if (preFilter.preType !== '' && preFilter.preStatus == '') {
      if (preFilter.preType === filterObj.matter?.matterType.toLowerCase()) {
        return true;
      }
      if (preFilter.preType === filterObj.matterType?.toLowerCase()) {
        return true;
      }
    }
    if (preFilter.preType == '' && preFilter.preStatus != '') {
      if (preFilter.preStatus === filterObj.completed?.toString()) {
        return true;
      }
      if (preFilter.preStatus === filterObj.status?.toLowerCase()) {
        return true;
      }
    }
    if (preFilter.preType == '' && preFilter.preStatus == '') {
      return false;
    }
  }

  transform(bytes: number = 0, precision: number = 2): string {
    if (isNaN(parseFloat(String(bytes))) || !isFinite(bytes)) return '?';
    let unit = 0;
    while (bytes >= 1024) {
      bytes /= 1024;
      unit++;
    }
    return bytes.toFixed(+precision) + ' ' + this.units[unit];
  }

  getFile(params: any, url = null, mergePdf = false, globalFile = false) {
    const headers = new HttpHeaders({
      'x-auth-token': this.authService.getToken(),
    });

    let urlName;
    if (globalFile) {
      urlName = url || `${this.base_url}${this.endPoints.downloadGlobalFile}`;
    } else {
      urlName = url || `${this.base_url}${this.endPoints.downloadFile}`;
    }

    if (mergePdf) {
      return this.http.post(urlName, params, { headers: headers, responseType: 'text' as 'json' });
    } else {
      return this.http.post(urlName, params, { headers: headers, responseType: 'blob' });
    }
  }

  getZipFile(params: any, url = null, globalFile = false) {
    const headers = new HttpHeaders({
      'x-auth-token': this.authService.getToken(),
    });

    if (globalFile) {
      return this.http.post(`${this.base_url}${this.endPoints.getGlobalZippedFiles}`, params, {
        headers: headers,
        responseType: 'blob',
      });
    } else {
      return this.http.post(`${this.base_url}${this.endPoints.getZippedFiles}`, params, {
        headers: headers,
        responseType: 'blob',
      });
    }
  }

  sendClientEmail(params: any, reference = false) {
    const headers = new HttpHeaders({
      'x-auth-token': this.authService.getToken(),
    });

    if (reference) {
      return this.http
        .post(`${this.base_url}${this.endPoints.sendReferenceMail}`, params, { headers: headers })
        .toPromise();
    } else {
      return this.http.post(`${this.base_url}${this.endPoints.sendMail}`, params, { headers: headers }).toPromise();
    }
  }

  getLawfirm() {
    this.http
      .get(`${this.base_url}${this.endPoints.subscriber}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (response: any) => {
          if (response && response?.unitTime !== '') {
            const unitTime = response?.unitTime;

            if (Boolean(unitTime)) {
              this.lawFirmDetails.next(response);
              localStorage.setItem(LOCAL_STORAGE.UNIT_TIME, unitTime);
              localStorage.setItem(LOCAL_STORAGE.LAW_FIRM_NAME, response.subscriberName);
              localStorage.setItem(LOCAL_STORAGE.TAX_RATE, response?.taxRate?.$numberDecimal);
            }
          }
        },
        err => {
          console.error('the error', err);
        },
      );
  }

  getLawfirmName() {
    return localStorage.getItem(LOCAL_STORAGE.LAW_FIRM_NAME);
  }

  validateNumber(val: number) {
    return Math.abs(val);
  }

  setInvoiceInfo(info: any) {
    this.InvoiceInfo = info;
  }

  clearInvoiceInfo() {
    this.InvoiceInfo = null;
  }

  getTrustData(matterId: string, matterSpecific: boolean, type: string, pagination?: any, creditType?: string) {
    let params;
    if (Boolean(creditType)) {
      params = {
        creditType,
        type,
        matterSpecific,
      };
    } else {
      params = {
        type,
        matterSpecific,
      };
    }
    if (pagination && pagination?.pageSize !== '') {
      params.pageSize = pagination.pageSize;
      params.pageNumber = pagination.pageNumber;
    }
    const headers = new HttpHeaders({
      'x-auth-token': this.authService.getToken(),
    });
    return this.http
      .get(`${this.base_url}${this.endPoints.getTrustReceipts}${matterId}`, { params, headers })
      .toPromise();
  }

  getInterTrustData(matterId: string, matterSpecific: boolean) {
    let params;

    if (Boolean(matterSpecific)) {
      params = {
        matterId,
        matterSpecific,
      };
    } else {
      params = {
        matterSpecific,
      };
    }
    const headers = new HttpHeaders({
      'x-auth-token': this.authService.getToken(),
    });
    return this.http.get(`${this.base_url}${this.endPoints.interTrustTransfer}`, { params, headers }).toPromise();
  }

  getMaterData() {
    try {
      const data: any = JSON.parse(localStorage.getItem('matterNavDetails'));
      return data.matterDetails;
    } catch (err) {}
  }

  addLineBreak(content: string) {
    return content?.replace(/\n/g, '<br/>');
  }

  getCurrentPartyDetails(matterDetails) {
    const fullName = `${matterDetails?.user?.firstName ? matterDetails?.user?.firstName + ' ' : ''}${
      matterDetails?.user?.lastName ? matterDetails?.user?.lastName : ''
    }`;
    const address = `${
      matterDetails?.mailingAddress?.streetNumber ? matterDetails?.mailingAddress?.streetNumber + ' ' : ''
    }${matterDetails?.mailingAddress?.streetName ? matterDetails?.mailingAddress?.streetName + ',\n' : ',\n'}${
      matterDetails?.mailingAddress?.city ? matterDetails?.mailingAddress?.city + ', ' : ''
    }${matterDetails?.mailingAddress?.province ? matterDetails?.mailingAddress?.province + '\n' : '\n'}${
      matterDetails?.mailingAddress?.postalCode ? matterDetails?.mailingAddress?.postalCode : ''
    }`;
    const contactNumber = matterDetails?.user?.mobilePhone ? matterDetails?.user?.mobilePhone : '';
    const email = matterDetails?.user?.email ? matterDetails?.user?.email : '';
    const fullAddress = `${fullName !== '' ? fullName + '\n' : ''}${address !== '' ? address + '\n' : ''}${
      contactNumber !== '' ? contactNumber + '\n' : ''
    }${email !== '' ? email : ''}`;

    return fullAddress;
  }

  getCurrentPartyLawyerDetails(matterDetails) {
    const fullName = `${matterDetails?.lawyers[0]?.firstName ? matterDetails?.lawyers[0]?.firstName + ' ' : ''}${
      matterDetails?.lawyers[0]?.lastName ? matterDetails?.lawyers[0]?.lastName : ''
    }`;
    const address = `${matterDetails?.lawyers[0]?.streetNumber ? matterDetails?.lawyers[0]?.streetNumber + ' ' : ''}${
      matterDetails?.lawyers[0]?.streetName ? matterDetails?.lawyers[0]?.streetName + '\n' : '\n'
    }${matterDetails?.lawyers[0]?.city ? matterDetails?.lawyers[0]?.city + ', ' : ''}${
      matterDetails?.lawyers[0]?.province ? matterDetails?.lawyers[0]?.province + '\n' : '\n'
    }${matterDetails?.lawyers[0]?.postalCode ? matterDetails?.lawyers[0]?.postalCode : ''}`;
    const contactNumber = matterDetails?.lawyers[0]?.mobilePhone ? matterDetails?.lawyers[0]?.mobilePhone : '';
    const email = matterDetails?.lawyers[0]?.email ? matterDetails?.lawyers[0]?.email : '';
    const fullAddress = `${fullName !== '' ? fullName + '\n' : ''}${address !== '' ? address + '\n' : ''}${
      Boolean(contactNumber) ? contactNumber + '\n' : ''
    }${email !== '' ? email : ''}`;

    return fullAddress;
  }

  onKeyPressEvent(event: KeyboardEvent): void {
    const input = (event.target as HTMLInputElement).value;
    const parts = input.split('.');
    if (parts[0].length >= 6) {
      event.preventDefault();
    }
  }

  formatNumber($event: any, controlName: string, formName: FormGroup) {
    const val = Math.abs(+($event.target as HTMLInputElement).value);
    const isValidNumber = /^\d*\.?\d*$/.test(($event.target as HTMLInputElement).value);

    if (isValidNumber) {
      formName.get(controlName).setValue(val.toFixed(2));
    } else {
      ($event.target as HTMLInputElement).value = '';
      this.toastr.error('Please enter a valid amount');
    }
  }

  onDateChange(event: Date, form: FormGroup, controlName: string): void {
    if (event) {
      const formattedDate = moment(event).format('YYYY-MM-DD');
      form.get(controlName)?.setValue(formattedDate);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
