import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../../../../../../environments/environment';
import { Endpoints } from '../../../../../../config';
import { CommonService } from '../../../../../../services';

@Component({
 selector: 'app-trust-disbursements',
 templateUrl: './trust-disbursements.component.html',
 styleUrls: ['./trust-disbursements.component.scss'],
})
export class TrustDisbursementsComponent implements OnInit, OnDestroy {
 @Input() matterSpecific: boolean;
 destroy$ = new Subject<boolean>();
 matterDetails: any;
 receipts: any;
 transferType = 'debit';
 notFoundFlag = false;
 requestedReceipts: any;
 showPaymentModal = false;
 subscription$: Subscription;
 endPoints = Endpoints;
 totalBalance = 0;
 dataRange = '';
 pageNo = 1;
 pageLimit = 10;
 totalPages: number;
 windowWidth: number = window.innerWidth;

 constructor(private commonService: CommonService) {}

 ngOnInit(): void {
  try {
   const data: any = JSON.parse(localStorage.getItem('matterNavDetails'));
   this.matterDetails = data.matterDetails;
  } catch (err) {}

  this.getTrustReceipts();
 }

 async getTrustReceipts() {
  const data: any = await this.commonService
   .getTrustData(this.matterDetails?._id, this.matterSpecific, 'debit', {
    pageNumber: this.pageNo,
    pageSize: this.pageLimit,
   })
   .catch(err => {
    this.receipts = [];
    this.notFoundFlag = true;
   });

  if (data?.records.length == 0) {
   this.notFoundFlag = true;
  }
  if (this.matterSpecific) {
   this.receipts = data?.records;
  } else {
   this.receipts = data?.records?.sort((a, b) => {
    const as: any = new Date(a.timestamps);
    const bs: any = new Date(b.timestamps);
    return bs - as;
   });
  }

  this.totalBalance = +data?.trustBalance?.$numberDecimal ? +data?.trustBalance?.$numberDecimal : 0;
  this.totalPages = data?.count;
 }

 pageChange(ev: number) {
  this.pageNo = ev;
  this.getTrustReceipts();
 }

 showModal() {
  this.showPaymentModal = true;
 }

 OnConfirm(ev: any) {
  if (ev?.isDone) {
   this.getTrustReceipts();
  }
  this.showPaymentModal = !this.showPaymentModal;
 }

 getFile() {
  this.commonService
   .getFile({}, `${environment.base_url}trust/get-PDF`)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    async (res: any) => {
     const blob = new Blob([res], { type: 'application/pdf' });
     const fileName = `trust-disbursement-${Date.now()}.pdf`;
     const objectUrl = URL.createObjectURL(blob);

     const a: HTMLAnchorElement = document.createElement('a') as HTMLAnchorElement;
     a.href = objectUrl;
     a.download = fileName;
     document.body.appendChild(a);
     a.click();

     document.body.removeChild(a);
     URL.revokeObjectURL(objectUrl);
    },
    err => {
     console.log('Error >', err);
    },
   );
 }

 async downloadPDF(dataRange, transferType: string) {
  const from = moment(dataRange[0]).format('YYYY-MM-DD');
  const to = moment(dataRange[1]).format('YYYY-MM-DD');
  this.dataRange = '';
  this.commonService
   .getFile({ from, to, transferType }, `${environment.base_url}${this.endPoints.downloadTrustData}`)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    async () => {
     this.getFile();
    },
    err => {},
   );
 }

 ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.complete();
 }
}
