import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root',
})
export class ValidationsService {
  constructor() {}

  cannotContainSpace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if ((control.value as string).indexOf(' ') >= 0) {
        return { cannotContainSpace: true };
      }
      return null;
    };
  }

  cannotContainLeadingSpace(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value as string;

      const isHtml = /<\/?[a-z][\s\S]*>/i.test(value);
      const processedValue = isHtml ? this.stripHtml(value) : value;

      if (processedValue && processedValue.trimLeft() !== processedValue) {
        return { cannotContainLeadingSpace: true };
      }
      return null;
    };
  }

  stripHtml(html: string): string {
    if (!html) {
      return '';
    }

    const doc = new DOMParser().parseFromString(html, 'text/html');
    const decodedString = doc.body.textContent || '';
    return decodedString.replace(/&nbsp;/g, ' ');
  }

  noWhitespaceValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const isHtml = /<\/?[a-z][\s\S]*>/i.test(control.value);

      const processedValue = isHtml ? this.stripHtml(control.value) : control.value;
      const isWhitespace = (processedValue || '').trim().length === 0;
      return isWhitespace ? { whitespace: true } : null;
    };
  }

  //Positive number validation
  positiveNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const value = control.value;

      if (value === null || value === '') {
        return null;
      }

      const valid = /^[0-9]+$/.test(value);
      if (!valid || value <= 0) {
        return { positiveNumber: { value: value } };
      }
      return null;
    };
  }

  //Restricts the all signs(/*-+) & special character
  restrictMinusAndSpecialChars(event: KeyboardEvent): void {
    const key = event.key;
    if (!/^\d$/.test(key)) {
      event.preventDefault();
    }
  }

  //On paste it only takes numbers, not string and exponential(e)
  onPaste(event: ClipboardEvent): void {
    const pastedData = event.clipboardData?.getData('text/plain');
    if (pastedData && isNaN(Number(pastedData))) {
      event.preventDefault();
    }
  }

  //For input type number, Max digit length validation
  maxDigitsValidator(maxDigits: number): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (value && value.toString().length > maxDigits) {
        return { maxDigits: { requiredLength: maxDigits, actualLength: value.toString().length } };
      }
      return null;
    };
  }
}
