export const SideBar = [
  'memo',
  'accounting',
  'brief-dockets',
  'data-forms',
  'documents',
  'archive',
  'precedents',
  'compliance',
  'data',
  'forms',
  'notifications',
  'documents?notification=true',
  'bin',
  'chat',
  'approvals',
  'brief',
  'dockets',
  'case-progress',
];
