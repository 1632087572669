import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ApiService } from '.';
import { Endpoints } from '../config';
import { ERROR_MESSAGE, INFO_MESSAGES, TOASTR } from '../enum/info-messages.enum';
import { ROUTES_TO } from '../enum/routes.enum';

@Injectable({
  providedIn: 'root',
})
export class BriefDocketService implements OnDestroy {
  endpoints = Endpoints;
  initialLoad: boolean = false;
  loginErr: string = '';
  enableControls: Subject<boolean> = new Subject<boolean>();
  saveData: Subject<boolean> = new Subject<boolean>();
  cancel: Subject<boolean> = new Subject<boolean>();
  isEditable: Subject<boolean> = new Subject<boolean>();
  destroy$ = new Subject<boolean>();

  constructor(private api: ApiService, private toastr: ToastrService, private router: Router) {}

  getMatterDetails(matterId) {
    return this.api.get(`${this.endpoints.getMatter}/${matterId}`);
  }

  getLawyers() {
    this.api
      .get(this.endpoints.getLawyer)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          this.loginErr = '';
          if (data) {
            let lawyersDetail = [...data];
            lawyersDetail = lawyersDetail.map(lawyer => {
              lawyer['fullName'] = `${lawyer.firstName} ${lawyer.middleName} ${lawyer.lastName}`;
              lawyer['_id'] = lawyer._id;
              return lawyer;
            });
          }
        },
        error => {
          const loginErr = error.error || error.message || 'something went wrong';
          this.toastr.error(loginErr, 'Alert');
        },
      );
  }

  getClerks() {
    this.api
      .get(this.endpoints.getClerks)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          if (data) {
            let clerksDetail = data;
            let otherLawyers = [...clerksDetail];
            return otherLawyers;
          }
        },
        error => {
          const loginErr = error.error || error.message || 'something went wrong';
          this.toastr.error(loginErr, 'Alert');
        },
      );
  }

  updateMatterStatus(formData: any) {
    this.api
      .put(this.endpoints.matter, formData)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          if (res) {
            this.toastr.success(TOASTR.MESSAGE_MATER_CLOSED, INFO_MESSAGES.SUCCESS);
            this.router.navigate([ROUTES_TO.MATTERS]);
          }
        },
        error => {
          console.log('error', error);
          this.toastr.success(ERROR_MESSAGE.RESPONSE_MESSAGE, TOASTR.ERROR);
        },
      );
  }

  getAnswers(matter: any) {
    return this.api.getParams(`${Endpoints.getAnswer}`, { matterId: matter._id, clientId: matter.clientId });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
