import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CommonService } from '../../../services';

@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
})
export class TaskDetailsComponent implements OnInit {
  @ViewChild('eventDetailsModal') public eventDetailsModal: ModalDirective;

  @Input() eventDetails: any;
  @Output() onClose = new EventEmitter();

  eventData;

  constructor(private commonService: CommonService, private router: Router) {}

  ngOnInit(): void {
    this.eventData = this.eventDetails.meta.taskDetails;
  }

  ngOnChanges() {
    setTimeout(() => {
      this.eventDetailsModal.show();
    }, 100);
  }

  openMatter() {
    const matterType = this.eventDetails.meta.type === 'docket' ? 3 : 2;
    const matterId = this.eventDetails.id;
    this.eventDetailsModal.hide();
    this.onClose.emit({ isModalOpen: false });
    setTimeout(() => {
      this.commonService.isEventClicked.next(matterType);
    }, 100);
    this.router.navigate([`/matters/${matterId}/brief-dockets`]);
  }

  onModalClose() {
    this.eventDetailsModal.hide();
    this.onClose.emit({ isModalOpen: false });
  }
}
