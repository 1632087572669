import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'app/services';

@Component({
  selector: 'app-date',
  template: `
    <ng-container [formGroup]="formGroup">
      <label>{{ question?.question }}</label>
      <!-- <input
            type="date"
            [name]="question?.controlName"
            [id]="question?.controlName"
            [max]="question?.controlName?.toLowerCase().includes('dob')  || question?.controlName?.includes('previousRelationship')  || question?.controlName?.includes('departureDate')  || question?.controlName?.includes('arrivalDate') ? minDate : ''"
            [formControlName]="question?.controlName"
            class="form-control"
          /> -->
      <input
        type="text"
        class="form-control"
        #dp="bsDatepicker"
        placeholder="yyyy-mm-dd"
        [formControlName]="question.controlName"
        bsDatepicker
        [maxDate]="
          question.controlName.toLowerCase().includes('dob') ||
          question.controlName.includes('previousRelationship') ||
          question.controlName.includes('departureDate') ||
          question.controlName.includes('arrivalDate')
            ? maxDate
            : null
        "
        [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', showWeekNumbers: false }"
        (bsValueChange)="onDateChange($event, question.controlName)" />
    </ng-container>
  `,
  styles: [],
})
export class DateComponent implements OnInit {
  @Input() formGroup: any;
  @Input() question: any;
  // minDate = moment(new Date()).format('YYYY-MM-DD');
  maxDate: any = new Date();
  constructor(private commonService: CommonService) {}

  ngOnInit(): void {}

  onDateChange(event: Date, controlName: string): void {
    if (event) {
      this.commonService.onDateChange(event, this.formGroup, controlName);
    }
  }
}
