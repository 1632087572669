import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Endpoints } from 'app/config';
import { ApiService, CommonService } from 'app/services';
import { FormsService } from 'app/services/forms.service';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as MESSAGE from '../../../enum/info-messages.enum';

@Component({
 selector: 'app-authentication-code',
 templateUrl: './authentication-code.component.html',
 styleUrls: ['./authentication-code.component.scss'],
})
export class AuthenticationCodeComponent implements OnInit, OnDestroy {
 authCode: number;
 formId: string;
 formName: string;
 authCodeVerified = false;
 signatureData: any;
 destroy$ = new Subject<boolean>();

 constructor(
  private commonService: CommonService,
  private activatedRoute: ActivatedRoute,
  private api: ApiService,
  private toastr: ToastrService,
  private formService: FormsService,
 ) {}

 ngOnInit(): void {
  this.commonService.hideSideMenu.next(false);
  this.formId = this.activatedRoute.snapshot.paramMap.get('id');
  this.getAuthCode(this.formId);
  this.getSignatureData();
 }

 getAuthCode(formId, resend?) {
  const params = {
   formId: formId,
   resendAuthCode: resend,
  };

  this.api
   .post(Endpoints.sendAuthCode, params)
   .pipe(takeUntil(this.destroy$))
   .subscribe(
    (res: any) => {
     if (res) {
      if (params.resendAuthCode) {
       this.toastr.success(MESSAGE.TOASTR.MESSAGE_TOKENRESENT, MESSAGE.INFO_MESSAGES.SUCCESS);
      } else {
       if (res.message === 'Auth code already sent') {
        this.toastr.info(MESSAGE.TOASTR.MESSAGE_TOKENSENTALREADY, MESSAGE.INFO_MESSAGES.SUCCESS);
       } else {
        this.toastr.success(MESSAGE.TOASTR.MESSAGE_TOKENSENT, MESSAGE.INFO_MESSAGES.SUCCESS);
       }
      }
     }
    },
    err => {
     this.toastr.error(MESSAGE.TOASTR.MESSAGE_TOKENINVALID, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
    },
   );
 }

 getSignatureData() {
  this.formService.getVerifyToken().subscribe(data => {
   if (data) {
    this.signatureData = data;
    this.signatureData.sendDoc = true;
   }
  });
 }

 saveFormData() {
  if (this.signatureData) {
   this.api
    .put(Endpoints.saveForm, this.signatureData)
    .pipe(takeUntil(this.destroy$))
    .subscribe((res: any) => {
     if (res) {
      this.toastr.success(MESSAGE.TOASTR.MESSAGE_SAVEDFORM, MESSAGE.INFO_MESSAGES.SUCCESS);
      this.authCodeVerified = true;
     }
    });
  } else {
   console.log('No data to save');
  }
 }

 verifyToken() {
  this.api
   .post(Endpoints.verifyToken, { authCode: this.authCode, formId: this.formId })
   .pipe(takeUntil(this.destroy$))
   .subscribe((res: any) => {
    if (res) {
     if (this.signatureData) {
      this.saveFormData();
     }
     this.toastr.success(MESSAGE.TOASTR.MESSAGE_TOKENVERIFIED, MESSAGE.INFO_MESSAGES.SUCCESS);
    } else {
     this.toastr.error(MESSAGE.TOASTR.MESSAGE_TOKENINVALID, MESSAGE.INFO_MESSAGES.ALERT_TITLE);
    }
   });
 }

 ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.complete();
 }
}
