export enum SIDEBAR_MENU {
  ACCOUNTING = 'Accounting',
  INVOICES = 'Invoices',
  ARCHIVES = 'Archives',
  BIN = 'Bin',
  MATTERS = 'Matters'
}

export enum SIDEBAR_MENU_URLS {
  INVOICES = 'invoices',
  BIN = 'bin'
}

export enum SIDEBAR_MENU_CLASS {
  SHOWMENU = 'show-menu',
  HIDEMENU = 'hide-menu',
  SHOWONCOLLAPSE = 'show-oncollapse dis-block',
  CLIENTNAME = 'client-name'
}