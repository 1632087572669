import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BriefDocketService } from '../../../services';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Input() userDetails;
  @Input() matterType;
  @Input() matterId;
  @Input() centerTitle = false;
  @Input() showBin = false;
  @Input() tabs: any;
  @Input() accessLevel: number;
  isEditable = false;
  matterDetails: any;
  url = '';
  destroy$ = new Subject<boolean>();

  constructor(private router: Router, private briefsService: BriefDocketService) {}

  ngOnInit() {
    this.briefsService.isEditable.pipe(takeUntil(this.destroy$)).subscribe(res => {
      this.isEditable = res;
    });
  }

  checkBin() {
    const matter = JSON.parse(localStorage.getItem('matterDetails'));
    if (matter && matter._id !== '') {
      this.router.navigate([`/matters/${matter._id}/bin`]);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
