export enum ROUTES_TO {
  HOME = '/home',
  MATTERS = '/matters',
  MY_MATTERS = '/matters/my-matters',
  ADD_MATTERS = '/matters/add-matter',
  LOGIN = '/auth/login',
  CALENDER = '/calendar',
  BRIEFS_DOCKETS = '/matters/brief-dockets',
  DATA_FORMS = '/matters/dataForms',
  LAW_FIRM = '/law-firm-info',
  CLIENTNAME = 'clientname',
  ACCOUNTS = 'Accounts',
  CALENDAR = 'Calendar',
  USER_PROFILE = '/users/profile',
  AUTH = 'auth',
}
