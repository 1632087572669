import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import { ApiService } from '../../../services/api.service';
import { PaymentMethods } from '../../config/payment-methods';

@Component({
  selector: 'app-edit-trust-recipts',
  templateUrl: './edit-trust-recipts.component.html',
})
export class EditTrustReciptsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('editModal') public editModal: ModalDirective;
  @Input() receipt: any;
  @Output() confirm = new EventEmitter();
  @Input() selectedInvoice: any;
  @Input() type: string;
  @Input() matterDetails: any;
  @Input() totalBalance: number;
  @Input() addDisbursement = false;
  methods = [];
  receipts: any;
  labels = {
    Cheque: 'Cheque No',
    Cash: 'Cash Memo No',
    'Bank Transfer': 'Transaction ID',
    'Electronic Money Transfer': 'Transaction ID',
    'Credit card': 'Transaction ID',
  };
  editTrustReceiptForm: FormGroup;
  destroy$ = new Subject<boolean>();

  constructor(private fb: FormBuilder, private apiService: ApiService, private toastr: ToastrService) {}

  ngOnInit(): void {
    this.initializeTrustForm();
    this.methods = PaymentMethods.filter(method => method != 'Deduct from Trust');
  }

  initializeTrustForm() {
    this.editTrustReceiptForm = this.fb.group({
      paymentMethod: ['', Validators.required],
      refId: ['', Validators.required],
      lastFourDigits: ['', Validators.pattern('^((\\+91-?)|0)?[0-9]{4}$')],
      amount: ['', [Validators.required, Validators.min(1)]],
      purposeOfFunds: [''],
      creditType: ['trust receipts', Validators.required],
    });
    this.editTrustReceiptForm.patchValue({
      paymentMethod: this.receipt.paymentMethod,
      refId: this.receipt.refId,
      lastFourDigits: this.receipt.lastFourDigits,
      amount: this.receipt.amount.$numberDecimal,
      purposeOfFunds: this.receipt.purposeOfFunds,
      creditType: this.receipt.creditType,
    });
  }

  get getFormControls() {
    return this.editTrustReceiptForm.controls;
  }

  ngOnChanges() {
    setTimeout(() => {
      this.editModal.show();
    }, 100);
  }

  onCancel() {
    this.confirm.emit({ state: false, type: 'edit modal' });
  }

  enableControls() {
    this.editTrustReceiptForm?.get('trustBalance')?.enable();
    this.editTrustReceiptForm?.get('amount')?.enable();
  }

  savePaymentInfo() {
    this.editTrustReceiptForm?.get('paymentMethod')?.enable();
    const params: any = {
      paymentMethod: this.editTrustReceiptForm.value.paymentMethod,
      refId: this.editTrustReceiptForm.value.refId,
      lastFourDigits: this.editTrustReceiptForm.value.lastFourDigits,
      amount: +this.editTrustReceiptForm.value.amount,
      purposeOfFunds: this.editTrustReceiptForm.value.purposeOfFunds,
      comments: this.editTrustReceiptForm?.value?.comment,
    };

    if (this.type === 'trust') {
      if (this.editTrustReceiptForm.value?.purposeOfFunds.length === 0) {
        if (this.matterDetails?.matterType.toLowerCase() === 'immigration') {
          params.purposeOfFunds = `${this.matterDetails?.matterType} - ${this.matterDetails.matterSubType}`;
        } else {
          params.purposeOfFunds = `${this.matterDetails.matterType}`;
        }
      } else {
        params.purposeOfFunds = this.editTrustReceiptForm.value?.purposeOfFunds;
      }
      params.trustBalance = +this.editTrustReceiptForm.value.amount + this.totalBalance;
      params.creditType = this.editTrustReceiptForm.value.creditType;
    } else {
      params.trustBalance = +this.editTrustReceiptForm.value.trustBalance;
    }

    const api: any =
      this.type === 'invoice' && !this.addDisbursement
        ? this.apiService.put(Endpoints.addPayment, params)
        : this.apiService.put(Endpoints.trust, params);

    api.pipe(takeUntil(this.destroy$)).subscribe(
      (payment: any) => {
        this.toastr.success(payment.message);
        this.confirm.emit({ isDone: true });
      },
      err => {
        this.confirm.emit({ isDone: false });
      },
    );
  }

  updateReceipt() {
    const params: any = {
      paymentMethod: this.editTrustReceiptForm.value.paymentMethod,
      refId: this.editTrustReceiptForm.value.refId,
      amount: +this.editTrustReceiptForm.value.amount,
      receipt: this.receipt,
    };

    const apiEndPoints: any = this.apiService
      .put(Endpoints.updateReceipt, params)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.toastr.success(res.message);
          this.confirm.emit({ state: true, type: 'edit modal' });
        },
        err => {
          console.log(
            'TCL ->  ~ file: trust-receipts.component.ts ~ line 98 ~ TrustReceiptsComponent ~ this.apiService.deleteWithParams ~ err',
            err,
          );
          this.confirm.emit({ state: true, type: 'edit modal' });
          this.toastr.error(err?.message);
        },
      );
  }

  validateNumber(ev: any, controlName: string) {
    if (ev.target.value < 0) {
      this.editTrustReceiptForm.get(controlName).setValue(0);
    }
  }

  getList() {
    this.apiService
      .get(Endpoints.interTrustTransfer)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data: any) => {
          this.receipts = data;
        },
        err => {},
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
