import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import * as MESSAGE from '../enum/info-messages.enum';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  constructor(private zone: NgZone, private toastr: ToastrService) {}

  handleError(error: any) {
    if (!(error instanceof HttpErrorResponse)) {
      error = error.rejection;
    }
    this.zone.run(() =>
      this.toastr.error(
        typeof error.error !== 'object' ? error.error : error?.message !== '' ? error.message : MESSAGE.RESPONSE.ERROR,
        MESSAGE.INFO_MESSAGES.ALERT_TITLE,
      ),
    );
  }
}
