import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  public DeleteOption = new Subject<any>();

  constructor() {}
  deletePopup(deleteID, user: any, apiUrl = '', deleteMessage = '', deleteStatus = false) {
    let message = deleteMessage ? deleteMessage : `Are you sure you want to delete ${user.firstName} ${user.lastName}?`;
    let deleteOption = { deleteId: deleteID, message: message, apiUrl: apiUrl };
    this.DeleteOption.next(deleteOption);
  }
}
