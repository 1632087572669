import { INavData } from '@coreui/angular';

export const clientNavItems: INavData[] = [
  {
    class: 'border-bottom',
    divider: true,
  },
  {
    name: 'Matters',
    url: '/matters',
    icon: 'icon-drawer',
  },
  {
    name: 'Notifications',
    url: '/notifications',
    icon: 'icon-bell',
    badge: {
      text: '',
      variant: 'success',
      class: 'custom-badge-class',
    },
  },
  {
    name: '',
    icon: '',
    url: 'clientname',
    class: 'child-side-bar',
  },
  {
    name: 'Briefs & Dockets',
    url: '/matters/brief-dockets',
    icon: 'icon-layers',
    class: 'child-side-bar',
  },
];
