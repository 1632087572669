import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject, fromEvent } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { Endpoints } from '../../../config';
import * as INFO from '../../../enum/info-messages.enum';
import { LOCAL_STORAGE } from '../../../enum/local-storage.enum';
import { ApiService, AuthService, CommonService, DataService } from '../../../services';
@Component({
 selector: 'app-matter-list',
 templateUrl: './matter-list.component.html',
 styleUrls: ['./matter-list.component.scss'],
})
export class MatterListComponent implements OnInit, OnDestroy {
 @ViewChild('searcMatter', { static: true }) searcMatter: ElementRef;
 @Input() status: string;

 windowWidth: number = window.innerWidth;
 selectMatterType: FormGroup;
 searchTerm = new Subject<any>();

 endpoints = Endpoints;
 public searchFilter: any = '';
 userDetail: any;
 term;
 preFilter = {
  preType: '',
  preStatus: '',
 };
 mattersList = [];
 searchList = [];
 filteredMatterList = [];
 query: string;
 clientId: string;
 hidePagination: boolean;
 totalPage: number;
 pageLimit = 10;
 p: number;
 accessLevel: number;
 currentPage = 1;
 searchTotalPage: number;
 searchedText = '';
 matters: any;
 destroy$ = new Subject<boolean>();

 constructor(
  private dataService: DataService,
  private router: Router,
  private api: ApiService,
  private commonService: CommonService,
  private fb: FormBuilder,
  private authService: AuthService,
  private toastr: ToastrService,
 ) {}

 async ngOnInit(): Promise<void> {
  this.accessLevel = +this.authService.getUserAccessLevel();
  this.userDetail = this.authService.getUserDetails();
  if (this.userDetail) {
   this.clientId = this.userDetail?._id;
  }
  this.p = 1;
  this.dataService.setMatterDetailActive(false);
  this.dataService.setAddMatterButtonActive(false);
  this.commonService.showSidebarCalendar(false);
  this.dataService.setUserDetailActive(false);
  this.selectMatterType = this.fb.group({
   matterTypeValue: [''],
  });
  await this.getMatterList();
  this.searchListMatter();
 }

 getMatterList() {
  return new Promise((resolve, reject) => {
   this.pageLimit = this.pageLimit ? this.pageLimit : 10;
   const myMatters = this.router.url.includes('my-matters');
   let api: any;

   if (this.accessLevel >= 2 && myMatters) {
    api = this.api.getParams(`${this.endpoints.getClientMatters}`, {
     clientId: this.userDetail._id,
     pageNumber: this.p,
     limit: this.pageLimit,
     status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
     isArchived: this.status !== INFO.STATUS.ACTIVE,
    });
   } else if ((this.accessLevel === 2 || this.accessLevel === 3) && !myMatters && this.status !== INFO.STATUS.CLOSED) {
    api = this.api.getParams(`${this.endpoints.getClientMatters}`, {
     clientId: this.userDetail._id,
     pageNumber: this.p,
     limit: this.pageLimit,
     status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
     isArchived: this.status !== INFO.STATUS.ACTIVE,
    });
   } else if (this.accessLevel >= 2 && !myMatters) {
    api = this.api.getParams(`${this.endpoints.getMatterList}`, {
     matterType: 'all',
     pageNumber: this.p,
     limit: this.pageLimit,
     status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
     isArchived: this.status !== INFO.STATUS.ACTIVE,
     userId: this.accessLevel === 2 || this.accessLevel === 3 ? this.userDetail._id : '',
    });
   } else {
    api = this.api.getParams(`${this.endpoints.getClientMatters}`, {
     clientId: this.userDetail._id,
     pageNumber: this.p,
     limit: this.pageLimit,
     status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
     isArchived: this.status !== INFO.STATUS.ACTIVE,
    });
   }

   api.pipe(takeUntil(this.destroy$)).subscribe(
    (res: any) => {
     if (res) {
      this.mattersList = res.matters;
      this.totalPage = res.totalMatter;
      if ((this.status === INFO.STATUS.CLOSED && this.accessLevel === 2) || this.accessLevel === 3) {
       this.filteredMatterList = this.mattersList.filter(
        matter =>
         matter?.lawyers[0]?._id === this.userDetail?._id ||
         matter?.clerk?.some(clerk => clerk._id === this.userDetail?._id),
       );
      } else {
       this.filteredMatterList = [...this.mattersList];
      }

      if (this.accessLevel === 1 && this.mattersList.length === 1) {
       this.gotMatterDetails(this.mattersList[0]._id, this.mattersList[0]);
      } else {
       this.userData(this.mattersList);
      }
      resolve(true);
     }
    },
    error => {
     reject(new Error('Failed to retrieve matters list'));
    },
   );
  });
 }

 userData(data) {
  const matterType = data.reduce((acc, item) => {
   const type = item.matterType;
   if (acc[type] == null) acc[type] = [];
   acc[type].push(item);
   return acc;
  }, {});

  this.matters = Object.keys(matterType).map(key => {
   return {
    matterType: key,
    details: matterType[key],
   };
  });
 }

 gotMatterDetails(matter: string, matterData: any) {
  localStorage.removeItem(LOCAL_STORAGE.MATTER_NAV_DETAILS);

  const matterInfo = { userDetail: matterData.user, matterDetails: matterData };
  localStorage.setItem(LOCAL_STORAGE.MATTER_NAV_DETAILS, JSON.stringify(matterInfo));
  this.dataService.getSelectedUserData.next(matterInfo);
  this.router.navigate([`/matters/${matter}/brief-dockets`]);
 }

 private searchListMatter(): void {
  fromEvent(this.searcMatter.nativeElement, 'keyup')
   .pipe(
    map((event: any) => {
     return event.target.value;
    }),
    filter(res => {
     if (res.length > 3) {
      this.hidePagination = true;
      return res;
     }
     this.hidePagination = false;
     this.filteredMatterList = [...this.mattersList];
     return;
    }),
    debounceTime(1000),
   )
   .subscribe((matter: string) => {
    if (matter.length > 3) {
     this.searchedText = matter;
     this.filteredMatterList = [];
     this.hidePagination = true;
     this.pageLimit = this.pageLimit ? this.pageLimit : 10;
     this.currentPage = 1;
     const id = this.accessLevel === 2 || this.accessLevel === 3 ? this.userDetail._id : '';
     const api: any =
      this.accessLevel >= 1
       ? this.api.getParams(`${this.endpoints.searchMatter}`, {
          searchText: matter,
          pageNumber: this.currentPage,
          status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
          limit: this.pageLimit,
          userId: id,
         })
       : this.api.getParams(`${this.endpoints.searchMatter}`, {
          searchText: matter,
          clientId: this.clientId,
          status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
          pageNumber: this.currentPage,
          limit: this.pageLimit,
         });
     try {
      api.pipe(takeUntil(this.destroy$)).subscribe(
       (res: any) => {
        if (res) {
         this.filteredMatterList = res.matters;
         this.searchTotalPage = res.totalMatter;
        }
       },
       err => {
        const errResponse = err.error.message || INFO.RESPONSE.NOMATTER;
        this.toastr.error(errResponse, INFO.TOASTR.ALERT);
        this.filteredMatterList = [...this.mattersList];
       },
      );
     } catch (err) {}
    } else {
     this.filteredMatterList = [...this.mattersList];
    }
   });
 }

 matterPageChanged(event: any) {
  this.pageLimit = this.pageLimit ? this.pageLimit : 10;
  this.p = event;
  const myMatters = this.router.url.includes('my-matters');
  let api: any;

  if (this.accessLevel >= 2 && myMatters) {
   api = this.api.getParams(`${this.endpoints.getClientMatters}`, {
    clientId: this.userDetail._id,
    pageNumber: this.p,
    limit: this.pageLimit,
    status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
    isArchived: this.status !== INFO.STATUS.ACTIVE,
   });
  } else if ((this.accessLevel === 2 || this.accessLevel === 3) && !myMatters && this.status !== INFO.STATUS.CLOSED) {
   api = this.api.getParams(`${this.endpoints.getClientMatters}`, {
    clientId: this.userDetail._id,
    pageNumber: this.p,
    limit: this.pageLimit,
    status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
    isArchived: this.status !== INFO.STATUS.ACTIVE,
   });
  } else if (this.accessLevel >= 2 && !myMatters) {
   api = this.api.getParams(`${this.endpoints.getMatterList}`, {
    matterType: 'all',
    pageNumber: this.p,
    limit: this.pageLimit,
    status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
    isArchived: this.status !== INFO.STATUS.ACTIVE,
    userId: this.accessLevel === 2 || this.accessLevel === 3 ? this.userDetail._id : '',
   });
  } else {
   api = this.api.getParams(`${this.endpoints.getClientMatters}`, {
    clientId: this.userDetail._id,
    pageNumber: this.p,
    limit: this.pageLimit,
    status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
    isArchived: this.status !== INFO.STATUS.ACTIVE,
   });
  }

  api.pipe(takeUntil(this.destroy$)).subscribe(
   (res: any) => {
    if (res) {
     this.mattersList = res.matters;
     this.totalPage = res.totalMatter;
     this.filteredMatterList = [...this.mattersList];
     this.userData(this.mattersList);
    }
   },
   error => {},
  );
 }

 searchPageChanged(event: any) {
  this.currentPage = event;
  const id = this.accessLevel === 2 || this.accessLevel === 3 ? this.userDetail._id : '';

  const api: any =
   this.accessLevel >= 1
    ? this.api.getParams(`${this.endpoints.searchMatter}`, {
       searchText: this.searchedText,
       pageNumber: this.currentPage,
       limit: this.pageLimit,
       status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
       userId: id,
      })
    : this.api.getParams(`${this.endpoints.searchMatter}`, {
       searchText: this.searchedText,
       clientId: this.clientId,
       status: this.status === INFO.STATUS.ACTIVE ? INFO.STATUS.OPEN : INFO.STATUS.CLOSED,
       pageNumber: this.currentPage,
       limit: this.pageLimit,
      });
  try {
   api.pipe(takeUntil(this.destroy$)).subscribe(
    (res: any) => {
     if (res) {
      this.filteredMatterList = res.matters;
      this.searchTotalPage = res.totalMatter;
     }
    },
    err => {
     const errResponse = err.error.message || INFO.RESPONSE.NOMATTER;
     this.toastr.error(errResponse, INFO.TOASTR.ALERT);
     this.filteredMatterList = [...this.mattersList];
    },
   );
  } catch (err) {}
 }

 updatePageLimit(ev: any) {
  this.pageLimit = +ev.target.value;

  if (this.hidePagination) {
   this.searchPageChanged(1);
  } else {
   this.matterPageChanged(1);
  }
 }

 ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.complete();
 }
}
