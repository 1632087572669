import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
 BrowserModule,
 HAMMER_GESTURE_CONFIG,
 HammerGestureConfig,
 HammerModule,
 Title,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Capacitor } from '@capacitor/core';
import {
 AppAsideModule,
 AppBreadcrumbModule,
 AppFooterModule,
 AppHeaderModule,
 AppSidebarModule,
} from '@coreui/angular';
import { FullCalendarModule } from '@fullcalendar/angular'; // must go before plugins
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import {
 CalendarDateFormatter,
 CalendarModule,
 CalendarNativeDateFormatter,
 DateAdapter,
 DateFormatterParams,
} from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import * as Hammer from 'hammerjs';
import { polyfill } from 'mobile-drag-drop';
import { scrollBehaviourDragImageTranslateOverride } from 'mobile-drag-drop/scroll-behaviour';
import { NgAdblockDetectModule } from 'ng-adblock-detect';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { PdfJsViewerModule } from 'ng2-pdfjs-viewer';
import { NgxSpinnerModule } from 'ngx-bootstrap-spinner';
import { BsDatepickerModule, DatepickerModule } from 'ngx-bootstrap/datepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PerfectScrollbarConfigInterface, PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationCodeComponent } from './all-modules/component/authentication-code/authentication-code.component';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { DefaultLayoutComponent } from './containers';
import { AppTwoDigitDecimalNumberDirective } from './directive/app-two-digit-decimal-number.directive';
import { AppInterceptor } from './interceptor/app.interceptor';
import { SharedModule } from './shared/shared.module';

polyfill({
 dragImageTranslateOverride: scrollBehaviourDragImageTranslateOverride,
});

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
 suppressScrollX: true,
};

const APP_CONTAINERS = [DefaultLayoutComponent];
@Injectable()
export class HammerConfig extends HammerGestureConfig {
 overrides = {
  swipe: { direction: Hammer.DIRECTION_ALL },
 };
}

FullCalendarModule.registerPlugins([
 // register FullCalendar plugins
 dayGridPlugin,
 interactionPlugin,
 timeGridPlugin,
 resourceTimeGridPlugin,
]);

@Injectable({
 providedIn: 'root',
})
class CustomDateFormatter extends CalendarNativeDateFormatter {
 public monthViewColumnHeader({ date, locale }: DateFormatterParams): string {
  return new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);
 }

 public weekViewColumnHeader({ date, locale }: DateFormatterParams): string {
  return new Intl.DateTimeFormat(locale, { weekday: 'narrow' }).format(date);
 }
}

@NgModule({
 imports: [
  BrowserModule,
  BrowserAnimationsModule,
  AppRoutingModule,
  AppAsideModule,
  AppBreadcrumbModule.forRoot(),
  AppFooterModule,
  AppHeaderModule,
  AppSidebarModule,
  PerfectScrollbarModule,
  BsDropdownModule.forRoot(),
  TabsModule.forRoot(),
  ChartsModule,
  SharedModule,
  FormsModule,
  NgAdblockDetectModule,
  ReactiveFormsModule,
  HttpClientModule,
  ModalModule.forRoot(),
  CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
  FullCalendarModule,
  NgxSpinnerModule,
  ToastrModule.forRoot({
   preventDuplicates: true,
   positionClass: Capacitor.getPlatform() === 'web' ? 'toast-top-right' : 'toast-bottom-center',
  }),
  NgMultiSelectDropDownModule,
  PdfJsViewerModule,
  DatepickerModule,
  BsDatepickerModule,
  HammerModule,
  AngularSignaturePadModule,
 ],
 declarations: [AppComponent, ...APP_CONTAINERS, AppTwoDigitDecimalNumberDirective, AuthenticationCodeComponent],
 providers: [
  Title,
  {
   provide: LocationStrategy,
   useClass: HashLocationStrategy,
  },
  { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },

  { provide: CalendarDateFormatter, useClass: CustomDateFormatter },
  {
   provide: HAMMER_GESTURE_CONFIG,
   useClass: HammerConfig,
  },
 ],
 bootstrap: [AppComponent],
})
export class AppModule {}
