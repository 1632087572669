import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-input',
  template: `
    <ng-container [formGroup]="formGroup">
      <input class="form-control" type="text" [formControlName]="controlName" />
    </ng-container>
  `,
  styles: [],
})
export class InputComponent {
  @Input() formGroup: any;
  @Input() controlName: any;
  constructor() {}
}
