import { HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { NotificationResponse } from 'app/interfaces/notification.interface';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Endpoints } from '../config';
import { LOCAL_STORAGE } from '../enum/local-storage.enum';
import { User, currentUser } from '../interfaces/user.interface';
import { ApiService } from './api.service';
declare var window: any;

@Injectable({
 providedIn: 'root',
})
export class OneSignalService implements OnDestroy {
 endpoints = Endpoints;
 destroy$ = new Subject<boolean>();
 public viewMessage: Subject<boolean> = new Subject<boolean>();
 public viewDocuments: Subject<boolean> = new Subject<boolean>();

 constructor(private api: ApiService, private router: Router) {}

 async initOneSignal() {
  if (window?.plugins?.OneSignal) {
   window.plugins.OneSignal.setLogLevel(6, 0);
   // NOTE: Update the setAppId value below with your OneSignal AppId.
   window.plugins.OneSignal.setAppId(environment.oneSignalAppId);
   window.plugins.OneSignal.setNotificationOpenedHandler(jsonData => {
    this.router.navigate(['/notifications']);
   });
   const permissions = await this.notificationPermissions();
  }
 }

 async notificationPermissions() {
  //Prompts the user for notification permissions.
  //    * Since this shows a generic native prompt, we recommend instead using an In-App Message to prompt for notification permission (See step 6) to better communicate to your users what notifications they will get.
  return new Promise((resolve, reject) => {
   try {
    window.plugins.OneSignal.promptForPushNotificationsWithUserResponse(accepted => {
     if (accepted === true) {
      resolve(true);
     } else {
      resolve(false);
     }
    });
   } catch (error) {
    reject(error);
   }
  });
 }

 async getPlayerId(): Promise<string | boolean> {
  return new Promise((resolve, reject) => {
   if (window?.plugins?.OneSignal) {
    window.plugins.OneSignal.getDeviceState((stateChanges: any) => {
     if (stateChanges?.hasNotificationPermission) {
      this.setPlayerIdToLocalStorage(stateChanges.userId);
      resolve(stateChanges.userId);
     } else {
      reject(false);
     }
    });
   } else {
    reject(false);
   }
  });
 }

 async setPlayerIdToLocalStorage(playerId: string) {
  localStorage.setItem('playerId', playerId);
 }

 async storePlayerIdToDatabase(playerId: string, loginDetails: User): Promise<void> {
  if (playerId && loginDetails) {
   const playerIds = { _id: loginDetails._id, playerId: playerId };
   const isMobile = Capacitor.getPlatform() !== 'web';
   if (isMobile) {
    window.plugins.OneSignal.setEmail(loginDetails.email);
   }
   this.api
    .put(this.endpoints.playerId, playerIds)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
     next: response => {
      return true;
     },
     error: error => {
      console.log('error to store the playerId', error);
     },
    });
  }
 }

 async getNotifications(matterId: string, currentUser: currentUser): Promise<NotificationResponse[]> {
  if (!currentUser?._id) {
   return;
  }

  let params = new HttpParams();
  params = params.set('matterId', matterId);

  // Use a Promise to handle the asynchronous HTTP request
  return new Promise<NotificationResponse[]>((resolve, reject) => {
   this.api
    .getParams(`${Endpoints.getNotifications}/${currentUser?._id}`, params)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
     (res: NotificationResponse[]) => {
      if (res) {
       if (currentUser?.accessLevel === 1) {
        const updatedResponse = res.filter(
         x =>
          (x?.notificationType === 'message' && x.receiverId?.includes(currentUser?._id)) ||
          (x?.notificationType === 'approval' && x?.receiverId?.includes(currentUser?._id)),
        );
        resolve(updatedResponse);
       } else {
        const updatedResponse = res.filter(
         x =>
          (x.notificationType === 'document' && x?.lawyers.some(id => id?.lawyerId === currentUser?._id)) ||
          (x?.notificationType === 'message' && x.receiverId?.includes(currentUser?._id)) ||
          (x?.notificationType === 'approval' && x?.receiverId?.includes(currentUser?._id)),
        );

        resolve(updatedResponse);
       }
      }
     },
     err => {
      console.log('** -> ~ file: default-layout.component.ts:905 ~ getNotifications ~ err:', err);
      reject(err);
     },
    );
  });
 }

 async getPlayerIdFromLocalStorage(): Promise<string> {
  const playerId = localStorage.getItem(LOCAL_STORAGE.PLAYER_ID);
  return playerId;
 }

 async getCurrentUserDetails() {
  const currentUser = JSON.parse(localStorage.getItem(LOCAL_STORAGE.USER_DETAILS));
  return currentUser?._id;
 }

 async deletePlayerId(playerId: string, userId: string): Promise<void> {
  if (playerId) {
   this.api
    .delete(`${Endpoints.deletePlayerId}/${playerId}/${userId}`)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
     error: err => {
      console.log('🚀 ~ file: one-signal.service.ts:124 ~ OneSignalService ~ this.api.delete ~ err:', err);
     },
    });
  }
 }

 ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.complete();
 }
}
